// eslint-disable-next-line
import React from 'react'

import PollOutlinedIcon from '@material-ui/icons/PollOutlined';



import axios from 'axios'

import PropTypes from 'prop-types'

import ProgressBar from "../../ProgressBar/ProgressBar";
import { withRouter } from 'react-router-dom'
import IncomeSearchForm from './IncomeSearchForm'
import IncomeStatementTable from './IncomeStatementTable'
import Checkbox from '@material-ui/core/Checkbox'
import * as CommonFunction from "../../Custom/CommonFunctions"
import ReactToPrint from 'react-to-print';
import { IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print'
export const Component = withRouter(() => {

})

class IncomeStatements extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            model: 'Income Statement',
            incomeStatements: [],
            account: { months: [] ,is_posted: 'true'},
            title: '',
            load: false,
            tableHead: ['name', 'code', 'name type', 'sales', 'provision for income tax', 'income_special_projects', 'gross_profit'],
            branch: {},
            branches: [],
            withPagination: true,
            currentPage: 1,
            totalPages: 1,
            notConsolidated: false,
            includeZeroes: false,
            months: [
                { value: 1, name: "January" },
                { value: 2, name: "February" },
                { value: 3, name: "March" },
                { value: 4, name: "April" },
                { value: 5, name: "May" },
                { value: 6, name: "June" },
                { value: 7, name: "July" },
                { value: 8, name: "August" },
                { value: 9, name: "September" },
                { value: 10, name: "October" },
                { value: 11, name: "November" },
                { value: 12, name: "December" }
            ],
            urlPrint: {},
            forPrint: false
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleAutoComplete = this.handleAutoComplete.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleCheck = this.handleCheck.bind(this)
        this.changePrintLayout = this.changePrintLayout.bind(this)
    }

    componentDidMount() {
        var url = ''
        if (this.props.location.search === '') {
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ branches: resp.data })
                this.setState({ load: true })
            }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
        } else {
            var searchItem = {}
            url = process.env.REACT_APP_API_DOMAIN + '/v1/accounts/income_statement?'
            const query = new URLSearchParams(this.props.location.search);
            var urlOptions = []
            if (query.get('as_of_date') !== null && query.get('as_of_date') !== '') {
                searchItem["as_of_date"] = query.get('as_of_date')
                urlOptions.push("as_of_date=" + query.get('as_of_date'))
            }

            if (query.get('branch_id') !== null && query.get('branch_id') !== '') {
                axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
                    headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
                }).then(resp => {
                    resp.data.map((branch) => {
                        if (branch.id.toLocaleString() === query.get('branch_id')) {
                            searchItem["branch"] = branch

                            urlOptions.push("branch_id=" + branch.id)
                        }
                    })
                })
            }
            if (query.get('is_posted') !== null && query.get('is_posted') !== '') {
                searchItem["is_posted"] = query.get('is_posted')
                urlOptions.push("is_posted=" + query.get('is_posted'))
            }
            if (query.get('months') !== undefined && query.get('months') !== null) {
                var months = query.get('months').split(',')
                var searchitemMonth = []
                this.state.months.map((stateMonth) => {
                    months.map((queryMonth) => {
                        if (stateMonth.value === parseInt(queryMonth)) {
                            searchitemMonth.push(stateMonth)
                        }
                    })
                })
                searchItem["months"] = searchitemMonth
                urlOptions.push("months=" + query.get('months'))
            }

            if (urlOptions.length > 0) {
                urlOptions.map((urlOption, idx) => {
                    if (idx > 0) {
                        url = url + "&" + urlOption
                    } else {
                        url = url + urlOption
                    }
                })
            }
            this.setState({ account: searchItem })
            this.loadIncomeStatements(url)
        }

    }

    loadIncomeStatements(url) {
        axios({
            method: 'get',
            url: url,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ incomeStatements: resp.data.accounts })
            this.setState({ urlPrint: resp.data.meta })
            this.setState({notConsolidated: true})
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ branches: resp.data })
                this.setState({ load: true })
            }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
        }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    }

    handleSubmit() {
        this.setState({load: false})
        var url = process.env.REACT_APP_API_DOMAIN + "/v1/accounts/income_statement?"
        var searchUrl = ''
        var options = []
        var withResultsLabel = false
        if (this.state.account.as_of_date !== undefined && this.state.account.as_of_date !== '') {
            options.push("as_of_date=" + this.state.account.as_of_date)
            withResultsLabel = true
        }
        if (this.state.account.branch !== undefined &&this.state.account.branch.id !== undefined && this.state.account.branch !== '') {
            options.push("branch_id=" + this.state.account.branch.id)
            withResultsLabel = true
        }
        if (this.state.account.is_posted !== undefined && this.state.account.is_posted !== '') {
            options.push("is_posted=" + this.state.account.is_posted)
            withResultsLabel = true
        }
        if (this.state.account.months !== undefined && this.state.account.months !== null ) {
            if (this.state.account.months.length >0) {
                var months = ""
                this.state.account.months.map((month, idx) => {
                    if (idx === this.state.account.months.length - 1) {
                        months = months + month.value
                    } else {
                        months = months + month.value + ','
                    }
                })
                options.push("months=" + months)
                withResultsLabel = true
            }

        }

        options.map((option, idx) => {
            if (idx > 0) {
                searchUrl = searchUrl + "&" + option
            } else {
                searchUrl = searchUrl + option
            }
        })
        url = url + searchUrl
        axios({
            method: "get",
            url: url,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
            this.setState({ incomeStatements: resp.data.accounts })
            this.setState({ withResultsLabel: withResultsLabel })
            this.setState({ totalResults: resp.data.meta.total_count })
            this.setState({ urlPrint: resp.data.meta })
            this.setState({ totalPages: resp.data.meta.total_pages })
            this.setState({notConsolidated: true})
            this.setState({load: true})
        }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))

        const { history } = this.props
        const params = new URLSearchParams()
        params.append("/search", searchUrl)
        history.push({ search: searchUrl })
    }

    handleInputChange(e) {
        this.setState({
            account: {
                ...this.state.account,
                [e.target.name]: e.target.value
            }
        })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleSubmit()
        }
    }

    clearSearch() {
        const { history } = this.props
        history.push({ search: '' })
        this.setState({incomeStatements: [],urlPrint: {}})
        this.setState({notConsolidated: false})
        this.setState({ account: { as_of_date: '', is_posted: '', branch: {}, months: [] } })
    }

    handleAutoComplete(event, values) {
        this.setState({
            account: {
                ...this.state.account,
                branch: values
            }
        })
    }
    handleSelectChange(event, values) {
        this.setState({
            account: {
                ...this.state.account,
                months: values
            }
        })
    }

    handleCheck(e){
        this.setState({includeZeroes: !this.state.includeZeroes})
        var zeroText = "with_zero=" + e.target.checked.toString()
        var newExportExcel = this.state.urlPrint.export_excel_url.replace('with_zero=false',zeroText).replace('with_zero=true',zeroText)
        var newReportsUrl = this.state.urlPrint.reports_url.replace('with_zero=false',zeroText).replace('with_zero=true',zeroText)
        this.setState({
            urlPrint:{
                ...this.state.urlPrint,
                export_excel_url: newExportExcel,
                reports_url: newReportsUrl
            }
        })
    }

    changePrintLayout(){
        return new Promise((resolve) => {
            this.setState({ forPrint: !this.state.forPrint }, () => resolve());
          });        
    }

    render() {
        const print = 
            <ReactToPrint
            trigger={() => <IconButton color="primary"><PrintIcon /></IconButton>}
            onBeforeGetContent={this.changePrintLayout}
            onAfterPrint={this.changePrintLayout}
            content={() => this.componentRef}
        />
        const withoutZeroes = this.state.incomeStatements.filter(item =>item.balance!==0)
        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            <IncomeSearchForm
                                item={this.state.account}
                                branches={this.state.branches}
                                handleSelectChange={this.handleSelectChange}
                                months={this.state.months}
                                handleAutoComplete={this.handleAutoComplete}
                                search={this.handleSubmit}
                                clearSearch={this.clearSearch}
                                handleChange={this.handleInputChange}
                            />
                            {
                                this.state.incomeStatements.length>0?( 
                                <><Checkbox 
                                    checked={this.state.includeZeroes}
                                    onChange={this.handleCheck}
                                /><b>Include Zeroes</b></>):null
                            }
                           
                            <IncomeStatementTable
                                months={this.state.months}
                                urlPrint={this.state.urlPrint}
                                items={this.state.includeZeroes?(this.state.incomeStatements):withoutZeroes}
                                model={this.state.model}
                                headers={this.state.tableHead}
                                icon={<PollOutlinedIcon fontSize="large" />}
                                searchItem={this.state.account}
                                notConsolidated={this.state.notConsolidated}
                                ref={el => (this.componentRef = el)}
                                print={print}
                                forPrint={this.state.forPrint}
                            />
                        </div>
                    ) : (
                        <ProgressBar model={this.state.model} />
                    )}
            </>
        )
    }
}

export default withRouter(IncomeStatements)

IncomeStatements.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
}
