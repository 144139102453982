import React from 'react'

import PropTypes from 'prop-types'
import styles from '../Journals/Journal.module.css'

import {
  Table, TableHead, TableBody, TableCell, TableRow
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

class AttachmentDetailTab extends React.Component {
  render() {
    return (
      <div>
        <div className={styles.checkDetailsHeader}>
          <div>
            <IconButton
            onClick={this.props.openAttachment}
            ><AddCircleOutlineIcon/></IconButton>
          </div>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
               <b> Title</b>
              </TableCell>
              <TableCell>
                <b>Description</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.props.attachments.map((attachment) => (
            <TableRow  key={attachment.id}>
              <TableCell>
                {attachment.file_name}
              </TableCell>

              <TableCell>
                {attachment.description}
              </TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>

      </div>
    )
  }
}

export default AttachmentDetailTab

AttachmentDetailTab.propTypes = {
  classes: PropTypes.object,
  attachments: PropTypes.array,
  openAttachment: PropTypes.func,
}
