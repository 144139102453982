import React from 'react'
import moment from 'moment';
import 'moment-timezone';

import PropTypes from 'prop-types'
import styles from '../Clients.module.css'

import {
  Table, TableHead, TableBody, TableCell, TableRow, Tooltip
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

class AttachmentDetailTab extends React.Component {
  render() {
    return (
      <div>
        <div className={styles.checkDetailsHeader}>
          <div>
          </div>
          <div>
            <Tooltip title={`Add Attachment`} arrow>
            <IconButton
              onClick={this.props.openAttachment}
            ><AddCircleOutlineIcon/></IconButton>
            </Tooltip>
          </div>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b> Type</b>
              </TableCell>
              <TableCell>
                <b>description</b>
              </TableCell>
              <TableCell>
                <b>File Name</b>
              </TableCell>
              <TableCell>
                <b>Uploaded by</b>
              </TableCell>
              <TableCell>
                <b>Date Uploaded</b>
              </TableCell>
              <TableCell>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.props.attachments.map((attachment) => (
              <TableRow key={attachment.id}>
                <TableCell>
                  {attachment.category_name}
                </TableCell>
                <TableCell>
                  {attachment.description}
                </TableCell>
                <TableCell>
                  {attachment.file_name}
                </TableCell>
                <TableCell>
                  {attachment.uploader.email}
                </TableCell>
                <TableCell>
                  { moment(attachment.created_at).format("MM/DD/yyyy") }
                </TableCell>
                <TableCell>
                  <a href={attachment.file_url} target="_blank" rel="noopener noreferrer" download>
                    <IconButton aria-label="delete" color="primary" size="small">
                      <CloudDownloadIcon/>
                    </IconButton>
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </div>
    )
  }
}

export default AttachmentDetailTab

AttachmentDetailTab.propTypes = {
  classes: PropTypes.object,
  attachments: PropTypes.array,
  openAttachment: PropTypes.func,
}
