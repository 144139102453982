// eslint-disable-next-line

import React from 'react'
import moment from 'moment';
import 'moment-timezone';
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import JournalEntriesDetailsTab from './InvoiceTab/JournalEntriesDetailTab'
import ProductDetailTab from './InvoiceTab/ProductDetailTab'
import {Redirect} from 'react-router'
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import styles from './Invoices.module.css'
import InvoiceDetailsForm from './InvoiceDetailsForm'
import {
  Button,TextField,Tooltip,Box,Tab,Tabs,Typography,IconButton
}from '@material-ui/core'

import PropTypes from 'prop-types'
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import PaymentDetailTab from "./InvoiceTab/PaymentDetailTab";
import InvoicesFormPopUp from "./InvoicesFormPopUp";
import PaymentTermsForm from "./PaymentTermsForm";
import DeliveriesDetailTab from "../Invoices/InvoiceTab/DeliveriesDetailTab";
import EmailInvoiceForm from "./EmailInvoiceForm";
import ProductTransactionsTab from "./../ProductTransactions/ProductTransactionsTab.js"
import * as CommonFunction from "../../shared/Custom/CommonFunctions"
import AttachmentsTab from "./InvoiceTab/AttachmentsTab"
import AttachmentForm from "./AttachmentForm"
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import {Link} from "react-router-dom";
import InvoiceProductTransactionForm from "./../ProductTransactions/InvoiceProductTransactionForm.js"
import ReactToPrint from "react-to-print";
import CompanyHeader from '../../shared/CompanyHeader/CompanyHeader'

import AuditLog from '../../shared/AuditLog/AuditLog';
function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class InvoiceShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Invoice',
      value: 0,
      invoices: [],
      products: [],
      journalEntries: [],
      invoiceDetails: [],
      receiptContents: [],
      deliveries: [],
      contactPersons: [],
      paymentTerms: [],
      payment_term: {},
      clients: [],
      branches: [],
      openPopup: false,
      isInstallment: false,
      openPopupProduct: false,
      openInvoiceEditPopup: false,
      openAttachmentPopup: false,
      cancelInvoicePopup: false,
      activeInvoicePopup: false,
      activeJournalPopup: false,
      cancelJournalPopup: false,
      emailInvoicePopup: false,
      openTerms: false,
      openDeletePopup: false,
      invoiceDetailsExpirable: false,
      approveInvoicePopup: false,
      openDeletePopupProduct: false,
      redirectToIndex: false,
      invoiceDetail: {},
      invoiceDetailsValue: {},
      invoice: {},
      emailInvoice: {emails: '', subject: '', content: ''},
      initialItem: {
        id: '',
        product_id: '',
        year: new Date().getFullYear(),
        unit: '',
        qty: 1,
        discount: 0,
        unit_amount: '',
        amount: ''
      },
      title: '',
      load: false,
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      isOpen: false,
      message: '',
      type: '',
      selectedProductTransactionsIndex: 0,
      productTransactions: [],
      initialProductTransaction: { option: "Invoice", invoice_id: parseInt(this.props.match.params.id) },
      selectedClient: {},
      clientsAutoCompleteLoading: false,
      productsAutoCompleteLoading: false,
      selectedInvoiceDetailIndex: 0,
      popupHeader: '',
      isAddInvoiceDetail: false,
      invoiceForEdit: {},
      cwtTypes: [],
      current_user: JSON.parse(localStorage.getItem("current_user")),
      attachments: [],
      attachment: { category_name: "Invoice" },
      openNewAndEditProductTransactionPopup: false,
      deliveryEmployees: [],
      invoiceCds: [],
      invoiceDeliveryDetails: [],
      invoiceProducts: [],
      filteredInvoicdCds: [],
      filteredInvoiceProducts: [],
      signatory: {},
      openPrintPopup: false,
      changeSignPopup: false,
      forPrint: false,
      openPrintPDFPopup: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.cancelCheckVoucher = this.cancelCheckVoucher.bind(this)
    this.cancelInvoiceSubmit = this.cancelInvoiceSubmit.bind(this)
    this.activeInvoiceSubmit = this.activeInvoiceSubmit.bind(this)
    this.openAttachment = this.openAttachment.bind(this)
    this.activeJournal = this.activeJournal.bind(this)
    this.cancelJournal = this.cancelJournal.bind(this)
    this.activeJournalSubmit = this.activeJournalSubmit.bind(this)
    this.cancelJournalSubmit = this.cancelJournalSubmit.bind(this)
    this.handleOpenDeletePopupProduct = this.handleOpenDeletePopupProduct.bind(this)
    this.handleDeleteProduct = this.handleDeleteProduct.bind(this)
    this.handleAutocompleteInvoiceDetails = this.handleAutocompleteInvoiceDetails.bind(this)
    this.discountChange = this.discountChange.bind(this)
    this.handleInputChangeInvoiceDetail = this.handleInputChangeInvoiceDetail.bind(this)
    this.handleAddInvoiceDetails = this.handleAddInvoiceDetails.bind(this)
    this.approveInvoiceSubmit = this.approveInvoiceSubmit.bind(this)
    this.approveInvoice = this.approveInvoice.bind(this)
    this.openInvoiceEditForm = this.openInvoiceEditForm.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleSubmitInvoice = this.handleSubmitInvoice.bind(this)
    this.openTermsForm = this.openTermsForm.bind(this)
    this.handleCloseTerms = this.handleCloseTerms.bind(this)
    this.addPaymentTerms = this.addPaymentTerms.bind(this)
    this.handleTermsChange = this.handleTermsChange.bind(this)
    this.handleEmailPopup = this.handleEmailPopup.bind(this)
    this.sendEmail = this.sendEmail.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleInputChangeInvoiceDetailAmount = this.handleInputChangeInvoiceDetailAmount.bind(this)
    this.handleOpenProductTransactionSimplePopup = this.handleOpenProductTransactionSimplePopup.bind(this)
    this.handleAutocompleteOnInputChange = this.handleAutocompleteOnInputChange.bind(this)
    this.handleAttacthmentSubmit = this.handleAttacthmentSubmit.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.handleAttactmentInputChange = this.handleAttactmentInputChange.bind(this)
    this.amountChange = this.amountChange.bind(this)
    this.donwPaymentChange = this.donwPaymentChange.bind(this)
    this.reverseJournal = this.reverseJournal.bind(this)
    this.handleProductTransactionFormInputChange = this.handleProductTransactionFormInputChange.bind(this)
    this.handleProductTransactionAutocomplete = this.handleProductTransactionAutocomplete.bind(this)
    this.handleSaveProductTransaction = this.handleSaveProductTransaction.bind(this)
    this.changePrintLayout = this.changePrintLayout.bind(this)
    this.openPrint = this.openPrint.bind(this)
    this.changeSign = this.changeSign.bind(this)
    this.submitChangeSign = this.submitChangeSign.bind(this)
    this.handleInputChangePDF = this.handleInputChangePDF.bind(this)
    this.openPrintPDF = this.openPrintPDF.bind(this)
  }

  componentDidMount() {
    this.loadInvoice(process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.props.match.params.id)
  }

  loadInvoice(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      const invoice = resp.data
      this.setState({ invoice: invoice, invoiceForEdit: invoice, branch: invoice.branch, load: true,
        initialProductTransaction: { ...this.state.initialProductTransaction, invoice_no: resp.data.reference_no, date_of_password: resp.data.date_of_invoice } })
      this.setState({ selectedClient: invoice.client, isInstallment: resp.data.payment_term.name.toLowerCase().includes("installment") })
      this.loadClientsAutoCompleteOptions()
      this.loadClientContactPersonsOptions(invoice.client)
      this.loadProductsAutoCompleteOptions()
      this.loadPaymentTermsAutoCompleteOptions()
      this.loadBranchesAutoCompleteOptions()
      this.loadCwtTypes()
      this.loadAttachments(invoice.id)

      if (this.state.current_user.is_from_cdasia) {
        this.loadProductTransactions()
        this.loadInvoiceCds(invoice.id)
        this.loadInvoiceDeliveryDetails(invoice.id)
        this.loadInvoiceProducts(invoice.id)
        this.loadDeliveryEmployees()
      }

      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + invoice.id + '/journal_entries',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({journalEntries: resp.data})
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + invoice.id + '/deliveries',
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          this.setState({deliveries: resp.data})
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + invoice.id + '/invoice_details',
            headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
          }).then(resp => {
            this.setState({invoiceDetails: resp.data})
          }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + invoice.id + '/receipt_contents',
            headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
          }).then(resp => {
            this.setState({ receiptContents: resp.data })
          }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
        }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))

  }

  loadProductTransactions () {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/product_transactions/search',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')},
      params: { invoice_no: this.state.invoice.reference_no, per_page: 1000000 }
    }).then(resp => {
      this.setState({ productTransactions: resp.data.product_transactions })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadCwtTypes () {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoice_cwt_types',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ cwtTypes: resp.data })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadAttachments (invoice_id) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + invoice_id + "/attachments",
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ attachments: resp.data })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleSubmit() {
    const item = this.state.invoiceForEdit
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/invoices/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      const newData = resp.data
      this.setState({
        invoices: this.state.invoices.filter(invoice => invoice.id !== item.id)
      })
      this.setState({invoices: [...this.state.invoices, newData]})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({openPopup: false})
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.invoice.id
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        invoices: this.state.invoices.filter(item => item.id !== DeleteItemId)
      })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
      this.setState({openDeletePopup: false})
      this.setState({redirectToIndex: true})
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleClose() {
    this.setState({openPopup: false})
    this.setState({openDeletePopup: false})
    this.setState({error_messages: ''})
    this.setState({cancelInvoicePopup: false})
    this.setState({activeInvoicePopup: false})
    this.setState({openAttachmentPopup: false})
    this.setState({activeJournalPopup: false})
    this.setState({cancelJournalPopup: false})
    this.setState({openDeletePopupProduct: false})
    this.setState({openPopupProduct: false})
    this.setState({approveInvoicePopup: false})
    this.setState({openInvoiceEditPopup: false})
    this.setState({openTerms: false})
    this.setState({emailInvoicePopup: false})
    this.setState({ openNewAndEditProductTransactionPopup: false }) 
    this.setState({ openPrintPopup: false, changeSignPopup: false, signatory: {...this.state.signatory, creator: ''},openPrintPDFPopup: false })

  }

  openInvoiceEditForm() {
    const invoice = this.state.invoice
    this.setState({openInvoiceEditPopup: true, invoiceForEdit: invoice, selectedClient: invoice.client, branch: invoice.branch,
                   isInstallment: invoice.payment_term.name.toLowerCase() === "installment"})
  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({invoiceDetail: {discount: 0, qty: 1, year: new Date().getFullYear()}})
    this.setState({invoiceDetailsValue: item.product})
    this.setState({openPopupProduct: true})
    this.setState({invoiceDetail: item})
    this.setState({title: textTitle})
  }

  handleInputChange(e) {
    if (e.target.name.includes("payment_term") && this.state.paymentTerms.length > 0) {
      const payment_term = this.state.paymentTerms.find((item) => item.id === e.target.value)
      if (payment_term.name.toLowerCase().includes("installment")) {
        this.setState({ isInstallment: true })
      } else {
        this.setState({ isInstallment: false })
      }

      this.setState({ invoiceForEdit: { ...this.state.invoiceForEdit, payment_term: payment_term, payment_term_id: payment_term.id, no_of_months: 0, monthly_payment: 0, down_payment: 0,
                      date_of_maturity: moment(this.state.invoice.date_of_invoice).add(parseInt(1), 'month').format("yyyy-MM-DD") } })
    } else if (e.target.name === "no_of_months") {
      var amountTotal = 0
      var taxVat = 0
      var totalAmount =  this.state.invoiceDetails.reduce((a,v) =>  a = a + parseInt(v.amount) , 0 )
      if (this.state.selectedClient.tax_exempted === false) {
        taxVat = totalAmount * .12
        amountTotal = taxVat + totalAmount
      }else {
        amountTotal = totalAmount
      }
      this.setState({ invoiceForEdit: { ...this.state.invoiceForEdit, no_of_months: parseInt(e.target.value), date_of_maturity: moment().add(parseInt(e.target.value), 'month').format("yyyy-MM-DD"),
                                              monthly_payment: (amountTotal - this.state.invoiceForEdit.down_payment) / parseInt(e.target.value)} })  }
    else {
      this.setState({
        invoiceForEdit: {
          ...this.state.invoiceForEdit, [e.target.name]: e.target.value
        }
      })
    }
  }

  handleChange(event, newValue) {
    this.setState({value: newValue})
  }

  handleOpenDeletePopup(model) {
    this.setState({model: model})
    this.setState({invoice: this.state.invoice})
    this.setState({openDeletePopup: true})
  }


  cancelCheckVoucher() {
    this.setState({cancelInvoicePopup: true})
    this.setState({activeInvoicePopup: true})
  }

  openAttachment() {
    this.setState({openAttachmentPopup: true})
  }

  activeJournal() {
    this.setState({activeJournalPopup: true})
  }

  cancelJournal() {
    this.setState({cancelJournalPopup: true})
  }

  approveInvoice() {
    this.setState({approveInvoicePopup: true})
  }

  approveInvoiceSubmit() {
    const id = this.props.match.params.id
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + id + '/approve',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then((resp) => {
      this.setState({invoice: resp.data, invoiceForEdit: resp.data})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({approveInvoicePopup: false})
    })

  }

  cancelInvoiceSubmit() {
    const id = this.props.match.params.id
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + id + '/cancel',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then((resp) => {
      this.setState({invoice: resp.data, invoiceForEdit: resp.data})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({cancelInvoicePopup: false})
    })

  }

  activeInvoiceSubmit() {
    const id = this.props.match.params.id
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + id + '/activate',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then((resp) => {
      this.setState({invoice: resp.data, invoiceForEdit: resp.data})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({activeInvoicePopup: false})
    })
  }

  activeJournalSubmit() {
    const id = this.state.invoice.journal_id
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/journals/' + id + '/post',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then((resp) => {
      this.setState({invoice: {...this.state.invoice, journal: resp.data, status: "Posted"}})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({activeInvoicePopup: false})
    })
  }

  cancelJournalSubmit() {
    const id = this.state.invoice.journal_id
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/journals/' + id + '/unpost',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then((resp) => {
      this.setState({invoice: resp.data, invoiceForEdit: resp.data})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({activeInvoicePopup: false})
    })
  }

  handleDeleteClose() {
    this.setState({openDeletePopup: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      isOpen: false
    })
  }

  sendEmail() {
    const id = this.state.invoice.id
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + id + '/email',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({emailInvoicePopup: false})
    })
  }

  handleEmailPopup() {
    this.setState({emailInvoice: {emails: '', subject: '', content: ''}})
    this.setState({emailInvoicePopup: true})
  }

  handleEmailChange(e) {
    this.setState({
      emailInvoice: {
        ...this.state.emailInvoice,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopupProduct(item, model) {
    this.setState({model: model})
    this.setState({product: item})
    this.setState({openDeletePopupProduct: true})
  }

  handleDeleteProduct() {
    const DeleteItemId = this.state.invoice.id
    const DeleteProductId = this.state.product.id
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + DeleteItemId + '/invoice_details/' + DeleteProductId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        invoiceDetails: this.state.invoiceDetails.filter(item => item.id !== DeleteProductId)
      })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
      this.setState({openDeletePopupProduct: false})
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleSubmitInvoice() {
    var item = this.state.invoiceForEdit

    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.state.invoice.id,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      var newData = resp.data
      this.setState({
        invoices: this.state.invoices.filter(term => term.id !== item.id)
      })
      this.setState({invoice: newData, invoiceForEdit: newData})
      this.setState({selectedClient: newData.client})
      this.setState({branch: newData.branch})
      this.setState({openInvoiceEditPopup: false})
    })
  }

  handleInputChangeInvoiceDetailAmount(e) {

    if (this.state.invoiceDetail.tax_exempt !== false) {
      this.setState({
        invoiceDetail: {
          ...this.state.invoiceDetail,
          unit_amount: e.floatValue,
          amount: ((e.floatValue) * (1 - (this.state.invoiceDetail.discount / 100)) * this.state.invoiceDetail.qty),
          tax_amount: 0
        }
      })
    } else {
      this.setState({
        invoiceDetail: {
          ...this.state.invoiceDetail,
          unit_amount: e.floatValue,
          amount: ((e.floatValue) * (1 - (this.state.invoiceDetail.discount / 100)) * this.state.invoiceDetail.qty),
          tax_amount: ((e.floatValue) * (1 - (this.state.invoiceDetail.discount / 100)) * this.state.invoiceDetail.qty) * .12
        }
      })
    }
  }

  handleInputChangeInvoiceDetail(e) {
    if (e.target.name === "year") {
      this.setState({
        invoiceDetail: {
          ...this.state.invoiceDetail,
          [e.target.name]: e.target.value,
          remarks: this.state.invoiceDetail.remarks + ' ' + e.target.value
        }
      })
    } else if (e.target.name === "qty") {
      this.setState({
        invoiceDetail: {
          ...this.state.invoiceDetail,
          qty: e.target.value,
          amount: ((this.state.invoiceDetail.unit_amount) * (1 - (this.state.invoiceDetail.discount / 100)) * e.target.value),
          tax_amount: ((this.state.invoiceDetail.unit_amount) * (1 - (this.state.invoiceDetail.discount / 100)) * e.target.value) * .12
        }
      })
    } else {
      this.setState({
        invoiceDetail: {
          ...this.state.invoiceDetail,
          [e.target.name]: e.target.value

        }
      })
    }
  }

  openTermsForm() {
    this.setState({payment_term: {id: '', name: '', description: ''}})
    this.setState({openTerms: true})
  }

  handleAutocompleteInvoiceDetails(e, values) {
    if (values !== null) {
      this.setState({
        invoiceDetail: {
          ...this.state.invoiceDetail,
          [e.target.name]: e.target.value
        }
      })
      this.setState({
        invoiceDetail: {
          ...this.state.invoiceDetail,
          remarks: values.name,
          unit_amount: values.amount,
          amount: values.amount,
          unit: values.unit,
          tax_exempt: values.tax_exempt,
          is_tax_exempted: values.tax_exempt,
          product_id: values.id,
          product: values
        }
      })
    } else {
      this.setState({
        invoiceDetail: {
          ...this.state.invoiceDetail,
          remarks: "",
          unit_amount: 0,
          amount: 0,
          product: {}
        }
      })
      // This is the reload of the products autocomplete.
      this.loadProductsAutoCompleteOptions()
    }
  }

  discountChange(e) {
    if (this.state.amount === undefined) {
      this.setState({amount: 0})
    }
    this.setState({
      invoiceDetail: {
        ...this.state.invoiceDetail,
        discount: e.floatValue,
        amount: ((this.state.invoiceDetail.unit_amount) * (1 - (e.floatValue / 100)) * (this.state.invoiceDetail.qty)),
        tax_amount: ((this.state.invoiceDetail.unit_amount) * (1 - (e.floatValue / 100)) * (this.state.invoiceDetail.qty)) * .12
      }
    })
  }

  handleAddInvoiceDetails() {
    const invoiceId = this.state.invoice.id
    const item = this.state.invoiceDetail

    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + invoiceId + '/invoice_details/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + invoiceId + '/invoice_details/' + item.id
    }

    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      const newData = resp.data
      this.setState({
        invoiceDetails: this.state.invoiceDetails.filter(invoice => invoice.id !== item.id)
      })
      this.setState({invoiceDetails: [...this.state.invoiceDetails, newData]})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.props.match.params.id,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({invoice: resp.data, invoiceForEdit: resp.data})
      })
      this.setState({openPopupProduct: false})
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleCloseTerms() {
    this.setState({openTerms: false})
  }

  addPaymentTerms() {
    const item = this.state.payment_term
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/payment_terms/',
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    })
      .then(resp => {
        var newData = resp.data

        this.setState({
          paymentTerms: this.state.paymentTerms.filter(term => term.id !== item.id)
        })
        this.setState({paymentTerms: [...this.state.paymentTerms, newData]})
        this.setState({
          invoiceForEdit: {
            ...this.state.invoiceForEdit,
            payment_terms_id: resp.data.id
          }
        })
        this.setState({openTerms: false})
      })
      .catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleTermsChange(e) {
    this.setState({
      payment_term: {
        ...this.state.payment_term,
        [e.target.name]: e.target.value
      }
    })

  }

  handleOpenProductTransactionSimplePopup(item, isAdd, targetIndex = 0) {
    var popupHeader = ''
    isAdd ? (popupHeader = 'Add Product Given Password') : (popupHeader = 'Edit Product Given Password')
    this.setState({ openNewAndEditProductTransactionPopup: true, initialProductTransaction: item, popupHeader: popupHeader, selectedProductTransactionsIndex: parseInt(targetIndex) })
  }

  loadClientsAutoCompleteOptions(searchText = '') {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/search',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')},
      params: { name: searchText }
    }).then(resp => {
      this.setState({ clients: resp.data.clients, clientsAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadClientContactPersonsOptions(client) {
    if (![null, undefined].includes(client)) {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + client.id + '/contact_persons',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ contactPersons: resp.data, invoiceForEdit: { ...this.state.invoiceForEdit, contact_person_id: resp.data[0] ? (resp.data[0].id) : ("") } })
      })
    } else {
      this.setState({ contactPersons: [] })
    }
  }

  loadProductsAutoCompleteOptions (searchText = '') {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/products/search',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')},
      params: { name: searchText }
    }).then(resp => {
      this.setState({ products: resp.data.products })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadPaymentTermsAutoCompleteOptions () {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/payment_terms',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ paymentTerms: resp.data })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadBranchesAutoCompleteOptions () {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ branches: resp.data })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleAutocomplete(event, value) {
    if (event.target.id.includes("client")) {
      this.setState({ selectedClient: value || {}, invoiceForEdit: { ...this.state.invoiceForEdit, client_id: ![null, undefined].includes(value) ? (value.id) : '' } })
      this.loadClientContactPersonsOptions(value || this.state.invoice.client)
    } else if (event.target.id.includes("branch")) {
      var reference_no = value !== undefined && value !== null ? (value.name.substr(0, 1) + '-') : ('')
      this.setState({ branch: value, invoiceForEdit: { ...this.state.invoiceForEdit, reference_no: reference_no } })
    } else if (event.target.id.includes("payment_term")) {
      this.setState({ invoiceForEdit: { ...this.state.invoiceForEdit, payment_term: value, payment_term_id: ![null, undefined].includes(value) ? (value.id) : '' } })
    }
  }

  handleAutocompleteOnInputChange(event, value, reason) {
    if (reason === 'input') {
      if (event.target.id.includes("client")) {
        this.setState({ clientsAutoCompleteLoading: true })
        this.loadClientsAutoCompleteOptions(value || '')
      } else if (event.target.id.includes("product")) {
        this.loadProductsAutoCompleteOptions(value || '')
      }
    }
  }

  donwPaymentChange(e) {
    var amountTotal = 0
    var taxVat = 0
    var totalAmount =  this.state.invoiceDetails.reduce((a,v) =>  a = a + parseInt(v.amount), 0 )
    if (this.state.selectedClient.tax_exempted === false) {
      taxVat = totalAmount * .12
      amountTotal = taxVat + totalAmount
    }else {
      amountTotal = totalAmount
    }
    this.setState({invoiceForEdit: {...this.state.invoiceForEdit, down_payment: e.floatValue, monthly_payment: (amountTotal - e.floatValue) /  0 }
    })
  }

  amountChange(e) {
    this.setState({invoice: {...this.state.invoice, monthly_payment:  e.floatValue}})
  }

  handleAttacthmentSubmit() {
    const formdata = new FormData()
    formdata.append('file', this.state.attachment.file)
    formdata.append('title', this.state.attachment.file.name)
    formdata.append('description', this.state.attachment.description)
    formdata.append('category_name', this.state.attachment.category_name)
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.state.invoice.id + '/attachments',
      data: (formdata),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ attachments: [...this.state.attachments, resp.data],
                      isOpen: true, message: 'Submitted Successfully', type: 'success', openAttachmentPopup: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  onFileChange(e) {
    this.setState({ attachment: { ...this.state.attachment, file: e.target.files[0], title: e.target.files[0].name } })
  }

  handleAttactmentInputChange(e) {
    this.setState({ attachment: { ...this.state.attachment, [e.target.name]: e.target.value } })
  }

  reverseJournal() {
    const id = this.state.invoice.journal_id
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/journals/' + id + '/reverse_entries',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      setTimeout(() => {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.state.invoice.id + '/journal_entries',
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          this.setState({journalEntries: resp.data, isOpen: true, message: 'Submitted Successfully', type: 'success'})
        }).catch(error => console.log(error.response))
      }, 1000)
    })
  }

  handleChangeTabs(event, newValue) {
    this.setState({value: newValue})
  }

  handleProductTransactionFormInputChange(event) {
    this.setState({ initialProductTransaction: { ...this.state.initialProductTransaction, [event.target.id]: event.target.value } })
  }

  handleProductTransactionAutocomplete(event, value) {
    if (event.target.id.includes("employee")) {
      this.setState({ initialProductTransaction: { ...this.state.initialProductTransaction, employee: value || {} } })
    } else if (event.target.id.includes("delivery")) {
      if (![null, undefined].includes(value)) {
        const delivery_details = this.state.invoiceDeliveryDetails.filter(detail => detail.delivery_id === value.id)
        const cd_ids = delivery_details.map(detail => detail.cd_id)
        let product_ids = []
        delivery_details.map(detail => detail.products.map(product => product_ids.push(product.id)))
        this.setState({ initialProductTransaction: { ...this.state.initialProductTransaction, delivery: value, date_of_password: this.state.invoice.date_of_invoice },
                        filteredInvoicdCds: this.state.invoiceCds.filter(cd => cd_ids.includes(cd.id)),
                        filteredInvoiceProducts: this.state.invoiceProducts.filter(product => product_ids.includes(product.id)) })
      } else {
        this.setState({ initialProductTransaction: { ...this.state.initialProductTransaction, delivery: {}, date_of_password: this.state.invoice.date_of_invoice,
                        filteredInvoicdCds: this.state.invoiceCds, filteredInvoiceProducts: this.state.invoiceProducts } })
      }
    } else if (event.target.id.includes("cd")) {
      if (![null, undefined].includes(value)) {
        this.setState({ initialProductTransaction: { ...this.state.initialProductTransaction, cd: value || {} },
                        filteredInvoiceProducts: this.state.filteredInvoiceProducts.filter(product => product.cd_category_ids.includes(value.cd_category_id)) })
      } else {
        this.setState({ initialProductTransaction: { ...this.state.initialProductTransaction, cd: value || {} }, filteredInvoiceProducts: this.state.invoiceProducts })
      }
    } else if (event.target.id.includes("product")) {
      this.setState({ initialProductTransaction: { ...this.state.initialProductTransaction, product: value || {} } })
    }
  }

  loadInvoiceCds(invoice_id) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + invoice_id + '/cds',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      const cds = resp.data.sort(function (a, b) {
        if (parseInt(a.description.match(/\d/g).join("")) > parseInt(b.description.match(/\d/g).join(""))) return -1;
        if (parseInt(a.description.match(/\d/g).join("")) < parseInt(b.description.match(/\d/g).join(""))) return 1;
        return 0;
      })

      this.setState({ invoiceCds: cds, filteredInvoicdCds: cds})
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadInvoiceDeliveryDetails(invoice_id) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + invoice_id + '/delivery_details',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ invoiceDeliveryDetails: resp.data })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadDeliveryEmployees() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/deliveries/employees',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ deliveryEmployees: resp.data })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadInvoiceProducts(invoice_id) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + invoice_id + '/products',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      const products = resp.data.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      })

      this.setState({ invoiceProducts: products, filteredInvoiceProducts: products })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleSaveProductTransaction() {
    const item = this.state.initialProductTransaction
    let method = ''
    let url = ''

    if (item.id) {
      method = "PUT"
      url = process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.state.invoice.id + '/product_transactions/' + item.id
    } else {
      method = "POST"
      url = process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.state.invoice.id + '/product_transactions/'
    }
    let product_transaction = { id: item.id, product_id: item.product.id, employee_id: item.employee.id, cd_id: item.cd.id,
                                date_of_password: item.date_of_password, reason: item.reason, status: item.status }

    if (![null, undefined].includes(item.delivery)) {
      const delivery_detail = this.state.invoiceDeliveryDetails.filter(detail => detail.delivery_id === item.delivery.id && detail.cd_id === item.cd.id)
      product_transaction.delivery_id = item.delivery.id
      product_transaction.delivery_detail_id = delivery_detail[0].id
    }

    axios({
      method: method,
      url: url,
      data: product_transaction,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      if (this.state.isAddProductTransaction) {
        this.state.productTransactions.push(resp.data)
      } else {
        this.state.productTransactions.splice(this.state.selectedProductTransactionsIndex, 1, resp.data)
      }
      this.setState({ openAlertMessage: true, alertMessage: 'Submitted Successfully', type: 'success' })
      this.handleCloseSimplePopup()
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  openPrint() {
    this.setState({ openPrintPopup: true })
  }
  
  openPrintPDF() {
    this.setState({ openPrintPDFPopup: true })
  }
  
  changeSign() {
    this.setState({ changeSignPopup: true })
  }

  changePrintLayout() {
    return new Promise((resolve) => {
      this.setState({ forPrint: !this.state.forPrint}, () => resolve());
    });
  } 

  handleInputChangePDF(e) {
    this.setState({ signatory: {  ...this.state.signatory, [e.target.name]: e.target.value  } })
  }

  submitChangeSign() {
    this.setState({invoice: {...this.state.invoice, creator_name: this.state.signatory.creator}, changeSignPopup: false})
  }


  render() {
    const {redirectToIndex} = this.state
    if (redirectToIndex)
      return (<Redirect to={'/invoices'}/>)

    const printPdf = <ReactToPrint
      trigger={() => {
        return <Button color="primary" variant='outlined'>No</Button>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />

    const ChangeSignPrintPdf = <ReactToPrint
      trigger={() => {
        return <Button color="primary" variant='outlined'>Proceed</Button>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />
    return (
      <>
        {
          this.state.load ? (
            <div ref={el => (this.componentRef = el)} className={this.state.forPrint ? styles.tableBodyPrint : null}>
              <CompanyHeader forPrint={this.state.forPrint}/>
              <div className={styles.showTitleHeader}>
                <div><h2>Invoice Information</h2></div>
                <div className={this.state.forPrint ? styles.noDisplay: null}>
                  <div className={styles.groupButtonShow}>
                    <Link to="/invoices">
                      <Tooltip title="Back" arrow>
                        <IconButton>
                          <ArrowBackIosOutlinedIcon/>
                        </IconButton>
                      </Tooltip>
                    </Link>
                    <Tooltip title="Email Invoice">
                      <IconButton
                        name="email_invoice_button"
                        onClick={this.handleEmailPopup}
                      >
                        <MailOutlineOutlinedIcon/>
                      </IconButton>
                    </Tooltip>
                    {this.state.invoice.journal === null ? null :
                    <>
                    {this.state.invoice.can_approve && this.state.invoice.status == 'for_approval' && !this.state.invoice.journal.is_posted ? (
                      <Tooltip title="Approve Invoice">
                        <IconButton
                          name="approve_invoice_button"
                          onClick={this.approveInvoice}
                        >
                          <CheckCircleOutlineOutlinedIcon/>
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    {this.state.invoice.can_cancel && this.state.invoice.status != 'cancelled' && !this.state.invoice.journal.is_posted ? (
                      <Tooltip title="Cancel Invoice">
                        <IconButton
                          name="cancel_invoice_button"
                          onClick={this.cancelCheckVoucher}
                        >
                          <CancelOutlinedIcon/>
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    {this.state.invoice.can_delete && !this.state.invoice.journal.is_posted ? (
                      <Tooltip title="Delete Invoice">
                        <IconButton
                          color="secondary"
                          name="delete_invoice_button"
                          onClick={this.handleOpenDeletePopup}
                        >
                          <DeleteIcon/>
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {this.state.invoice.can_edit && !this.state.invoice.journal.is_posted ? (
                      <Tooltip title="Edit">
                        <IconButton
                          color="primary"
                          name="edit_invoice_button"
                          onClick={this.openInvoiceEditForm}
                        >
                          <EditIcon/>
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    </>
                    }
                  </div>
                </div>
              </div>
              <hr/>
              <div className={styles.detailsBody}>
                <div className={styles.leftShowDetails}>

                  <div className={styles.detailList}>
                    <div><b> Client Name </b></div>
                    <div>:&emsp;  <a href={'/clients/' + this.state.invoice.client.id} target="_blank"
                                     rel="noopener noreferrer">{this.state.invoice.client.name}</a></div>
                  </div>

                  <div className={styles.detailList}>
                    <div><b>Address</b></div>
                    <div> <div className={styles.customAddress}><div>:&emsp;</div> <div>{this.state.invoice.client.company_address}</div></div></div>
                  </div>

                  <div className={styles.detailList}>
                    <div><b> Contact Person </b></div>
                    <div>:&emsp; {this.state.invoice.contact_person !== null ? this.state.invoice.contact_person.name : 'n/a'} </div>
                  </div>

                  <div className={styles.detailList}>
                    <div><b> PO No. </b></div>
                    <div>:&emsp; {this.state.invoice.po_no} </div>
                  </div>

                  <div className={styles.detailList}>
                    <div><b> Due Date </b></div>
                    <div>:&emsp; { moment(this.state.invoice.date_of_maturity).format("MM/DD/YYYY") } </div>
                  </div>
                  <div className={styles.detailList}>
                    <div><b> Number of Months </b></div>
                    <div>:&emsp; {this.state.invoice.no_of_months} </div>
                  </div>
                  <div className={styles.detailList}>
                    <div><b> Total Amount</b></div>
                    <div>:&emsp; {this.state.invoice.total.toLocaleString(navigator.language, {minimumFractionDigits: 2})} </div>
                  </div>
                  <div className={styles.detailList}>
                    <div><b>TIN No.</b></div>
                    <div>:&emsp; {this.state.invoice.client.tin_no} </div>
                  </div>
                  <div className={styles.detailList}>
                    <div><b>Remarks</b></div>
                    <div>:&emsp; {this.state.invoice.remarks} </div>
                  </div>

                </div>

                <div className={styles.rightShowDetails}>
                  <div className={styles.detailList}>
                    <div><b> Invoice Number </b></div>
                    <div>:&emsp;&emsp; {this.state.invoice.reference_no}</div>
                  </div>
                  <div className={styles.detailList}>
                    <div><b>Date of Invoice </b></div>
                    <div>:&emsp;&emsp; { moment(this.state.invoice.date_of_invoice).format("MM/DD/YYYY") }</div>
                  </div>
                  <div className={styles.detailList}>
                    <div><b>CWT</b></div>
                    <div>:&emsp;&emsp; {this.state.invoice.cwt_type_id ? this.state.invoice.cwt_type.name + "(" + parseFloat(this.state.invoice.cwt_type.multiplier) * 100 + "%)" : "N/A" } </div>
                  </div>
                  <div className={styles.detailList}>
                    <div><b> Payment Status</b></div>
                    <div>:&emsp;&emsp; {this.state.invoice.payment_status} </div>
                  </div>

                  <div className={styles.detailList}>
                    <div><b> Terms of Payment </b></div>
                    <div>:&emsp;&emsp; {this.state.invoice.payment} </div>
                  </div>
                  <div className={styles.detailList}>
                    <div><b>Downpayment</b></div>
                    <div>:&emsp;&emsp; {parseFloat(this.state.invoice.down_payment).toLocaleString(navigator.language, {minimumFractionDigits: 2})} </div>
                  </div>
                  <div className={styles.detailList}>
                    <div><b>Monthly Payment</b></div>
                    <div>:&emsp;&emsp; {parseFloat(this.state.invoice.monthly_payment).toLocaleString(navigator.language, {minimumFractionDigits: 2})} </div>
                  </div>
                  <div className={styles.detailList}>
                    <div><b>Balance</b></div>
                    <div>:&emsp;&emsp; {this.state.invoice.remaining_balance.toLocaleString(navigator.language, {minimumFractionDigits: 2})} </div>
                  </div>
                  <div className={styles.detailList}>
                    <div><b>Prepared by</b></div>
                    <div>:&emsp;&emsp; {this.state.invoice.creator_name} </div>
                  </div>
                  <div className={styles.detailList}>
                    <div><b>Post Status</b></div>
                    <div>:&emsp;&emsp; {this.state.invoice.status.replace('_', ' ')} </div>
                  </div>
                </div>
              </div>
              <hr/>
              <div>
                <Tabs value={this.state.value} onChange={this.handleChange} indicatorColor={this.state.forPrint? "": "secondary"}
                      className={styles.tabBody} variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example">
                  <Tab className={this.state.openPrintPDFPopup? styles.noDisplay: styles.tabList} label={<div className={styles.tabListTitle}>
                    {/*<PersonOutlineIcon /> */}
                    <span className={styles.tabLabel}> <b> INVOICE DETAILS </b> </span></div>}{...a11yProps(0)} />
                  <Tab className={this.state.forPrint? styles.noDisplay : styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}> <b> PAYMENTS </b> </span></div>} {...a11yProps(1)} />
                  
                  {this.state.invoice.journal === null ? null:
                   <Tab className={this.state.openPrintPopup? styles.noDisplay:styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}> <b> JOURNAL ENTRIES </b> </span></div>} {...a11yProps(3)} />
                  }
                  <Tab className={this.state.forPrint? styles.noDisplay : styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}> <b> ATTACHMENTS </b> </span></div>}{...a11yProps(4)} />
                  {
                    this.state.current_user.is_from_cdasia ? (
                      <Tab className={this.state.forPrint? styles.noDisplay : styles.tabList} label={<div className={styles.tabListTitle}>
                        <span className={styles.tabLabel}> <b> PRODUCT GIVEN PASSWORDS </b> </span></div>} {...a11yProps(5)} />
                    ) : null
                  }
                  {
                    this.state.current_user.is_from_cdasia ? (
                    <Tab className={this.state.forPrint? styles.noDisplay : styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}> <b> DELIVERIES </b> </span></div>}{...a11yProps(2)} />
                    ) : null
                  }
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}> <b> AUDIT LOGS </b> </span></div>}{...a11yProps(6)} />
                </Tabs>

                <TabPanel value={this.state.value} index={0} className={styles.tabListBody}>
                  <ProductDetailTab
                    invoice_details={this.state.invoiceDetails}
                    invoice={this.state.invoice}
                    client={this.state.client}
                    model="Invoice Detail"
                    onOpenDeletePopup={this.handleOpenDeletePopupProduct}
                    handleClick={this.handleCreateorUpdateItem}
                    initialItem={this.state.initialItem}
                    forPrint={this.state.forPrint}
                    openPrint={this.openPrint}
                  />
                </TabPanel>

                <TabPanel value={this.state.value} index={1} className={styles.tabListBody}>
                  <PaymentDetailTab
                    receiptContents={this.state.receiptContents}
                    pathname={this.state.invoice.id}
                  />
                </TabPanel>
                {this.state.invoice.journal === null ? null:
                <TabPanel value={this.state.value} index={2} className={styles.tabListBody}>
                  <JournalEntriesDetailsTab
                    invoice={this.state.invoice}
                    journalEntries={this.state.journalEntries}
                    activeJournal={this.activeJournal}
                    cancelJournal={this.cancelJournal}
                    reverseJournal={this.reverseJournal}
                    openPrint={this.openPrintPDF}
                    forPrint={this.state.forPrint}
                  />
                </TabPanel>
                }

                <TabPanel value={this.state.value} index={this.state.invoice.journal === null? 2: 3} className={styles.tabListBody}>
                  <AttachmentsTab
                    openAttachmentForm={this.openAttachment}
                    attachments={this.state.attachments}
                  />
                </TabPanel>
                {
                  this.state.current_user.is_from_cdasia ? (
                    <TabPanel value={this.state.value} index={this.state.invoice.journal === null? 3: 4} className={styles.tabListBody}>
                      <ProductTransactionsTab
                        productTransactions={this.state.productTransactions}
                        initialProductTransaction={this.state.initialProductTransaction}
                        handleOpenProductTransactionSimplePopup={this.handleOpenProductTransactionSimplePopup}
                      />
                    </TabPanel>
                  ) : null
                }
                {
                  this.state.current_user.is_from_cdasia ? (
                    <TabPanel value={this.state.value} index={5} className={styles.tabListBody}>
                      <DeliveriesDetailTab
                        deliveries={this.state.deliveries}
                        pathname={this.state.invoice.id}
                      />
                    </TabPanel>
                  ) : null
                }
                {
                  this.state.current_user.is_from_cdasia ? (
                    <TabPanel value={this.state.value} index={this.state.invoice.journal === null? 5: 6} className={styles.tabListBody}>
                    <AuditLog gid={this.state.invoice.gid} />
                </TabPanel>
                  ) : null
                }
                 
              </div>

              <SimplePopUp
                openPopup={this.state.emailInvoicePopup}
                title="Email Invoice"
                items={this.state.emailInvoice}
                handleClose={this.handleClose}
                maxWidth="sm"
              >
                <EmailInvoiceForm
                  item={this.state.emailInvoice}
                  sendEmail={this.sendEmail}
                  handleEmailChange={this.handleEmailChange}
                />
              </SimplePopUp>


              <SimplePopUp
                openPopup={this.state.cancelInvoicePopup}
                title="Cancel Invoice"
                items={this.state.invoices}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <div>
                  <div>
                    <Typography> Would you like to cancel this Invoice? </Typography>
                  </div>
                  <div align="right"
                       className={styles.actionButton}>
                    <Button name="cancel_invoice_button" color="primary" variant="outlined"
                            onClick={this.cancelInvoiceSubmit}
                    >cancel</Button></div>
                </div>

              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.approveInvoicePopup}
                title="invoices"
                items={this.state.invoices}
                handleClose={this.handleClose}
                maxWidth="sm"
              >
                <div>
                  <div>
                    <Typography> Would you like to Approve this Invoice? </Typography>
                  </div>
                  <div align="right"
                       className={styles.actionButton}>
                    <Button color="primary" variant="outlined"
                            onClick={this.approveInvoiceSubmit}
                    >save</Button></div>
                </div>

              </SimplePopUp>


              <SimplePopUp
                openPopup={this.state.invoice.journal === null ? null:this.state.invoice.journal.is_posted === false ? this.state.activeJournalPopup : this.state.cancelJournalPopup}
                title="Journal"
                items={this.state.invoices}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                {this.state.invoice.journal === null ? null :
                  <>
                    {this.state.invoice.journal.is_posted === false ? (
                        <div>
                      <span>
                        <b>Would you like to post this Journal?</b>
                      </span>
                          <div
                            align="right"
                            className={styles.actionButton}>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={this.activeJournalSubmit}
                            >save</Button></div>
                        </div>
                      ) :
                      <div>
                      <span>
                        <b>Would you like to unpost this Journal?</b>
                      </span>
                        <div align="right" className={styles.actionButton}>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={this.cancelJournalSubmit}
                          >save</Button>
                        </div>
                      </div>
                    }
                  </>
                }
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openPopupProduct}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >

                <InvoiceDetailsForm
                  products={this.state.products}
                  item={this.state.invoiceDetail}
                  invoiceDetailsValue={this.state.invoiceDetailsValue}
                  invoiceDetailsExpirable={this.state.invoiceDetailsExpirable}
                  productsAutoCompleteLoading = {this.state.productsAutoCompleteLoading}
                  discountChange={this.discountChange}
                  handleAutocompleteInvoiceDetails={this.handleAutocompleteInvoiceDetails}
                  handleChange={this.handleInputChangeInvoiceDetail}
                  handleAddInvoiceDetails={this.handleAddInvoiceDetails}
                  handleInputChangeInvoiceDetailAmount={this.handleInputChangeInvoiceDetailAmount}
                  handleAutocompleteOnInputChange={this.handleAutocompleteOnInputChange}
                />
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openInvoiceEditPopup}
                title="Edit Invoice"
                handleClose={this.handleClose}
                maxWidth='sm'
              >
                <InvoicesFormPopUp
                  branch={this.state.branch}
                  address={this.state.address}
                  branches={this.state.branches}
                  clients={this.state.clients}
                  selectedClient={this.state.selectedClient}
                  item={this.state.invoiceForEdit}
                  paymentTerms={this.state.paymentTerms}
                  isInstallment={this.state.isInstallment}
                  contactPersons={this.state.contactPersons}
                  onchange={this.handleInputChange}
                  openInvoiceDetailsForm={this.openInvoiceDetailsForm}
                  invoiceDetails={this.state.invoiceDetails}
                  submit={this.handleSubmitInvoice}
                  handleAutocomplete={this.handleAutocomplete}
                  openTermsForm={this.openTermsForm}
                  clientsAutoCompleteLoading={this.state.clientsAutoCompleteLoading}
                  handleAutocompleteOnInputChange={this.handleAutocompleteOnInputChange}
                  amountChange={this.amountChange}
                  donwPaymentChange={this.donwPaymentChange}
                  cwtTypes={this.state.cwtTypes}
                />
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopupProduct}
                item={this.state.product}
                delete={this.handleDeleteProduct}
                handleDeleteClose={this.handleClose}
                model="invoice detail"
              />
              <SimplePopUp
                openPopup={this.state.openTerms}
                title="Add Payment Terms"
                handleClose={this.handleCloseTerms}
                maxWidth='xs'
              >
                <PaymentTermsForm item={this.state.payment_term} handleChange={this.handleTermsChange}
                                  addPaymentTerms={this.addPaymentTerms}/>
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.invoice}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model="delete invoice"
              />

              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />


              <SimplePopUp
                openPopup={this.state.openAttachmentPopup}
                title="Upload Attachments"
                items={this.state.attachments}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <AttachmentForm
                  attachment={this.state.attachment}
                  error={this.state.error_messages}
                  item={this.state.invoice}
                  onFileChange={this.onFileChange}
                  submit={this.handleAttacthmentSubmit}
                  onchange={this.handleAttactmentInputChange}/>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openNewAndEditProductTransactionPopup}
                title={this.state.popupHeader}
                handleClose={this.handleClose}
                maxWidth={true}
              >
                <InvoiceProductTransactionForm
                  client={this.state.invoice.client}
                  initialProductTransaction={this.state.initialProductTransaction}
                  cds={this.state.filteredInvoicdCds}
                  deliveries={this.state.deliveries}
                  deliveryEmployees={this.state.deliveryEmployees}
                  products={this.state.filteredInvoiceProducts}
                  handleAutocomplete={this.handleProductTransactionAutocomplete}
                  handleInputChange={this.handleProductTransactionFormInputChange}
                  handleSaveProductTransaction={this.handleSaveProductTransaction}/>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openPrintPopup? this.state.openPrintPopup : this.state.openPrintPDFPopup}
                title={this.state.openPrintPopup? "Print Invoice" : "Print Journal Entries"}
                items={this.state.bill}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              > 
                <div>
                <span>
                  <b>Do you want to overwrite signatory person?</b>
                </span>
                <div align="right" className={styles.actionButton}>
                    <div>
                      {printPdf}
                    </div>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.changeSign}
                    >Yes</Button></div>
                </div>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.changeSignPopup}
                title="Change Signatory"
                items={this.state.bill}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              > 
                <form>
                <TextField
                  autoComplete="off"
                  className={styles.textFields}
                  variant="outlined"
                  size="small"
                  name="creator"
                  fullWidth="true"
                  label="Name"
                  onChange={this.handleInputChangePDF}
                  value={this.state.signatory.creator}
                  id="bill-name"
                />
                </form>
                <div align="right" className={styles.actionButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.handleClose}
                    >Cancel</Button>
                    <div onClick={this.submitChangeSign}>{ChangeSignPrintPdf}</div> 
                </div>
              </SimplePopUp>

              <footer className={this.state.forPrint?null:styles.noDisplay} >
                <div className={styles.signLine}></div>
                <h3 >{this.state.invoice.creator_name}</h3>
              </footer> 
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default InvoiceShow
InvoiceShow.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
}
