// eslint-disable-next-line

import React from 'react'

import SimpleTable from '../../SimpleTable/SimpleTable'

import axios from 'axios'
import AlertMessage from "../../Notify/AlertMessage";
import ProgressBar from "../../ProgressBar/ProgressBar";
import JournalSearchForm from './JournalSearchForm';

import CreditCardIcon from '@material-ui/icons/CreditCard'
import PropTypes from "prop-types";
import { withRouter, Redirect } from 'react-router-dom'
import * as CommonFunction from "../../Custom/CommonFunctions"
export const Component = withRouter(() => {

})
class Journals extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			model: 'Journal',
			journals: [],
			banks: [],
			payees: [],
			branches: [],
			openPopup: false,
			openDeletePopup: false,
			journal: {},
			initialjournal: {
				id: '',
				date_of_check: '',
				prefix: '',
				check_no: '',
				reference_no: '',
				bank_id: '',
				branch_id: '',
				payee_id: '',
				journal_id: '',
				post_status: '',
			},
			searchjournal: {},
			title: '',
			load: false,
			tableHead: ['date_of_journal', 'reference_no', 'description', 'branch_name', 'amount', 'is_posted'],
			tableHeader: ['date_of_journal', 'reference_no', 'description', 'amount', 'is_posted'],
			withShow: false,
			withPagination: true,
			totalPages: 1,
			currentPage: 1,
			maxWidth: 'sm',
			error_messages: '',
			isOpen: false,
			message: '',
			type: '',
      clickableTableRow: true,
			redirectToAdd: false,
			totalResults: 0,
      perPage: '20'
		}
		this.handleDeleteItem = this.handleDeleteItem.bind(this)
		this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
		this.handleClose = this.handleClose.bind(this)
		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
		this.handleDeleteClose = this.handleDeleteClose.bind(this)
		this.handleCloseAlert = this.handleCloseAlert.bind(this)
		this.handlePageChange = this.handlePageChange.bind(this)
		this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
		this.handleAutocompleteSearch = this.handleAutocompleteSearch.bind(this)
		this.handleSearch = this.handleSearch.bind(this)
		this.clearSearch = this.clearSearch.bind(this)
		this.handleKeyPress = this.handleKeyPress.bind(this)
		this.handlePerPageChange=this.handlePerPageChange.bind(this)
		this.loadjournal = this.loadjournal.bind(this)
	}

	componentDidMount() {
		var url = ''
		if (this.props.location.search === '') {
			url = process.env.REACT_APP_API_DOMAIN + '/v1/journals/search?'
		} else {
			var searchItem = {}
			url = process.env.REACT_APP_API_DOMAIN + '/v1/journals/search?'
			const query = new URLSearchParams(this.props.location.search);
			var urlOptions = []

			if (query.get('starts_on_from') !== null && query.get('starts_on_from') !== '') {
				searchItem["starts_on_from"] = query.get('starts_on_from')
				urlOptions.push("starts_on_from=" + query.get('starts_on_from'))
			}
			if (query.get('starts_on_to') !== null && query.get('starts_on_to') !== '') {
				searchItem["starts_on_to"] = query.get('starts_on_to')
				urlOptions.push("starts_on_to=" + query.get('starts_on_to'))
			}
			if (query.get('reference_no') !== null && query.get('reference_no') !== '') {
				searchItem["reference_no"] = query.get('reference_no')
				urlOptions.push("reference_no=" + query.get('reference_no'))
			}
			if (query.get('branch_id') !== null && query.get('branch_id') !== '') {
				axios({
					method: 'get',
					url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + query.get('branch_id'),
					headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
				}).then(resp => {
					searchItem["branch"] = resp.data
					urlOptions.push("bank_id=" + query.get('branch_id'))
				})
			}

			if (query.get('is_posted') !== null && query.get('is_posted') !== '') {
				searchItem["is_posted"] = query.get('is_posted')
				urlOptions.push("is_posted=" + query.get('is_posted'))
			}
			if (query.get('remarks') !== null && query.get('remarks') !== '') {
				searchItem["remarks"] = query.get('remarks')
				urlOptions.push("remarks=" + query.get('remarks'))
			}
			if (query.get('page') !== null && query.get('page') !== '') {
				urlOptions.push("page=" + query.get('page'))
			}
			this.setState({ currentPage: parseInt(query.get('page')) })

			if (urlOptions.length > 0) {
				urlOptions.map((urlOption, idx) => {
					if (idx > 0) {
						url = url + "&" + urlOption
					} else {
						url = url + urlOption
					}
				})
			}
			this.setState({ searchjournal: searchItem })
		}
		this.loadjournal(url,this.state.perPage)
	}

	loadjournal(url,perPage) {
		axios({
			method: 'get',
			url: url + "&resource_type=Journal&sort_by[date_of_journal]=desc&per_page="+perPage,
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ journals: resp.data.journals,totalPages: resp.data.meta.total_pages, currentPage: resp.data.meta.current_page, totalResults: resp.data.meta.total_count })
			this.setState({ load: true })
		}).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
		axios({
			method: 'get',
			url: process.env.REACT_APP_API_DOMAIN + '/v1/banks/search?per_page=1000',
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ banks: resp.data.banks })

		}).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
		axios({
			method: 'get',
			url: process.env.REACT_APP_API_DOMAIN + '/v1/payees/search?per_page=10000',
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ payees: resp.data.payees })
		}).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
		axios({
			method: 'get',
			url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ branches: resp.data })
		}).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
	}

	handleDeleteItem() {
		const DeleteItemId = this.state.journal.id
		axios({
			method: 'delete',
			url: process.env.REACT_APP_API_DOMAIN + '/v1/journals/' + DeleteItemId,
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(() => {
			this.setState({
				journals: this.state.journals.filter(item => item.id !== DeleteItemId)
			})
			this.setState({
				isOpen: true,
				message: 'Deleted Successfully',
				type: 'error'
			})
			this.setState({ openDeletePopup: false })
		}).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
	}

	handleClose() {
		this.setState({ openPopup: false })
		this.setState({ openDeletePopup: false })
		this.setState({ error_messages: '' })
	}

	handleCreateorUpdateItem(item, isAdd, model) {
		var textTitle = ''
		if (isAdd) {
			textTitle = 'Create ' + model
			this.setState({ redirectToAdd: true })
		} else {
			textTitle = 'Edit ' + model
		}
		this.setState({ openPopup: true })
		this.setState({ journal: item })
		this.setState({ title: textTitle })
	}

	handleInputChange(e) {
		this.setState({
			journal: {
				...this.state.journal,
				[e.target.name]: e.target.value
			}
		})
	}

	handleOpenDeletePopup(item, model) {
		this.setState({ model: model })
		this.setState({ journal: item })
		this.setState({ openDeletePopup: true })
	}

	handleDeleteClose() {
		this.setState({ openDeletePopup: false })
	}

	handleCloseAlert(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({
			isOpen: false
		})
	}

	handlePageChange(event, value) {
		var searchItem = {}
		var url = process.env.REACT_APP_API_DOMAIN + '/v1/journals/search?'
		const query = new URLSearchParams(this.props.location.search);
		var urlOptions = []
		var searchUrl = ''

		if (query.get('starts_on_from') !== null && query.get('starts_on_from') !== '') {
			searchItem["starts_on_from"] = query.get('starts_on_from')
			urlOptions.push("starts_on_from=" + query.get('starts_on_from'))
		}
		if (query.get('starts_on_to') !== null && query.get('starts_on_to') !== '') {
			searchItem["starts_on_to"] = query.get('starts_on_to')
			urlOptions.push("starts_on_to=" + query.get('starts_on_to'))
		}
		if (query.get('reference_no') !== null && query.get('reference_no') !== '') {
			searchItem["reference_no"] = query.get('reference_no')
			urlOptions.push("reference_no=" + query.get('reference_no'))
		}
		if (query.get('branch_id') !== null && query.get('branch_id') !== '') {
			axios({
				method: 'get',
				url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + query.get('branch_id'),
				headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
			}).then(resp => {
				searchItem["branch"] = resp.data
				urlOptions.push("bank_id=" + query.get('branch_id'))
			})
		}
		if (query.get('is_posted') !== null && query.get('is_posted') !== '') {
			searchItem["is_posted"] = query.get('is_posted')
			urlOptions.push("is_posted=" + query.get('is_posted'))
		}
		if (query.get('remarks') !== null && query.get('remarks') !== '') {
			searchItem["remarks"] = query.get('remarks')
			urlOptions.push("remarks=" + query.get('remarks'))
		}
		if (urlOptions.length > 0) {
			urlOptions.map((urlOption, idx) => {
				if (idx > 0) {
					url = url + "&" + urlOption
					searchUrl = searchUrl + "&" + urlOption
				} else {
					url = url + urlOption
					searchUrl = searchUrl + urlOption
				}
			})
		}
		this.setState({ searchjournal: searchItem })
		axios({
			method: 'get',
			url: url + 'resource_type=Journal&sort_by[date_of_journal]=desc&page=' + value,
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ journals: resp.data.journals, totalPages: resp.data.meta.total_pages })
		})
		this.setState({ currentPage: value })
		const { history } = this.props
		const params = new URLSearchParams()
		params.append("/search", searchUrl)
		history.push({ search: searchUrl })
	}

	handleSearchInputChange(e) {
		if (e.target.name === "starts_on_from") {
			var dateStart = new Date(e.target.value).toLocaleDateString('fr-Ca')
			var lastDay = new Date(new Date(dateStart).getFullYear(), new Date(dateStart).getMonth() + 1, 0).toLocaleDateString('fr-Ca')
			this.setState({
				searchjournal: {
					...this.state.searchjournal,
					[e.target.name]: e.target.value,
					starts_on_to: lastDay
				}
			})
		}
		else {
			this.setState({
				searchjournal: {
					...this.state.searchjournal,
					[e.target.name]: e.target.value
				}
			})
		}
	}


	handleAutocompleteSearch(event, values) {
		if (event.target.id.includes("branch")) {
			this.setState({
				searchjournal:
				{
					...this.state.searchjournal,
					branch: values
				}
			})
		}
	}

	handleKeyPress(e) {
		if (e.key === 'Enter') {
			this.handleSearch()
		}
	}

	handleSearch() {
		this.setState({ load: false })
		var url = process.env.REACT_APP_API_DOMAIN + "/v1/journals/search?"
		var searchUrl = ''
		var options = []

		if (this.state.searchjournal.starts_on_from !== undefined && this.state.searchjournal.starts_on_from !== '') {
			options.push("starts_on_from=" + this.state.searchjournal.starts_on_from)
		}
		if (this.state.searchjournal.starts_on_to !== undefined && this.state.searchjournal.starts_on_to !== '') {
			options.push("starts_on_to=" + this.state.searchjournal.starts_on_to)
		}
		if (this.state.searchjournal.reference_no !== undefined && this.state.searchjournal.reference_no !== '') {
			options.push("reference_no=" + this.state.searchjournal.reference_no)
		}

		if (this.state.searchjournal.branch !== undefined && this.state.searchjournal.branch !== null && this.state.searchjournal.branch.id !== '') {
			options.push("branch_id=" + this.state.searchjournal.branch.id)
		}
		if (this.state.searchjournal.is_posted !== undefined && this.state.searchjournal.is_posted !== '') {
			options.push("is_posted=" + this.state.searchjournal.is_posted)
		}


		options.map((option, idx) => {
			if (idx > 0) {
				searchUrl = searchUrl + "&" + option
			} else {
				searchUrl = searchUrl + option
			}
		})
		url = url + searchUrl
		axios({
			method: "get",
			url: url + "&resource_type=Journal&sort_by[date_of_journal]=desc",
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ journals: resp.data.journals })
			this.setState({ totalPages: resp.data.meta.total_pages })
			this.setState({ currentPage: resp.data.meta.current_page })
			this.setState({ load: true })
		}).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))

		const { history } = this.props
		const params = new URLSearchParams()
		params.append("/search", searchUrl)
		history.push({ search: searchUrl })
	}

	clearSearch() {
		const { history } = this.props
		history.push({ search: '' })
		this.loadjournal(process.env.REACT_APP_API_DOMAIN + '/v1/journals/search?', this.state.perPage)
		this.setState({ searchjournal: { starts_on_from: '', starts_on_to: '', branch: {}, is_posted: '', reference_no: '' } })

	}

	handlePerPageChange(e){
		var url = ''
		if (this.props.location.search === '') {
			url = process.env.REACT_APP_API_DOMAIN + '/v1/journals/search?'
		} else {
			var searchItem = {}
			url = process.env.REACT_APP_API_DOMAIN + '/v1/journals/search?'
			const query = new URLSearchParams(this.props.location.search);
			var urlOptions = []
			if (query.get('starts_on_from') !== null && query.get('starts_on_from') !== '') {
				searchItem["starts_on_from"] = query.get('starts_on_from')
				urlOptions.push("starts_on_from=" + query.get('starts_on_from'))
			}
			if (query.get('starts_on_to') !== null && query.get('starts_on_to') !== '') {
				searchItem["starts_on_to"] = query.get('starts_on_to')
				urlOptions.push("starts_on_to=" + query.get('starts_on_to'))
			}
			if (query.get('reference_no') !== null && query.get('reference_no') !== '') {
				searchItem["reference_no"] = query.get('reference_no')
				urlOptions.push("reference_no=*" + query.get('reference_no') + "*")
			}
			if (query.get('branch_id') !== null && query.get('branch_id') !== '') {
				axios({
					method: 'get',
					url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + query.get('branch_id'),
					headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
				}).then(resp => {
					searchItem["branch"] = resp.data
					urlOptions.push("bank_id=" + query.get('branch_id'))})
			}
			if (query.get('is_posted') !== null && query.get('is_posted') !== '') {
				searchItem["is_posted"] = query.get('is_posted')
				urlOptions.push("is_posted=" + query.get('is_posted'))
			}
			if (query.get('remarks') !== null && query.get('remarks') !== '') {
				searchItem["remarks"] = query.get('remarks')
				urlOptions.push("remarks=" + query.get('remarks'))
			}
			if (urlOptions.length > 0) {
				urlOptions.map((urlOption, idx) => {
					if (idx > 0) {url = url + "&" + urlOption} else {url = url + urlOption}})}
		this.setState({ searchjournal: searchItem })}
		this.setState({perPage: e.target.value})
		this.loadjournal(url,e.target.value)
	}


	render() {
		if (this.state.redirectToAdd) {
			return (<Redirect to={{
				pathname: '/journal/new'
			}} />)
		}
		return (
			<>
				{
					this.state.load ? (
						<div>

							<JournalSearchForm
								item={this.state.searchjournal}
								clearSearch={this.clearSearch}
								banks={this.state.banks}
								branches={this.state.branches}
								payees={this.state.payees}
								handleChange={this.handleSearchInputChange}
								handleAutocompleteSearch={this.handleAutocompleteSearch}
								handleKeyPress={this.handleKeyPress}
								search={this.handleSearch}
							/>

							<SimpleTable
								handleClick={this.handleCreateorUpdateItem}
								initialItem={this.state.initialjournal}
								items={this.state.journals}
								model={this.state.model}
								headers={this.state.branches.length > 1 ? this.state.tableHead : this.state.tableHeader}
								withShow={this.state.withShow}
								withEdit={this.state.withEdit}
								totalPages={this.state.totalPages}
								withPagination={this.state.withPagination}
								currentPage={this.state.currentPage}
								item={this.state.journal}
								handlePageChange={this.handlePageChange}
								icon={<CreditCardIcon fontSize="large" />}
								headerTitle="Journals"
								defaultNewRoutesPath="/journal/new"
								defaultModelRoute="/journals/"
								hasDeleteButton={false}
								clickableTableRow={this.state.clickableTableRow}
								withResultsLabel={true}
								totalResults={this.state.totalResults}
								perPage={this.state.perPage}
								handlePerPageChange={this.handlePerPageChange}
							/>

							<AlertMessage
								notify={this.state.notify}
								handleCloseAlert={this.handleCloseAlert}
								isOpen={this.state.isOpen}
								type={this.state.type}
								message={this.state.message}
							/>
						</div>

					) : (

						<ProgressBar model={this.state.model} />

					)}
			</>
		)
	}
}

export default withRouter(Journals)

Journals.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object
}
