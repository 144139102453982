import React from 'react'
import styles from './CwtTypes.module.css'

import PropTypes from 'prop-types'

import { TextField, Button } from '@material-ui/core'

class CwtTypesForm extends React.Component {
  render () {
    return (
      <div>
        <form>
          <TextField
            autoComplete="off"
            className={styles.textFields}
            variant="outlined"
            size="small"
            name="name"
            fullWidth="true"
            label="Name"
            onChange={this.props.onchange}
            value={this.props.item.name}
            id="expenseType-name"
          />
          <TextField
            autoComplete="off"
            className={styles.textFields}
            variant="outlined"
            size="small"
            name="description"
            fullWidth="true"
            label="Description"
            onChange={this.props.onchange}
            value={this.props.item.description}
            id="expenseType-description"
          />
           <TextField
            autoComplete="off"
            className={styles.textFields}
            variant="outlined"
            size="small"
            name="multiplier"
            fullWidth="true"
            label="Multiplier"
            onChange={this.props.onchange}
            value={this.props.item.multiplier}
            id="expenseType-multiplier"
          />
          
        </form>
        
        <div className={styles.actionButton}>
          <Button variant="outlined" disabled={!this.props.item.name} id="cwt-submit" color="primary" onClick={() => this.props.submit()} >Save</Button>
        </div>
      </div>
    )
  }
}
export default CwtTypesForm

CwtTypesForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func
}
