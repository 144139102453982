import React from 'react'
import { Snackbar} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from "prop-types";

class AlertMessage extends React.Component {
  render() {
    return (
      <Snackbar
        open={this.props.isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right'  }}
        onClose={this.props.handleCloseAlert}>
        <Alert
          severity={this.props.type}
          onClose={this.props.handleCloseAlert}>
          {this.props.message}
        </Alert>
      </Snackbar>
    )
  }
}
export default AlertMessage


AlertMessage.propTypes = {
  notify: PropTypes.object,
  children: PropTypes.object,
  type: PropTypes.string,
  message: PropTypes.string,
  isOpen: PropTypes.bool,
  handleCloseAlert: PropTypes.func,
  error: PropTypes.func
}
