// eslint-disable-next-line
import React from 'react'

import axios from 'axios'

import PropTypes from 'prop-types'
import ProgressBar from "../../ProgressBar/ProgressBar";
import { withRouter } from 'react-router-dom'
import BalanceSheetsSearchForm from './BalanceSheetsSearchForm'
import BalanceSheetsTable from './BalanceSheetsTable'
import ListAltIcon from '@material-ui/icons/ListAlt';
import PrintIcon from '@material-ui/icons/Print'
import ReactToPrint from "react-to-print";
import { IconButton } from '@material-ui/core';
export const Component = withRouter(() => {

})

class BalanceSheets extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            model: 'Balance Sheet',
            balanceSheets: [],
            searchParams: {is_posted: 'true'},
            title: '',
            load: false,
            branch: {},
            branches: [],
            income: {},
            forPrint: false,
            urlPrint: {},
            searchUrl: process.env.REACT_APP_API_DOMAIN + '/v1/accounts/balance_sheet'
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleAutoComplete = this.handleAutoComplete.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.changePrintLayout = this.changePrintLayout.bind(this)
    }

    componentDidMount() {
        var searchUrl = this.state.searchUrl + this.props.location.search
        var searchParams = {is_posted: 'true'}
        const params = new URLSearchParams(this.props.location.search)
        // This is to load all params based on URLSearchParams
        params.forEach(function(value, key) {
        if (key !== 'page' ) {
            searchParams[key] = value
        }
        })
        
        this.setState({ searchParams: searchParams })
        this.loadBalanceSheets(searchUrl, this.props.location.search ? true : false)

    }

    loadBalanceSheets(url,searchLoad) {
        if (searchLoad) {
            axios({
                method: 'get',
                url: url,
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ balanceSheets: resp.data.accounts })
                this.setState({urlPrint: resp.data.meta})
                axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
                    headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
                }).then(resp => {
                    this.setState({ branches: resp.data })
                    this.setState({ load: true })
                }).catch(error => console.log(error.response))
            }).catch((error) => {
            if(error.response)
            {this.setState({load: true, isOpen: true, message: error.response.status + " Unexpected Problem Occurred",type: 'error'})}
            })
        }else {
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ branches: resp.data,load: true })
            })
        }
    }

    handleSubmit() {
      this.setState({ load: true }) 
      const { history } = this.props
      var searchParams = this.getSearchParams().join("&")
      const params = new URLSearchParams()
      params.append("/search", searchParams)
      history.push({ search: searchParams })
      this.loadBalanceSheets([this.state.searchUrl, searchParams].join("?"), true, false)
    }

    getSearchParams() {
      var searchParams = []
      // This is to load all params based on searchParams state.
      Object.entries(this.state.searchParams).map(([key, value]) => {
        if (value != '' && value != undefined && value != null) {
          if(key === "branch_id") {
            searchParams.push([key, value.id].join("="))
          }else {
            searchParams.push([key, value].join("="))
          }
        }
      })
  
      return searchParams
    }

    handleInputChange(e) {
        this.setState({ searchParams: { ...this.state.searchParams, [e.target.name]: e.target.value } })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleSubmit()
        }
    }

    clearSearch() {
        const { history } = this.props
        history.push({ search: '' })
        this.setState({balanceSheets: [],urlPrint: {}})
        this.setState({ searchParams: { date_to_compare: '', as_of_date: '', is_posted: '', branch_id: {} } })
    }

    handleAutoComplete(event, values) {
        this.setState({
          searchParams: {  ...this.state.searchParams, branch_id: values  }
        })
    }
    changePrintLayout() {
        return new Promise((resolve) => {
            this.setState({ forPrint: !this.state.forPrint }, () => resolve());
        });     
    }

    render() {
        const printPdf = <ReactToPrint
            trigger={() => {
                return <IconButton color="primary"><PrintIcon /></IconButton>
            }}
            onBeforeGetContent={this.changePrintLayout}
            onAfterPrint={this.changePrintLayout}
            content={() => this.componentRef}
        />
        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            <BalanceSheetsSearchForm
                                item={this.state.searchParams}
                                branches={this.state.branches}
                                search={this.handleSubmit}
                                clearSearch={this.clearSearch}
                                handleChange={this.handleInputChange}
                                handleKeyPress={this.handleKeyPress}
                                handleAutoComplete={this.handleAutoComplete}

                            />
                            <BalanceSheetsTable
                                searchItem={this.state.searchParams}
                                items={this.state.balanceSheets}
                                model={this.state.model}
                                headers={this.state.tableHead}
                                icon={<ListAltIcon fontSize="large" />}
                                urlPrint={this.state.urlPrint}
                                forPrint={this.state.forPrint}
                                printPdf={printPdf}
                                ref={el => (this.componentRef = el)}
                            />

                        </div>
                    ) : (
                        <ProgressBar model={this.state.model} />
                    )}
            </>
        )
    }
}

export default withRouter(BalanceSheets)

BalanceSheets.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
}
