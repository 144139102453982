import React from 'react'


import PropTypes from 'prop-types'

import {
  Typography, IconButton, ButtonGroup, Tooltip
} from '@material-ui/core'

import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import CallIcon from '@material-ui/icons/Call'
import styles from '../Clients.module.css'

class ContactNumbers extends React.Component {

  render() {
    return (
      <div className={styles.Parent}>
        <div className="contact-body ">
          <Typography className={styles.headerBody}>
            <div className={styles.parentTitle}><CallIcon fontSize="small"/><b>Contact Numbers :</b></div>
            <Tooltip title={`Add Contact Number`} arrow>
            <IconButton className="padding-none" color="inherit" aria-label="menu"
                        onClick={() => this.props.handleClick('Add Contact Number', 'contact number', this.props.initialContactNumber)}
                        name="add-contactNumber"
            >
              <AddCircleOutlinedIcon fontSize="small"/>
            </IconButton>
            </Tooltip>
          </Typography>
          {this.props.contactNumbers.length > 0 ? (
            this.props.contactNumbers
              .map((contactNumber) => (
                <div key={contactNumber.uid} className={styles.contactListBody}>
                  <div className={styles.contactLists}>
                    <div className={styles.contactListLabel}>
                      {contactNumber.content}

                    </div>
                    &nbsp; - &nbsp;
                    <div className={styles.contactListLabel}>
                      <i>{contactNumber.category_name.replace('_', ' ')}</i>
                    </div>
                  </div>
                  <div className={styles.actionButtonHoverable}>
                    <ButtonGroup aria-label="outlined primary button group">
                      <Tooltip title={`Edit`} arrow>
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={() => this.props.handleClick('Edit Contact Number', 'Contact Number', contactNumber)}
                        name={`contactNumber-edit`}
                      >
                        <EditIcon/>
                      </IconButton>
                      </Tooltip>
                      <Tooltip title={`Delete`} arrow>
                      <IconButton
                        variant="contained"
                        color="secondary"
                        onClick={() => this.props.handleOpenDelete('contact number', contactNumber)}
                        name={`contactNumber-delete`}
                      >
                        <DeleteForeverIcon/>
                      </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </div>
                </div>
              ))
          ) : (
            <div>
              <Typography className={styles.contactLists}>No Saved Contact Numbers</Typography>
            </div>
          )}
        </div>
      </div>

    )
  }
}

export default ContactNumbers

ContactNumbers.propTypes = {
  contactNumbers: PropTypes.array,
  initialContactNumber: PropTypes.object,
  handleClick: PropTypes.func,
  isCompany: PropTypes.bool,
  handleOpenDelete: PropTypes.func
}
