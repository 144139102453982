import React from 'react'
import styles from './GeneralLedgers.module.css'

import PropTypes from 'prop-types'

import {
    TextField,
    Button,
    MenuItem,
    Grid
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

class GeneralLedgersSearchForm extends React.Component {
    render() {
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <TextField
                            type="date"
                            autoComplete="off"
                            className={styles.textFields}
                            variant="outlined"
                            size="small"
                            name="date_of_journal_start"
                            fullWidth={true}
                            label="Start Date"
                            onChange={this.props.handleChange}
                            onKeyPress={this.props.handleKeyPress}
                            value={this.props.item.date_of_journal_start}
                            id="generalLedger-date_of_journal_start"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            type="date"
                            autoComplete="off"
                            className={styles.textFields}
                            variant="outlined"
                            size="small"
                            name="date_of_journal_end"
                            fullWidth={true}
                            label="End Date"
                            onChange={this.props.handleChange}
                            onKeyPress={this.props.handleKeyPress}
                            value={this.props.item.date_of_journal_end}
                            id="generalLedger-date_of_journal_end"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            value={this.props.item.branch}
                            id="branch"
                            name="branch"
                            fullWidth
                            size="small"
                            className={styles.autoCompleteField}
                            options={(this.props.branches).sort(function (a, b) {
                                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                return 0;
                            })}
                            getOptionLabel={(option) => option.name || ''}
                            getOptionSelected={(option, value) => option.name === value.name}
                            onChange={this.props.handleAutoComplete}
                            renderInput={(params) => (
                                <TextField {...params} name="branch" variant="outlined" label="Branch Name" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            select
                            className={styles.textFields}
                            variant="outlined"
                            size="small"
                            name="is_posted"
                            fullWidth={true}
                            label="Status"
                            onChange={this.props.handleChange}
                            value={this.props.item.is_posted || ''}
                            id="generalLedger-classification"
                        >
                            <MenuItem value =""><div>&nbsp;</div></MenuItem>
                            <MenuItem value="true">Posted</MenuItem>
                            <MenuItem value="false">Not Posted</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            value={this.props.item.accounts}
                            multiple
                            id="account"
                            name="accounts"
                            fullWidth
                            size="small"
                            options={(this.props.accounts)}
                            getOptionLabel={(option) => option.name || ''}
                            getOptionSelected={(option, value) => option.name === value.name }
                            onChange={this.props.handleAutoComplete}
                            renderInput={(params) => (
                                <TextField {...params} name="accounts" variant="outlined" label="Accounts" />
                            )}
                        />
                    </Grid>
                </Grid>
                <div className={styles.actionButton}>
                    <Button variant="outlined" id="generalLedger-search" disabled={!this.props.item.date_of_journal_end || !this.props.item.date_of_journal_start} color="primary" onClick={() => this.props.search()} >Search</Button>
                    <Button variant="outlined" id="generalLedger-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
                </div>

            </div>
        )
    }
}
export default GeneralLedgersSearchForm

GeneralLedgersSearchForm.propTypes = {
    handleChange: PropTypes.func,
    item: PropTypes.object,
    branches: PropTypes.array,
    search: PropTypes.func,
    clearSearch: PropTypes.func,
    handleKeyPress: PropTypes.func,
    handleAutoComplete: PropTypes.func,
    accounts: PropTypes.array
}
