import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios"
import moment from 'moment';
import 'moment-timezone';
import ReceiptForm from './ReceiptForm'
import ReceiptDetailsForm from './ReceiptDetailsForm'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import { Redirect } from 'react-router'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import * as CommonFunction from "../../shared/Custom/CommonFunctions"

class ReceiptNew extends React.Component {
  constructor (props) {
    super(props)
    const current_user = JSON.parse(localStorage.getItem("current_user"))
    this.state = {
      model: 'New Receipt',
      error_messages: '',
      popupHeader: '',
      popupWidth: 'sm',
      updateReceiptDetailsIndex: 0,
      clientsAutoCompleteLoading: true,
      baanksAutoCompleteLoading: true,
      branchesAutoCompleteLoading: true,
      employeesAutoCompleteLoading: true,
      invoicesAutoCompleteLoading: true,
      openPopup: false,
      isAddReceiptDetail: false,
      isCheckPayment: false,
      load: false,
      banks: [],
      branches: [],
      clients: [],
      paymentTerms: [],
      receiptDetails: [],
      invoices: [],
      products: [],
      current_user: current_user,
      initialReceipt: { date_of_receipt: moment().format("yyyy-MM-DD") },
      initialReceiptDetail: { balance: 0 },
      selectedClient: {},
      savedReceipt: {},
      defaultInvoice: {},
      errorMessages: {}
    }

    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleAutocompleteOnInputChange = this.handleAutocompleteOnInputChange.bind(this)
    this.handleCloseSimplePopup = this.handleCloseSimplePopup.bind(this)
    this.handleReceiptFormInputChange = this.handleReceiptFormInputChange.bind(this)
    this.handleReceiptDetailsFormInputChange = this.handleReceiptDetailsFormInputChange.bind(this)
    this.handleOpenSimplePopup = this.handleOpenSimplePopup.bind(this)
    this.handleDeleteReceiptDetails = this.handleDeleteReceiptDetails.bind(this)
    this.handleSaveReceiptDetails = this.handleSaveReceiptDetails.bind(this)
    this.handleSaveReceipt = this.handleSaveReceipt.bind(this)
    this.handleInputChangeReceiptDetailAmount = this.handleInputChangeReceiptDetailAmount.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
  }

  componentDidMount() {
    if (Object.entries(this.props.match.params).length > 0) {
      if (this.props.match.params.client_id) {
        this.loadTrueClients(process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.props.match.params.client_id)
      } else if (this.props.match.params.invoice_id) {
        this.loadThruInvoices(process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.props.match.params.invoice_id)
      }
    } else {
      console.log(this.state.current_user)
      if (![null, undefined].includes(this.state.current_user.branch_id)) {
        this.getLastReceiptNumberByBranch(this.state.current_user.branch_id)
      }
      this.loadClientsAutoCompleteOptions()
      this.loadBanksAutoCompleteOptions()
      this.loadBranchesAutoCompleteOptions()
      this.loadPaymentTermsAutoCompleteOptions()
      this.setState({ load: true })
    }
  }

  loadThruInvoices(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({
        initialReceipt: {
          branch: resp.data.branch,
          date_of_receipt: new moment().format("YYYY-MM-DD"),
          client: resp.data.client,
        }
      })
      this.getLastReceiptNumberByBranch(resp.data.branch.id)
      this.loadBanksAutoCompleteOptions()
      this.loadBranchesAutoCompleteOptions()
      this.loadInvoicesAutoCompleteOptions(resp.data.client_id)
      this.loadPaymentTermsAutoCompleteOptions()
      this.setState({ selectedClient: resp.data.client, load: true, defaultInvoice: resp.data })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadTrueClients(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({initialReceipt: {id: resp.data.id,
          date_of_receipt: new moment().format("YYYY-MM-DD"),
          client: resp.data,
        }
      })
      this.loadBanksAutoCompleteOptions()
      this.loadBranchesAutoCompleteOptions()
      this.loadInvoicesAutoCompleteOptions(resp.data.id)
      this.loadPaymentTermsAutoCompleteOptions()
      this.setState({ selectedClient: resp.data, load: true })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadClientsAutoCompleteOptions(searchText = '') {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/search',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')},
      params: { name: '*' + searchText + '*' }
    }).then(resp => {
      this.setState({ clients: resp.data.clients, clientsAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadInvoicesAutoCompleteOptions(client_id, searchText = '') {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/search',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')},
      params: { client_id: client_id, reference_no: searchText, status: "for_approval,for_posting" }
    }).then(resp => {
      this.setState({ invoices: resp.data.invoices.filter(invoice => !invoice.is_cash_sales && invoice.remaining_balance > 0), invoicesAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadBanksAutoCompleteOptions(searchText = '') {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/banks/search',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')},
      params: { name: searchText }
    }).then(resp => {
      this.setState({ banks: resp.data.banks, banksAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadPaymentTermsAutoCompleteOptions() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/payment_terms',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({paymentTerms: resp.data})
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadBranchesAutoCompleteOptions() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ branches: resp.data, initialReceipt: { ...this.state.initialReceipt, } })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  getLastReceiptNumberByBranch(branch_id) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/receipts/search',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')},
      params: { branch_id: branch_id }
    }).then(resp => {
      var receipts = resp.data.receipts.sort(function (a, b) {
        if (parseInt(a.reference_no) > parseInt(b.reference_no)) return -1;
        if (parseInt(a.reference_no) < parseInt(b.reference_no)) return 1;
        return 0;
      })
      if (receipts.length > 0) {
        this.setState({ initialReceipt: { ...this.state.initialReceipt, reference_no: this.padFix(parseInt(receipts[0].reference_no) + 1)[0] } })
      } else {
        this.setState({ initialReceipt: { ...this.state.initialReceipt, reference_no: this.padFix("1")[0] } })
      }
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleAutocomplete(event, value) {
    if (event.target.id.includes("branch")) {
      this.setState({ branchesAutoCompleteLoading: false, initialReceipt: { ...this.state.initialReceipt, branch: value } })
      value !== undefined && value !== null ? (this.getLastReceiptNumberByBranch(value.id)) : (this.setState({ ...this.state.initialReceipt, reference_no: this.padFix("1")[0] }))
    } else if (event.target.id.includes("client")) {
      this.setState({ selectedClient: value || {}, initialReceipt: { ...this.state.initialReceipt, client: value } })
      if (value !== undefined && value !== null) {
        this.loadInvoicesAutoCompleteOptions(value.id)
      } else {
        this.loadClientsAutoCompleteOptions()
      }
    } else if (event.target.id.includes("invoice")) {
      if (![undefined, null].includes(value)) {
        this.setState({ initialReceiptDetail: { ...this.state.initialReceiptDetail, invoice: value, payment_term: value.payment_term, amount: 0, balance: parseFloat(value.remaining_balance), canSaveDetails: value.remaining_balance > 0 } })
      } else {
        this.setState({ initialReceiptDetail: { ...this.state.initialReceiptDetail, invoice: {}, payment_term: {}, amount: 0, balance: 0 } })
      }
    } else if (event.target.id.includes("bank")) {
      this.setState({ initialReceiptDetail: { ...this.state.initialReceiptDetail, bank: value || {} } })
    } else if (event.target.id.includes("payment_term")) {
      if (![null, undefined].includes(value) && value.name === "Check") {
        this.setState({ initialReceiptDetail: { ...this.state.initialReceiptDetail, payment_term: value, date_of_check: moment().format("YYYY-MM-DD"), pdc: false }, isCheckPayment: true })
      } else {
        this.setState({ initialReceiptDetail: { ...this.state.initialReceiptDetail, payment_term: value || {} }, isCheckPayment: false })

      }
    }
  }

  handleAutocompleteOnInputChange(event, value, reason) {
    if (reason === 'input') {
      if (event.target.id.includes("client")) {
        this.setState({ clientsAutoCompleteLoading: true })
        this.loadClientsAutoCompleteOptions(event.target.value || '')
      } else if(event.target.id.includes("invoice")) {
        this.setState({ invoicesAutoCompleteLoading: true })
        this.loadInvoicesAutoCompleteOptions(this.state.initialReceipt.client_id, event.target.value || '')
      }
    }
  }

  handleReceiptFormInputChange(event) {
    this.setState({ initialReceipt: { ...this.state.initialReceipt, [event.target.id]: event.target.value } })
  }

  handleReceiptDetailsFormInputChange(event) {
    this.setState({ initialReceiptDetail: { ...this.state.initialReceiptDetail, [event.target.name]: event.target.value } })
  }

  handleCloseSimplePopup () {
    this.setState({ openPopup: false, openDeletePopup: false, error_messages: '' })
  }

  handleSwitch(event) {
    this.setState({ initialReceiptDetail: { ...this.state.initialReceiptDetail, [event.target.id]: event.target.checked } })
  }

  handleOpenSimplePopup(item, isAdd, targetIndex = 0) {
    var popupHeader = ''
    isAdd ? (popupHeader = 'Add Collection Receipt Detail') : (popupHeader = 'Edit Collection Receipt Detail')

    if (Object.entries(item).length > 0) {
      item = { ...item, amount: parseFloat(item.amount), balance: parseFloat(item.invoice.remaining_balance) - parseFloat(item.amount) }
    } else if (Object.entries(this.state.defaultInvoice).length > 0) {
      item = { ...item, invoice: this.state.defaultInvoice, payment_term: this.state.defaultInvoice.payment_term, balance: this.state.defaultInvoice.remaining_balance }
    }

    this.setState({ isCheckPayment: item !== undefined && item !== null && Object.entries(item).length > 0 && item.payment_term.name === "Check" })
    this.setState({ openPopup: true, initialReceiptDetail: item, popupHeader: popupHeader, isAddReceiptDetail: isAdd, updateReceiptDetailsIndex: parseInt(targetIndex) })
  }

  handleSaveReceiptDetails(receiptDetail) {
    this.handleCloseSimplePopup()
    receiptDetail.isCheckPayment = this.state.isCheckPayment
    this.state.isAddReceiptDetail ? (this.state.receiptDetails.push(receiptDetail)) : (this.state.receiptDetails.splice(this.state.updatereceiptDetailsIndex, 1, receiptDetail))
  }

  handleDeleteReceiptDetails(index) {
    const receipt_details = this.state.receiptDetails
    receipt_details.splice(index, 1)
    this.setState({ receiptDetails: receipt_details })
  }

  handleSaveReceipt() {
    var receiptDetails = []

    this.state.receiptDetails.map((item) => {
      var receiptDetail = { invoice_id: Object.entries(item.invoice).length > 0 ? (item.invoice.id) : (''), bank_id: item.bank.id,
                            payment_term_id: item.payment_term.id, amount: item.amount, remarks: item.remarks }
      if (item.isCheckPayment) {
        receiptDetail.check_no = item.check_no
        receiptDetail.bank_of_check = item.bank_of_check
        receiptDetail.type_of_check = item.type_of_check
        receiptDetail.date_of_check = item.date_of_check
        receiptDetail.pdc = item.pdc
      }

      receiptDetails.push(receiptDetail)
    })

    var receipt = { client_id: this.state.initialReceipt.client.id, branch_id: this.state.initialReceipt.branch.id,
                     reference_no: this.state.initialReceipt.reference_no, date_of_receipt: this.state.initialReceipt.date_of_receipt,
                     receipt_contents_attributes: receiptDetails }

    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/receipts',
      data: (receipt),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ isOpen: true, message: 'Submitted Successfully', type: 'success', savedReceipt: resp.data, redirect: true })
    }).catch(error => {
      if (error.response.status === 422) {
        var errors = {}
        Object.keys(error.response.data.errors[0].detail.errors).map((field) => {
          errors[field] = error.response.data.errors[0].detail.errors[field][0]
        })
        this.setState({ errorMessages: errors })
      } else {
        this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error', openPopup: false})
      }
    })
  }

  handleInputChangeReceiptDetailAmount(e) {
    if (![null, undefined, ''].includes(e.floatValue)) {
      this.setState({
        initialReceiptDetail: {
          ...this.state.initialReceiptDetail,
          amount: e.floatValue,
          balance: parseFloat(this.state.initialReceiptDetail.invoice.remaining_balance) - e.floatValue
        }
      })
    } else {
      this.setState({
        initialReceiptDetail: {
          ...this.state.initialReceiptDetail,
          amount: 0,
          balance: parseFloat(this.state.initialReceiptDetail.invoice.remaining_balance)
        }
      })
    }
  }

  padFix(n) {
    return ('00000000'+n.toString()).match(/\S{8}$/);
  }

  render () {
    const {redirect} = this.state
    if (redirect)
      return (
        <Redirect to={{
          pathname: '/receipts/' + this.state.savedReceipt.id
        }}/>)
    return (
      <>
        {
          this.state.load ? (
            <div>
              <ReceiptForm
                branches={this.state.branches}
                clients={this.state.clients}
                invoiceThru={true}
                clientsAutoCompleteLoading={this.state.clientsAutoCompleteLoading}
                branchesAutoCompleteLoading={this.state.branchesAutoCompleteLoading}
                selectedClient={this.state.selectedClient}
                initialReceipt={this.state.initialReceipt}
                receiptDetails={this.state.receiptDetails}
                initialReceipDetail={this.state.initialReceipDetail}
                handleAutocomplete={this.handleAutocomplete}
                handleAutocompleteOnInputChange={this.handleAutocompleteOnInputChange}
                handleReceiptFormInputChange={this.handleReceiptFormInputChange}
                handleOpenSimplePopup={this.handleOpenSimplePopup}
                handleDeleteReceiptDetails={this.handleDeleteReceiptDetails}
                handleSaveReceipt={this.handleSaveReceipt}
                error={this.state.errorMessages}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.popupHeader}
                handleClose={this.handleCloseSimplePopup}
                maxWidth={true}
              >
                <ReceiptDetailsForm
                  invoicesAutoCompleteLoading={ this.state.invoicesAutoCompleteLoading }
                  banksAutoCompleteLoading={ this.state.banksAutoCompleteLoading }
                  isCheckPayment={ this.state.isCheckPayment }
                  invoices={ this.state.invoices }
                  banks={ this.state.banks }
                  paymentTerms={ this.state.paymentTerms }
                  initialReceiptDetail={ this.state.initialReceiptDetail }
                  handleAutocomplete={ this.handleAutocomplete }
                  handleAutocompleteOnInputChange={ this.handleAutocompleteOnInputChange }
                  handleReceiptDetailsFormInputChange={ this.handleReceiptDetailsFormInputChange }
                  handleSaveReceiptDetails={ this.handleSaveReceiptDetails }
                  handleSwitch={ this.handleSwitch }
                  handleInputChangeReceiptDetailAmount={ this.handleInputChangeReceiptDetailAmount }/>
              </SimplePopUp>
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default ReceiptNew

ReceiptNew.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      client_id: PropTypes.string,
      invoice_id: PropTypes.string
    })
  })
}
