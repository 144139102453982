import React from 'react'
import styles from './ARReports.module.css'

import PropTypes from 'prop-types'

import {
	TextField,
	Button,
  MenuItem,
	Grid
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

class ARReportsSearchForm extends React.Component {
	render() {
		return (
			<div>
				<Grid container spacing={1}>
					<Grid item xs={3}>
						<TextField
							autoComplete="off"
							className={styles.textFields}
              type="date"
							variant="outlined"
							size="small"
							name="as_of_date"
							fullWidth={true}
							label="As of Date"
							onChange={this.props.handleChange}
							onKeyPress={this.props.handleKeyPress}
							value={this.props.item.as_of_date}
              InputLabelProps={{
                shrink: true
              }}
						/>
					</Grid>
					<Grid item xs={3}>
            <TextField
              autoComplete="off"
              select
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="tax_exempted"
              fullWidth={true}
              label="Tax Exempted"
              onChange={this.props.handleChange}
              value={this.props.item.tax_exempted}
            >
              <MenuItem value="null">All</MenuItem>
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </TextField>
					</Grid>
					<Grid item xs={3}>
					<Autocomplete
                            value={this.props.item.branch_id}
                            id="branch"
                            name="branch"
                            fullWidth
                            className={styles.autoCompleteField}
                            size="small"
                            options={(this.props.branches).sort(function (a, b) {
                                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                return 0;
                            })}
                            getOptionLabel={(option) => option.name || ''}
                            getOptionSelected={(option, value) => option.name === value.name}
                            onChange={this.props.handleARRSearch}
                            renderInput={(params) => (
                                <TextField {...params} name="branch" variant="outlined" label="Branch Name" />
                            )}
                        />
					</Grid>
					<Grid item xs={3}>
						<TextField
							autoComplete="off"
							select
							className={styles.textFields}
							variant="outlined"
							size="small"
							name="is_posted"
							fullWidth={true}
							label="Status"
							onChange={this.props.handleChange}
							value={this.props.item.is_posted || ''}
						>
							<MenuItem value=""> </MenuItem>
							<MenuItem value="false">Not Posted</MenuItem>
							<MenuItem value="true">Posted</MenuItem>
						</TextField>
					</Grid>
				</Grid>
				<div className={styles.actionButton}>
					<Button variant="outlined"  id="ar-search" color="primary" onClick={() => this.props.search()} >Search</Button>
					<Button variant="outlined" id="ar-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
				</div>

			</div>
		)
	}
}
export default ARReportsSearchForm

ARReportsSearchForm.propTypes = {
	handleChange: PropTypes.func,
	item: PropTypes.object,
	search: PropTypes.func,
	handleARRSearch: PropTypes.func,
	clearSearch: PropTypes.func,
  branches: PropTypes.array,
	handleKeyPress: PropTypes.func
}
