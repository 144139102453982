// eslint-disable-next-line


import React from 'react'

import SimpleTable from '../../SimpleTable/SimpleTable'
import SimplePopUp from '../../SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../SimpleDeletePopUp/SimpleDeletePopUp'
import BranchesForm from './BranchesForm'
import SettingsInputCompositeOutlinedIcon from '@material-ui/icons/SettingsInputCompositeOutlined';

import axios from 'axios'
import AlertMessage from "../../Notify/AlertMessage";
import ProgressBar from "../../ProgressBar/ProgressBar";
import * as CommonFunction from "../../Custom/CommonFunctions"

class Branches extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Branche',
      branches: [],
      openPopup: false,
      openDeletePopup: false,
      branch: {},
      intialBranch: {id: '', name: '', sort_name: '', address: '', telephone: '', fax_no: '', email: '', is_active: true},
      title: '',
      load: false,
      tableHead: ['Name', 'address', 'telephone', 'fax_no', 'email'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      isEdit: false,
      allowedParams: []
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
  }

  componentDidMount() {
    this.loadbranches(process.env.REACT_APP_API_DOMAIN + '/v1/branches')
  }

  loadbranches(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({branches: resp.data})
      this.setState({load: true})
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleSubmit() {
    const item = this.state.branch
    item["name"] = this.state.branch.name.charAt(0).toUpperCase() + this.state.branch.name.slice(1)
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/branches/'
    } else {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {

      const newData = resp.data
      this.setState({
        branches: this.state.branches.filter(branch => branch.id !== item.id)
      })
      this.setState({branches: [...this.state.branches, newData]})
      this.setState({openPopup: false})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.branch.id
    axios({
      method: 'delete',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        branches: this.state.branches.filter(item => item.id !== DeleteItemId)
      })
      this.setState({openDeletePopup: false})
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleClose() {
    this.setState({openPopup: false})
    this.setState({openDeletePopup: false})
    this.setState({error_messages: ''})
  }

  handleCreateorUpdateItem(item, isAdd) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + "Branch"
    } else {
      textTitle = 'Edit ' + "Branch"
      this.setState({isEdit: true, allowedParams: item.allowed_params})
    }
    this.setState({openPopup: true})
    this.setState({branch: item})
    this.setState({title: textTitle})
  }

  handleInputChange(e) {
    this.setState({ branch: { ...this.state.branch, [e.target.name]: e.target.value }})
  }

  handleOpenDeletePopup(item, model) {
    this.setState({model: model})
    this.setState({branch: item})
    this.setState({openDeletePopup: true})
  }

  handleDeleteClose() {
    this.setState({openDeletePopup: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  handleSwitch(event) {
    const value = event.target.checked
    this.setState({
      branch: {
        ...this.state.branch,
        [event.target.name]: value
      }
    })
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.intialBranch}
                items={this.state.branches}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                icon={<SettingsInputCompositeOutlinedIcon fontSize="large"/>}
                headerTitle="Branches"
                createUsingSimplePopup={true}
                hasDeleteButton={true}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <BranchesForm
                  error={this.state.error_messages}
                  item={this.state.branch}
                  submit={this.handleSubmit}
                  handleSwitch={this.handleSwitch}
                  onchange={this.handleInputChange}
                  isEdit={this.state.isEdit}
                  allowedParams={this.state.allowedParams}
                  />
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.branch}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model="Branch"
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default Branches
