import React from 'react'

import PropTypes from 'prop-types'

import {
  Typography, IconButton, ButtonGroup, Tooltip
} from '@material-ui/core'

import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import styles from '../Clients.module.css'

class EmailAddresses extends React.Component {

  render() {

    return (
      <div className={styles.Parent}>
        <div className="email-body">
          <Typography className={styles.headerBody}>
            <div className={styles.parentTitle}><MailOutlineIcon fontSize="small"/><b>Email Address :</b></div>
            <Tooltip title={`Add Email`} arrow>
            <IconButton color="inherit" aria-label="menu"
                        onClick={() => this.props.handleClick('Add Email Address', 'Email Address', this.props.initialEmailAddress)}
                        name="create-emailAddress"
            >
              <AddCircleOutlinedIcon fontSize="small"/>
            </IconButton>
            </Tooltip>
          </Typography>

          {this.props.emailAddresses.length > 0 ? (
            this.props.emailAddresses
              .map((emailAddress) => (
                <div key={emailAddress.uid} className={styles.contactListBody}>
                  <div className={styles.contactLists}>
                    <div className={styles.emailListValue}>
                      {emailAddress.content}

                    </div>
                    &nbsp; - &nbsp;
                    <div className={styles.emailListLabel}>
                      <i>{emailAddress.category_name}</i>
                    </div>
                  </div>
                  <div className={styles.actionButtonHoverable}>
                    <ButtonGroup aria-label="outlined primary button group">
                      <Tooltip title={`Edit`} arrow>
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={() => this.props.handleClick('Edit Email Address', 'Email Address', emailAddress)}
                        name={`emailAddress-edit`}
                      >
                        <EditIcon/>
                      </IconButton>
                      </Tooltip>
                      <Tooltip title={`Delete`} arrow>
                      <IconButton
                        variant="contained"
                        color="secondary"
                        onClick={() => this.props.handleOpenDelete('email address', emailAddress)}
                        name={`emailAddress-delete`}
                      >
                        <DeleteForeverIcon/>
                      </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </div>
                </div>
              ))
          ) : (
            <div>
              <Typography className={styles.contactLists}>No Saved Email Address</Typography>
            </div>
          )}
        </div>
      </div>

    )
  }
}

export default EmailAddresses
EmailAddresses.propTypes = {
  emailAddresses: PropTypes.array,
  initialEmailAddress: PropTypes.object,
  handleClick: PropTypes.func,
  isCompany: PropTypes.bool,
  handleOpenDelete: PropTypes.func

}
