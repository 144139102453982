import React from 'react'
import PropTypes from 'prop-types'
import {TextField, IconButton, InputLabel, option} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import styles from "../Clients.module.css"
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';

class EmailAddress extends React.Component {

  render() {
    const contentId = `client_email_address_${this.props.idx}`
    const categoryId = `client_email_category_${this.props.idx}`
    const deleteButton = `delete_client_email_address_${this.props.idx}`
    return (
      <form className={styles.formBody} key={`content-${this.props.idx}`} name="client_email_address_contents">
        <br/>
        <>
          <TextField
            autoComplete="off"
            className={styles.formTextfield}
            fullWidth
            variant="outlined" required
            name={contentId}
            id={contentId}
            size="small"
            data-idx={this.props.idx}
            value={this.props.emailAddresses[this.props.idx].content}
            onChange={this.props.handleEmailAddressChange}
            label="Email Address"
            inputProps={{'data-id': this.props.idx, 'data-field-type': 'content'}}
          />
          <FormControl fullWidth className={styles.formTextfield} size="small" variant="outlined">
            <InputLabel>Category</InputLabel>
            <Select
              native
              id={categoryId}
              fullWidth
              label="category"
              variant="outlined"
              size="small"
              data-idx={this.props.idx}
              name={categoryId}
              value={this.props.emailAddresses[this.props.idx].category || 0}
              onChange={this.props.handleEmailAddressChange}
              inputProps={{
                name: categoryId,
                id: 'outlined-age-native-simple',
                'data-id': this.props.idx,
                'data-field-type': 'category'
              }}
              InputLabelProps={{
                shrink: true
              }}
            >
              <option value="" disabled selected>Please Select</option>
              <option value={0}>Personal</option>
              <option value={1}>Primary</option>
              <option value={2}>Secondary</option>
              <option value={3}>Others</option>
            </Select>
          </FormControl>
          <IconButton
            color="secondary"
            name={deleteButton}
            onClick={() => this.props.removeEmail(this.props.idx)}>
            <DeleteForeverIcon/>
          </IconButton>

        </>

      </form>
    )
  }
}

export default EmailAddress

EmailAddress.propTypes = {
  idx: PropTypes.number,
  emailAddresses: PropTypes.array,
  handleEmailAddressChange: PropTypes.func,
  removeEmail: PropTypes.func

}
