import React from 'react'

import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import styles from './Invoices.module.css'

class PaymentTermsForm extends React.Component {
  render() {
    return (
      <>
        <TextField
          variant="outlined"
          autoComplete="off"
          required
          className={styles.textFields}
          size="small"
          fullWidth
          label="Name"
          name="name"
          value={this.props.item.name}
          onChange={this.props.handleChange}
        />
        <TextField
          variant="outlined"
          autoComplete="off"
          size="small"
          fullWidth
          className={styles.textFields}
          label="Description"
          name="description"
          value={this.props.item.description}
          onChange={this.props.handleChange}
        />

        <div className={styles.actionButton}>
          <Button
            variant="outlined"
            disabled={!this.props.item.name}
            onClick={this.props.addPaymentTerms}
            color="primary">Save</Button>
        </div>
      </>
    )
  }
}

export default PaymentTermsForm

PaymentTermsForm.propTypes = {
  item: PropTypes.object,
  handleChange: PropTypes.func,
  addPaymentTerms: PropTypes.func
}
