import React from 'react'
import styles from './Departments.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button, Grid
} from '@material-ui/core'

class DepartmentsForm extends React.Component {
  render() {
    return (
      <div>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="name"
                fullWidth="true"
                label="Name"
                onChange={this.props.onchange}
                value={this.props.item.name}
                id="name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="short_name"
                fullWidth="true"
                label="Short Name"
                onChange={this.props.onchange}
                value={this.props.item.short_name}
                id="short_name"
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="description"
                fullWidth="true"
                label="Description"
                onChange={this.props.onchange}
                value={this.props.item.description}
                id="description"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                type="number"
                size="small"
                name="rank"
                fullWidth="true"
                label="Rank"
                onChange={this.props.onchange}
                value={this.props.item.rank}
                id="rank"
              />
            </Grid>

          </Grid>
        </form>

        <div className={styles.actionButton}>
          <Button variant="outlined" disabled={!this.props.item.name} id="expenseType-submit" color="primary"
                  onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default DepartmentsForm

DepartmentsForm.propTypes = {
  onchange: PropTypes.func,
  handleSwitch: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func
}
