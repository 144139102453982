import React from 'react'

import PropTypes from 'prop-types'

import { Button, TextField, Table, TableHead, TableCell, TableRow, IconButton, TableBody, Tooltip, TableContainer } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Autocomplete from '@material-ui/lab/Autocomplete'
import styles from './Delivery.module.css'
import tableStyles from './../../shared/SimpleTable/SimpleTable.module.css'

class DeliveryForm extends React.Component {
  render() {
    return(
      <>
        <form>
          <div className={styles.groupTextfield}>
            {this.props.initialDelivery.id === null || this.props.initialDelivery.id === undefined ?
                (
                  <div className={styles.clientAutoCompleteField}>
                    <Autocomplete
                      name="client_id"
                      id="client_id"
                      key="client"
                      size="small"
                      loading={this.props.clientsAutoCompleteLoading}
                      options={(this.props.clients).sort(function (a, b) {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                        return 0;
                      })}
                      getOptionLabel={(option) => option.name || ''}
                      getOptionSelected={(option, value) => option.name === value.name}
                      onChange={this.props.handleAutocomplete}
                      onInputChange={(event, value, reason) => this.props.handleAutocompleteOnInputChange(event, value, reason)}
                      fullWidth={true}
                      value={this.props.initialDelivery.client}
                      closeIcon={<CloseIcon fontSize="small" id="clear_client_autocomplete" />}
                      renderInput={(params) => <TextField {...params} label="Client Name" variant="outlined" required />}
                    />
                  </div>
                ) : (
                  <div className={styles.addressBody}>
                    <div>
                      Client Name :
                    </div>
                    <div>
                      { this.props.selectedClient.name }
                    </div>
                  </div>
                )
            }

            <div className={styles.addressBody}>
              <div>
                Address :
              </div>
              <div>
                { this.props.selectedClient.company_address || this.props.selectedClient.home_address || '' }
              </div>
            </div>
          </div>

          <div className={styles.groupTextfield}>
            <div className={styles.autoCompleteField}>
              <Autocomplete
                name="branch_id"
                id="branch_id"
                key="branch"
                size="small"
                loading={this.props.branchesAutoCompleteLoading}
                options={(this.props.branches).sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                  return 0;
                })}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={this.props.handleAutocomplete}
                onInputChange={(event) => this.props.handleAutocompleteOnInputChange(event)}
                fullWidth={true}
                value={this.props.initialDelivery.branch}
                closeIcon={<CloseIcon fontSize="small" id="clear_branch_autocomplete" />}
                renderInput={(params) => <TextField {...params} label="Branch" variant="outlined" required />}
              />
            </div>

            <TextField
              autoComplete="off"
              variant="outlined"
              className={styles.textFields}
              size="small"
              required
              name="reference_no"
              id="reference_no"
              fullWidth={true}
              label="Delivery Number"
              value={this.props.initialDelivery.reference_no}
              onChange={this.props.handleDeliveryFormInputChange}
              InputLabelProps={{
                shrink: this.props.initialDelivery.branch !== undefined && Object.entries(this.props.initialDelivery.branch).length > 0,
              }}
            />

            <div className={styles.autoCompleteField}>
              <Autocomplete
                name="employee_id"
                id="employee_id"
                key="employee"
                size="small"
                options={(this.props.employees).sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                  return 0;
                })}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={this.props.handleAutocomplete}
                onInputChange={(event) => this.props.handleAutocompleteOnInputChange(event)}
                fullWidth={true}
                value={this.props.initialDelivery.employee}
                closeIcon={<CloseIcon fontSize="small" id="clear_employee_autocomplete" />}
                renderInput={(params) => <TextField {...params} label="Delivered By" variant="outlined" required />}
              />
            </div>

            <TextField
              autoComplete="off"
              className={styles.textFields}
              type="date"
              variant="outlined"
              size="small"
              required
              name="date_of_delivery"
              fullWidth={true}
              label="Date of Delivery"
              onChange={this.props.handleDeliveryFormInputChange}
              value={this.props.initialDelivery.date_of_delivery}
              id="date_of_delivery"
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>

          <div className={styles.groupTextfield}>
            <TextField
              autoComplete="off"
              variant="outlined"
              className={styles.textFields}
              multiline={true}
              size="small"
              name="description"
              id="description"
              fullWidth={true}
              label="Description"
              value={this.props.initialDelivery.description}
              onChange={this.props.handleDeliveryFormInputChange}
              InputProps={{
                shrink: true
              }}
            />
          </div>

          <div>
            {
              this.props.invoiceThru === true || this.props.initialDelivery.id === null || this.props.initialDelivery.id === undefined ?
              (
                <>
                  <div className={styles.headerContent}>
                    <div> Delivery Receipt Details </div>

                    <IconButton
                      variant="addAddress"
                      name="add_delivery_details"
                      disabled={Object.keys(this.props.selectedClient).length === 0}
                      onClick={() => this.props.handleOpenSimplePopup({}, true)}>
                      <AddCircleOutlineIcon fontSize="small" />
                    </IconButton>
                  </div>

                  {
                    this.props.deliveryDetails.length > 0 ? (
                      <TableContainer className="action-button" style={{maxHeight: '575px', position: 'relative'}}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <b>Invoice Reference No</b>
                              </TableCell>
                              <TableCell>
                                <b>Product Description</b>
                              </TableCell>
                              <TableCell>
                                <b>Media Type</b>
                              </TableCell>
                              <TableCell>
                                <b>Delivered Qty</b>
                              </TableCell>
                              <TableCell>
                                <b>Purpose</b>
                              </TableCell>
                              <TableCell>
                                <b>Remarks</b>
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody className={tableStyles.tableBody}>
                            {
                              this.props.deliveryDetails.map((item, index) => (
                                <>
                                  <TableRow key={index}>
                                    <TableCell>
                                      { item.invoice !== undefined && Object.entries(item.invoice).length > 0 ? (item.invoice.reference_no) : ('') }
                                    </TableCell>

                                    <TableCell>
                                      { item.cd.description }
                                    </TableCell>

                                    <TableCell>
                                      { item.media_type }
                                    </TableCell>

                                    <TableCell>
                                      { item.quantity }
                                    </TableCell>

                                    <TableCell>
                                      { item.purpose }
                                    </TableCell>

                                    <TableCell>
                                      { item.remarks }
                                    </TableCell>

                                    <TableCell size="small" className={styles.actionButtonHoverable} align="right">
                                      <Tooltip title="Edit" arrow>
                                        <IconButton
                                          size="small"
                                          id="edit_delivery_detail"
                                          name="edit_delivery_detail"
                                          color="primary"
                                          onClick={() => this.props.handleOpenSimplePopup(item, false, index)}>
                                          <EditIcon/>
                                        </IconButton>
                                      </Tooltip>

                                      <Tooltip title="Delete" arrow>
                                        <IconButton
                                          id="delete_delivery_detail"
                                          name="delete_delivery_detail"
                                          size="small"
                                          color="secondary"
                                          onClick={() => this.props.handleDeleteDeliveryDetails(index)}
                                        >
                                          <DeleteIcon/>
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (null)
                  }
                </>
              ) : (null)
            }

            <div className={styles.actionButton}>
              <Button
              disabled={!this.props.initialDelivery.client || !this.props.initialDelivery.branch || !this.props.initialDelivery.reference_no || !this.props.initialDelivery.employee || !this.props.initialDelivery.date_of_delivery || !this.props.deliveryDetails.length > 0 }
              variant="outlined" id="delivery_submit" color="primary" onClick={this.props.handleSaveDelivery}>Save</Button>
            </div>
          </div>
        </form>
      </>
    )
  }
}

export default DeliveryForm

DeliveryForm.propTypes = {
  branches: PropTypes.array,
  employees: PropTypes.array,
  clients: PropTypes.array,
  clientsAutoCompleteLoading: PropTypes.bool,
  branchesAutoCompleteLoading: PropTypes.bool,
  selectedClient: PropTypes.object,
  initialDelivery: PropTypes.object,
  initialDeliveryDetail: PropTypes.object,
  invoiceThru: PropTypes.bool,
  handleAutocomplete: PropTypes.func,
  handleAutocompleteOnInputChange: PropTypes.func,
  handleDeliveryFormInputChange: PropTypes.func,
  deliveryDetails: PropTypes.array,
  handleOpenSimplePopup: PropTypes.func,
  handleSwitch: PropTypes.func,
  handleDeleteDeliveryDetails: PropTypes.func,
  handleSaveDelivery: PropTypes.func
}
