import React from 'react'

import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import styles from './Invoices.module.css'
import {Grid} from "@material-ui/core";

class EmailInvoiceForm extends React.Component {
  render() {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              required
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="emails"
              fullWidth={true}
              label="Enter a Email"
              onChange={this.props.handleEmailChange}
              value={this.props.item.emails}
              id="emails"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              required
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="subject"
              fullWidth={true}
              label="Subject"
              onChange={this.props.handleEmailChange}
              value={this.props.item.subject}
              id="emails"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="content"
              fullWidth={true}
              label="Contents"
              onChange={this.props.handleEmailChange}
              value={this.props.item.content}
              id="emails"
            />
          </Grid>
        </Grid>

        <div className={styles.actionButton}>
          <Button
            name="send_button"
            variant="outlined"
            disabled={!this.props.item.emails}
            onClick={this.props.sendEmail}
            color="primary">Send</Button>
        </div>
      </>
    )
  }
}

export default EmailInvoiceForm

EmailInvoiceForm.propTypes = {
  item: PropTypes.object,
  handleChange: PropTypes.func,
  handleEmailChange: PropTypes.func,
  sendEmail: PropTypes.func
}
