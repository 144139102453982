import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios"
import DeliveryForm from './DeliveryForm'
import DeliveryDetailsForm from './DeliveryDetailsForm'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import { Redirect } from 'react-router'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import moment from "moment";
import * as CommonFunction from "../../shared/Custom/CommonFunctions"
import Cookies from 'js-cookie'
import AlertMessage from '../../shared/Notify/AlertMessage'
class DeliveryNew extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      model: 'New Delivery',
      error_messages: '',
      popupHeader: '',
      popupWidth: 'sm',
      updateDeliveryDetailsIndex: 0,
      clientsAutoCompleteLoading: true,
      branchesAutoCompleteLoading: true,
      employeesAutoCompleteLoading: true,
      invoicesAutoCompleteLoading: true,
      productsAutoCompleteLoading: true,
      openPopup: false,
      isAddDeliveryDetail: false,
      load: false,
      branches: [],
      clients: [],
      employees: [],
      deliveryDetails: [],
      invoices: [],
      products: [],
      initialDelivery: { date_of_delivery: moment().format("YYYY-MM-DD") },
      initialDeliveryDetail: {},
      selectedClient: {},
      savedDelivery: {},
      unauthorized: false,
      isOpen: false,
      type: '',
      message: ''
    }

    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleAutocompleteOnInputChange = this.handleAutocompleteOnInputChange.bind(this)
    this.handleCloseSimplePopup = this.handleCloseSimplePopup.bind(this)
    this.handleDeliveryFormInputChange = this.handleDeliveryFormInputChange.bind(this)
    this.handleDeliveryDetailsFormInputChange = this.handleDeliveryDetailsFormInputChange.bind(this)
    this.handleOpenSimplePopup = this.handleOpenSimplePopup.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleDeleteDeliveryDetails = this.handleDeleteDeliveryDetails.bind(this)
    this.handleSaveDeliveryDetails = this.handleSaveDeliveryDetails.bind(this)
    this.handleSaveDelivery = this.handleSaveDelivery.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
  }

  componentDidMount() {
    if(Cookies.get('current_user')) {
      if(!JSON.parse(Cookies.get('current_user')).is_from_cdasia) {
        this.setState({ unauthorized: true, isOpen: true, type: 'error', message: 'You are not authorized to access this page.' })
      }
    else{
      if (Object.entries(this.props.match.params).length > 0) {
        if (this.props.match.params.client_id) {
          this.loadThroughClients(process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.props.match.params.client_id)
        }else{
          this.loadThroughInvoices(process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + this.props.match.params.invoice_id)
        }
      }
      this.loadBranchesAutoCompleteOptions()
      this.loadClientsAutoCompleteOptions()
      this.loadCdsAutoCompleteOptions()
      this.loadDeliveryEmployeesAutoCompleteOptions()
      this.setState({ load: true })
      }
    }
  }

  loadThroughInvoices (url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ initialDelivery: { id: resp.data.id, branch: resp.data.branch, date_of_delivery: new moment().format("YYYY-MM-DD"), reference_no: resp.data.branch.name.charAt(0) + '-', client: resp.data.client, selectedClient: resp.data.client }})
      this.loadInvoicesAutoCompleteOptions(resp.data.client.id)
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadThroughClients (url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ initialDelivery: { id: resp.data.id, date_of_delivery: new moment().format("YYYY-MM-DD"), client: resp.data, selectedClient: resp.data }})
      this.loadInvoicesAutoCompleteOptions(resp.data.id)
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadClientsAutoCompleteOptions(searchText = '') {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/search?name=' + '*' + searchText + '*',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ clients: resp.data.clients, clientsAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadDeliveryEmployeesAutoCompleteOptions () {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/deliveries/employees',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ employees: resp.data, employeesAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadCdsAutoCompleteOptions(searchText = null) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/cds/search?description=' + searchText,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ products: resp.data.cds, productsAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadInvoicesAutoCompleteOptions(client_id, searchText = '') {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/search?client_id=' + client_id + '&reference_no=' + searchText,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ invoices: resp.data.invoices, invoicesAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadInvoiceCdsAutoCompleteOptions(invoice_id) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + invoice_id + '/cds/',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ products: resp.data, productsAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadBranchesAutoCompleteOptions() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ branches: resp.data })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleAutocomplete(event, value) {
    if (event.target.id.includes("employee")) {
      this.setState({ initialDelivery: { ...this.state.initialDelivery, employee: value } })
    } else if (event.target.id.includes("branch")) {
      var reference_no = value !== undefined && value !== null ? (value.name.substr(0, 1) + '-') : ('')
      this.setState({ branchesAutoCompleteLoading: false, initialDelivery: { ...this.state.initialDelivery, branch: value, reference_no: reference_no } })
    } else if (event.target.id.includes("client")) {
      this.setState({ selectedClient: value || {}, initialDelivery: { ...this.state.initialDelivery, client: value } })
      if (value !== undefined && value !== null) {
        this.loadInvoicesAutoCompleteOptions(value.id)
      } else {
        this.loadClientsAutoCompleteOptions()
      }
    } else if (event.target.id.includes("invoice")) {
      this.setState({ initialDeliveryDetail: { ...this.state.initialDeliveryDetail, invoice: value } })
      if (value !== undefined && value !== null) {
        this.loadInvoiceCdsAutoCompleteOptions(value.id)
      } else {
        this.loadCdsAutoCompleteOptions()
      }
    } else if (event.target.id.includes("cd")) {
      this.setState({ initialDeliveryDetail: { ...this.state.initialDeliveryDetail, cd: value } })
    }
  }

  handleAutocompleteOnInputChange(event, value, reason) {
    if (reason === 'input') {
      if (event.target.id.includes("client")) {
        this.setState({ clientsAutoCompleteLoading: true })
        this.loadClientsAutoCompleteOptions(event.target.value || '')
      } else if(event.target.id.includes("invoice")) {
        this.setState({ invoicesAutoCompleteLoading: true })
        this.loadInvoicesAutoCompleteOptions(this.state.initialDelivery.client_id, event.target.value || '')
      } else if(event.target.id.includes("cd")) {
        this.setState({ productsAutoCompleteLoading: true })
        this.loadCdsAutoCompleteOptions(event.target.value || '')
      }
    }
  }

  handleDeliveryFormInputChange(event) {
    this.setState({ initialDelivery: { ...this.state.initialDelivery, [event.target.id]: event.target.value } })
  }

  handleDeliveryDetailsFormInputChange(event) {
    this.setState({ initialDeliveryDetail: { ...this.state.initialDeliveryDetail, [event.target.name]: event.target.value } })
  }

  handleCloseSimplePopup () {
    this.setState({ openPopup: false, openDeletePopup: false, error_messages: '' })
  }

  handleOpenSimplePopup(item, isAdd, targetIndex = 0) {
    var popupHeader = ''
    isAdd ? (popupHeader = 'Add Delivery Detail') : (popupHeader = 'Edit Delivery Detail')
    this.setState({ openPopup: true, initialDeliveryDetail: item, popupHeader: popupHeader, isAddDeliveryDetail: isAdd, updateDeliveryDetailsIndex: parseInt(targetIndex) })
    console.log(this.state.deliveryDetails)
    console.log(this.state.deliveryDetails.length)
  }

  handleSaveDeliveryDetails(deliveryDetail) {
    this.handleCloseSimplePopup()
    this.state.isAddDeliveryDetail ? (this.state.deliveryDetails.push(deliveryDetail)) : (this.state.deliveryDetails.splice(this.state.updateDeliveryDetailsIndex, 1, deliveryDetail))
  }

  handleSwitch(event) {
    this.setState({ initialDeliveryDetail: { ...this.state.initialDeliveryDetail, [event.target.id]: event.target.checked } })
  }

  handleDeleteDeliveryDetails(index) {
    const delivery_details = [...this.state.deliveryDetails]
    delivery_details.splice(index, 1)
    this.setState({ deliveryDetails: delivery_details })
  }

  handleSaveDelivery() {
    var deliveryDetails = []

    this.state.deliveryDetails.map((item) => {
      deliveryDetails.push({ invoice_id: Object.entries(item.invoice).length > 0 ? (item.invoice.id) : (''), cd_id: item.cd.id,
                             media_type: item.media_type, qty: item.quantity, purpose: item.purpose, is_virtual: item.is_virtual,
                             remarks: item.remarks })
    })

    var delivery = { client_id: this.state.initialDelivery.client.id, branch_id: this.state.initialDelivery.branch.id, employee_id: this.state.initialDelivery.employee.id,
                     reference_no: this.state.initialDelivery.reference_no, date_of_delivery: new moment(this.state.initialDelivery.date_of_delivery).format("DD/MM/YYYY"),
                     description: this.state.initialDelivery.description, delivery_details_attributes: deliveryDetails }

    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/deliveries',
      data: (delivery),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ isOpen: true, message: 'Submitted Successfully', type: 'success' })
      this.setState({savedDelivery: resp.data})
      this.setState({redirect: true})
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Delivery Number ' + error.response.data.errors.reference_no[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Problem Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }
  handleCloseAlert(event, reason) {
    console.log(event,reason)
    this.setState({ redirectToHome: true })
    if (reason === 'clickaway') {
      return;
    }
    
  }

  render () {
    if(this.state.redirectToHome){
      return(
        <Redirect to={{'pathname':'/'}}/>  
      )
    }
    
    const {redirect} = this.state
    if (redirect)
      return (
        <Redirect to={{
          pathname: '/deliveries/' + this.state.savedDelivery.id
        }}/>)
    return (
      <>
        {
          this.state.load ? (
            <div>
              <DeliveryForm
                branches={this.state.branches}
                employees={this.state.employees}
                clients={this.state.clients}
                invoiceThru={true}
                clientsAutoCompleteLoading={this.state.clientsAutoCompleteLoading}
                branchesAutoCompleteLoading={this.state.branchesAutoCompleteLoading}
                employeesAutoCompleteLoading={this.state.employeesAutoCompleteLoading}
                selectedClient={this.state.selectedClient}
                initialDelivery={this.state.initialDelivery}
                deliveryDetails={this.state.deliveryDetails}
                initialDeliveryDetail={this.state.initialDeliveryDetail}
                handleAutocomplete={this.handleAutocomplete}
                handleAutocompleteOnInputChange={this.handleAutocompleteOnInputChange}
                handleDeliveryFormInputChange={this.handleDeliveryFormInputChange}
                handleOpenSimplePopup={this.handleOpenSimplePopup}
                handleDeleteDeliveryDetails={this.handleDeleteDeliveryDetails}
                handleSaveDelivery={this.handleSaveDelivery}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.popupHeader}
                handleClose={this.handleCloseSimplePopup}
                maxWidth={true}
              >
                <DeliveryDetailsForm
                  invoicesAutoCompleteLoading={this.state.invoicesAutoCompleteLoading}
                  productsAutoCompleteLoading={this.state.productsAutoCompleteLoading}
                  invoices={this.state.invoices}
                  products={this.state.products}
                  initialDeliveryDetail={this.state.initialDeliveryDetail}
                  handleAutocomplete={this.handleAutocomplete}
                  handleAutocompleteOnInputChange={this.handleAutocompleteOnInputChange}
                  handleDeliveryDetailsFormInputChange={this.handleDeliveryDetailsFormInputChange}
                  handleSaveDeliveryDetails={this.handleSaveDeliveryDetails}
                  handleSwitch={this.handleSwitch} />
              </SimplePopUp>
            </div>
          ) : (
            this.state.unauthorized?
            <AlertMessage
              isOpen={this.state.isOpen}
              handleCloseAlert={this.handleCloseAlert}
              message={this.state.message}
              type={this.state.type}
            />
            :<ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default DeliveryNew

DeliveryNew.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      client_id: PropTypes.string,
      invoice_id: PropTypes.string
    })
  })
}
