import React from 'react'
import styles from './Journal.module.css'

import PropTypes from 'prop-types'

import {
	TextField,
	Button,
} from '@material-ui/core'

import { Link } from 'react-router-dom'
import {
	Table, TableHead, TableCell, Tooltip, TableRow, TableBody, IconButton, InputAdornment,
} from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import JournalEntries from "./JournalEntries";
import Autocomplete from '@material-ui/lab/Autocomplete'
import RefreshIcon from '@material-ui/icons/Refresh';

class JournalForm extends React.Component {
	getTotalDebit() {
		var totalDebit = 0
		if (this.props.journalEntries.length > 0) {
			totalDebit = this.props.journalEntries.reduce((a, b) => parseFloat(parseFloat(a + parseFloat(b.amount_debit)).toFixed(2)), 0)
		}
		return totalDebit
	}

	getTotalCredit() {
		var totalCredit = 0
		if (this.props.journalEntries.length > 0) {
			totalCredit = this.props.journalEntries.reduce((a, b) => parseFloat(parseFloat(a + parseFloat(b.amount_credit)).toFixed(2)), 0)
		}
		return totalCredit
	}
	getBalance() {
		var balance = 0
		if (this.props.journalEntries.length > 0) {
			balance = parseFloat(this.getTotalCredit() - this.getTotalDebit()).toFixed(2)
		}
		return balance
	}
	render() {
		
		return (
			<div>
				<form>

					<div className={styles.group2Textfield}>
						<div className={styles.autoCompleteField}>
							<Autocomplete
								value={this.props.branch}
								id="branch"
								name="branch"
								fullWidth
								size="small"
								options={(this.props.branches).sort(function (a, b) {
									if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
									if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
									return 0;
								})}
								getOptionLabel={(option) => option.name || ''}
								getOptionSelected={(option, value) => option.name === value.name}
								onChange={this.props.handleAutocomplete}
								renderInput={(params) => (
									<TextField {...params} name="branch" variant="outlined" label="Branch Name" />
								)}
							/>
						</div>
						<TextField
							autoComplete="off"
							variant="outlined"
							className={styles.textFields}
							size="small"
							name="reference_no"
							fullWidth
							label="Reference No."
							onChange={this.props.handleInputChange}
							value={this.props.journal.reference_no}
							InputProps={{
								startAdornment: <InputAdornment><div>{this.props.journal.prefix ? this.props.journal.prefix : 'JV'}</div></InputAdornment>,
								endAdornment:
									<InputAdornment position="end" >{this.props.journal.reference_no ? <Tooltip title="Get Reference No"><IconButton id="refresh_ref_no" name="refresh_ref_no" onClick={this.props.getReferenceNo}><RefreshIcon /></IconButton></Tooltip> : null}</InputAdornment>,
							}}


						/>
						<TextField
							autoComplete="off"
							variant="outlined"
							type="date"
							className={styles.textFields}
							size="small"
							name="date_of_journal"
							fullWidth
							label="Date of Journal"
							onChange={this.props.handleInputChange}
							value={this.props.journal.date_of_journal}
							InputLabelProps={{
								shrink: true
							}}
						/>
					</div>
					{
						this.props.error ? (
							this.props.error.reference_no ? (
								<div className={styles.errorBlock}>
									<div className={styles.autoCompleteField}></div>
									<div className={styles.errorField}>{this.props.error.reference_no ? 'Reference No. ' + this.props.error.reference_no : null}</div>
									<div className={styles.autoCompleteField}></div>
								</div>
							) : null
						) : null
					}

					<TextField
						required
						autoComplete="off"
						variant="outlined"
						className={styles.textFields}
						size="small"
						name="description"
						fullWidth
						label="Particulars"
						onChange={this.props.handleInputChange}
						value={this.props.journal.description}
					/>
				</form>

				<div className={styles.headerContent}>
					<div className={styles.valueDetails}><div> Journal Entries</div>
						<div className={styles.switch}> <FormControlLabel
							control={<Switch
								size="small"
								checked={this.props.withReference}
								onChange={this.props.handleSwitch}
								name="reference" />}
							label="With Reference"
						/>
						</div>
					</div>

					<div> Balance: {this.getBalance()}</div>

					<IconButton

						name="add_journal_entry"
						onClick={this.props.addJournalEntry}>
						<AddCircleOutlineIcon fontSize="small" />
					</IconButton>
				</div>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								<b>Account Code</b>
							</TableCell>
							<TableCell>
								<b>Account Name</b>
							</TableCell>
							{
								this.props.withReference ? (
									<>
										<TableCell >
											<b>Memo/Description</b>
										</TableCell>
										<TableCell>
											<b>Reference Type</b>
										</TableCell>
										<TableCell>
											<b>Name</b>
										</TableCell>
									</>
								) : null
							}

							<TableCell align="right">
								<b>Debit</b>
							</TableCell>
							<TableCell align="right">
								<b>Credit</b>
							</TableCell>

						</TableRow>
					</TableHead>


					{this.props.journalEntries.map((val, idx) => (
						<JournalEntries
							key={`check_content-${idx}`}
							idx={idx}
							name={`account_content_${idx}`}
							handleJournalEntriesChange={this.props.handleJournalEntriesChange}
							removeJournalEntry={this.props.removeJournalEntry}
							accounts={this.props.accounts}
							handleAutocomplete={this.props.handleContentAutocomplete}
							journalEntries={this.props.journalEntries}
							handleJournalEntriesAmountChange={this.props.handleJournalEntriesAmountChange}
							withReference={this.props.withReference}
							handleReferences={this.props.handleReferences}
							referenceTypes={this.props.referenceTypes}
							references={this.props.references}
							handleReferenceAutoComplete={this.props.handleReferenceAutoComplete}
							handleReferenceAutoCompleteSearch={this.props.handleReferenceAutoCompleteSearch}
							handleMemo={this.props.handleMemo}

						/>

					))}
					<TableBody>
						<TableRow>
							<TableCell></TableCell>
							{
								this.props.withReference ? (
									<>
										<TableCell></TableCell>
										<TableCell></TableCell>
										<TableCell></TableCell>
									</>
								) : null
							}
							<TableCell align="right"><b>Total :</b></TableCell>
							<TableCell align="right">&#8369; {this.getTotalDebit().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
							<TableCell align="right">&#8369; {this.getTotalCredit().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>

						</TableRow>
					</TableBody>
				</Table>
				<div
					className={styles.actionButton}>
					<Button variant="outlined" id="voucher-back" ><Link className={styles.removeStyle} to={`/journals`}>Back</Link></Button>
					<Button variant="outlined" id="voucher-submit" name="voucher-submit" color="primary" disabled={this.getTotalCredit() !== this.getTotalDebit() || !this.props.journal.description || !this.props.journalEntries.length > 0}
						onClick={() => this.props.handleSubmit()}
					>Save</Button>
				</div>
			</div>

		)
	}
}
export default JournalForm

JournalForm.propTypes = {
	handleSubmit: PropTypes.func,
	handleAutocomplete: PropTypes.func,
	handleInputChange: PropTypes.func,
	journal: PropTypes.object,
	journalEntries: PropTypes.array,
	payees: PropTypes.array,
	banks: PropTypes.array,
	expenseTypes: PropTypes.array,
	accounts: PropTypes.array,
	branches: PropTypes.array,
	expenseType: PropTypes.object,
	handleSwitch: PropTypes.func,
	error: PropTypes.object,
	amountChange: PropTypes.func,
	branch: PropTypes.object,
	payee: PropTypes.object,
	bank: PropTypes.object,
	handleContentAutocomplete: PropTypes.func,
	addJournalEntry: PropTypes.func,
	removeJournalEntry: PropTypes.func,
	handleJournalEntriesChange: PropTypes.func,
	handleJournalEntriesAmountChange: PropTypes.func,
	zeroFill: PropTypes.string,
	withReference: PropTypes.bool,
	referenceTypes: PropTypes.array,
	handleReferences: PropTypes.func,
	references: PropTypes.array,
	handleReferenceAutoComplete: PropTypes.func,
	handleReferenceAutoCompleteSearch: PropTypes.func,
	handleMemo: PropTypes.func,
	getReferenceNo: PropTypes.func
}
