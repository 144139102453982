// eslint-disable-next-line

import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import moment from 'moment';
import 'moment-timezone';
import {Redirect} from 'react-router'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Box, Button, IconButton, Tab, Tabs, Typography, Table, TableHead, TableCell, TableRow,
         TableBody, Tooltip, TableContainer,TextField } from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import styles from './Receipt.module.css'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import tableStyles from './../../shared/SimpleTable/SimpleTable.module.css'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import ReceiptForm from './ReceiptForm'
import ReceiptDetailsForm from './ReceiptDetailsForm'
import AlertMessage from "../../shared/Notify/AlertMessage";
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import JournalEntriesDetailsTab from './ReceiptTabs/JournalEntriesTab'
import * as CommonFunction from "../../shared/Custom/CommonFunctions"
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import {Link} from "react-router-dom";
import AuditLog from '../../shared/AuditLog/AuditLog';
import ReactToPrint from "react-to-print";
import CompanyHeader from '../../shared/CompanyHeader/CompanyHeader'

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class ReceiptShow extends React.Component {
  constructor(props) {
    super(props)
    const current_user = JSON.parse(localStorage.getItem("current_user"))

    this.state = {
      model: 'Receipts',
      popupHeader: '',
      title: '',
      error_messages: '',
      alertMessage: '',
      type: '',
      selectedReceiptDetailsIndex: 0,
      value: 0,
      invoices: [],
      journalEntries: [],
      banks: [],
      branches: [],
      paymentTerms: [],
      receiptDetails: [],
      clientsAutoCompleteLoading: true,
      banksAutoCompleteLoading: true,
      branchesAutoCompleteLoading: true,
      employeesAutoCompleteLoading: true,
      invoicesAutoCompleteLoading: true,
      openNewAndEditReceiptDetailPopup: false,
      openDeleteReceiptPopup: false,
      openDeleteReceiptDetailsPopup: false,
      redirectToIndex: false,
      openEditReceiptPopup: false,
      openAlertMessage: false,
      openPostReceiptJournalPopup: false,
      openUnostReceiptJournalPopup: false,
      isCheckPayment: false,
      load: false,
      receipt: {},
      initialReceiptDetail: { branch: current_user.branch || {} },
      initialReceipt: {},
      journal: {},
      invoice: {},
      is_cash_sales: false,
      forPrint: false,
      signatory: {},
      openPrintPopup: false,
      changeSignPopup: false,
      openPrintPDFPopup: false,
    }

    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleAutocompleteOnInputChange = this.handleAutocompleteOnInputChange.bind(this)
    this.handleReceiptDetailsFormInputChange = this.handleReceiptDetailsFormInputChange.bind(this)
    this.handleCloseSimplePopup = this.handleCloseSimplePopup.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleOpenReceiptDetailSimplePopup = this.handleOpenReceiptDetailSimplePopup.bind(this)
    this.handleDeleteReceipt = this.handleDeleteReceipt.bind(this)
    this.handleDeleteReceiptDetails = this.handleDeleteReceiptDetails.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSaveReceiptDetails = this.handleSaveReceiptDetails.bind(this)
    this.handleReceiptFormInputChange = this.handleReceiptFormInputChange.bind(this)
    this.handleEditReceiptSimplePopup = this.handleEditReceiptSimplePopup.bind(this)
    this.handleSaveReceipt = this.handleSaveReceipt.bind(this)
    this.handleChangeTabs = this.handleChangeTabs.bind(this)
    this.handlePostReceiptJournal = this.handlePostReceiptJournal.bind(this)
    this.handleUnpostReceiptJournal = this.handleUnpostReceiptJournal.bind(this)
    this.handleOpenPostAndUnpostSimplePopUp = this.handleOpenPostAndUnpostSimplePopUp.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleInputChangeReceiptDetailAmount = this.handleInputChangeReceiptDetailAmount.bind(this)
    this.changePrintLayout = this.changePrintLayout.bind(this)
    this.openPrint = this.openPrint.bind(this)
    this.changeSign = this.changeSign.bind(this)
    this.submitChangeSign = this.submitChangeSign.bind(this)
    this.handleInputChangePDF = this.handleInputChangePDF.bind(this)
    this.openPrintPDF = this.openPrintPDF.bind(this)
  }

  componentDidMount() {
    this.loadReceipt()
  }

  loadReceipt() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/receipts/' + this.props.match.params.id,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ receipt: resp.data, initialReceipt: resp.data, selectedClient: resp.data.client, load: true })
      this.loadReceiptDetails()
      this.loadInvoicesAutoCompleteOptions(this.state.receipt.client_id)
      this.loadBanksAutoCompleteOptions()
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ branches: resp.data })
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/payment_terms',
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ paymentTerms: resp.data })
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadReceiptDetails() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/receipts/' + this.props.match.params.id + '/receipt_contents',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ receiptDetails: resp.data })

      if (resp.data.length === 1) {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + resp.data[0].invoice_id,
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          if (resp.data.is_cash_sales) {
            this.setState({ journal: resp.data.journal, invoice: resp.data })
            this.loadJournalEntries(process.env.REACT_APP_API_DOMAIN + '/v1/invoices/' + resp.data.id + '/journal_entries')
          } else {
            this.setState({ journal: this.state.receipt.journal })
            this.loadJournalEntries(process.env.REACT_APP_API_DOMAIN + '/v1/receipts/' + this.state.receipt.id + '/journal_entries')
          }
        }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
      } else {
        this.loadJournalEntries(process.env.REACT_APP_API_DOMAIN + '/v1/receipts/' + this.state.receipt.id + '/journal_entries')
      }
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadJournalEntries (url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ journalEntries: resp.data })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadClientsAutoCompleteOptions(searchText = '') {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/search?name=' + searchText,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ clients: resp.data.clients, clientsAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadInvoicesAutoCompleteOptions(client_id, searchText = '') {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/search?client_id=' + client_id + '&reference_no=' + searchText + '&status=for_approval,for_posting',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ invoices: resp.data.invoices.filter(invoice => !invoice.is_cash_sales && invoice.remaining_balance > 0), invoicesAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadBanksAutoCompleteOptions(searchText = '') {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/banks/search?name=' + searchText,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ banks: resp.data.banks, banksAutoCompleteLoading: false })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  getLastReceiptNumberByBranch(branch_id) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/receipts/search?branch_id=' + branch_id,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      var receipts = resp.data.receipts.sort(function (a, b) {
        if (parseInt(a.reference_no) > parseInt(b.reference_no)) return -1;
        if (parseInt(a.reference_no) < parseInt(b.reference_no)) return 1;
        return 0;
      })
      if (receipts.length > 0) {
        this.setState({ initialReceipt: { ...this.state.initialReceipt, reference_no: this.padFix(parseInt(receipts[0].reference_no) + 1)[0] } })
      } else {
        this.setState({ initialReceipt: { ...this.state.initialReceipt, reference_no: this.padFix("1")[0] } })
      }
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleAutocomplete(event, value) {
    if (event.target.id.includes("branch")) {
      this.setState({ branchesAutoCompleteLoading: false, initialReceipt: { ...this.state.initialReceipt, branch: value } })
      value !== undefined && value !== null ? (this.getLastReceiptNumberByBranch(value.id)) : (this.setState({ ...this.state.initialReceipt, reference_no: this.padFix("1")[0] }))
    } else if (event.target.id.includes("invoice")) {
      if (![undefined, null].includes(value)) {
        this.setState({ initialReceiptDetail: { ...this.state.initialReceiptDetail, invoice: value, payment_term: value.payment_term, amount: 0, balance: parseFloat(value.remaining_balance), canSaveDetails: value.remaining_balance > 0 } })
      } else {
        this.setState({ initialReceiptDetail: { ...this.state.initialReceiptDetail, invoice: {}, payment_term: {}, amount: 0, balance: 0 } })
      }
    } else if (event.target.id.includes("bank")) {
      this.setState({ initialReceiptDetail: { ...this.state.initialReceiptDetail, bank: value || {} } })
    } else if (event.target.id.includes("payment_term")) {
      if (![null, undefined].includes(value) && value.name === "Check") {
        this.setState({ initialReceiptDetail: { ...this.state.initialReceiptDetail, payment_term: value, date_of_check: moment().format("YYYY-MM-DD"), pdc: false }, isCheckPayment: true })
      } else {
        this.setState({ initialReceiptDetail: { ...this.state.initialReceiptDetail, payment_term: value || {} }, isCheckPayment: false })

      }
    }
  }

  handleAutocompleteOnInputChange(event, value, reason) {
    if (reason === 'input') {
      if(event.target.id.includes("invoice")) {
        this.setState({ invoicesAutoCompleteLoading: true })
        this.loadInvoicesAutoCompleteOptions(this.state.initialReceipt.client_id, event.target.value || '')
      }
    }
  }

  handleReceiptFormInputChange(event) {
    this.setState({ initialReceipt: { ...this.state.initialReceipt, [event.target.id]: event.target.value } })
  }

  handleReceiptDetailsFormInputChange(event) {
    this.setState({ initialReceiptDetail: { ...this.state.initialReceiptDetail, [event.target.name]: event.target.value } })
  }

  handleCloseSimplePopup () {
    this.setState({ openNewAndEditReceiptDetailPopup: false, openDeleteReceiptPopup: false, openDeleteReceiptDetailsPopup: false, isCheckPayment: false,
                    openPostReceiptJournalPopup: false, openUnpostReceiptJournalPopup: false, openEditReceiptPopup: false, error_messages: '' })
    this.setState({ openPrintPopup: false, changeSignPopup: false, signatory: {...this.state.signatory, creator: ''},openPrintPDFPopup: false })

  }

  handleOpenDeletePopup(opts) {
    this.setState(opts)
  }

  handleSwitch(event) {
    this.setState({ initialReceiptDetail: { ...this.state.initialReceiptDetail, [event.target.id]: event.target.checked } })
  }

  handleEditReceiptSimplePopup() {
    this.setState({ openEditReceiptPopup: true, popupHeader: 'Edit Collection Receipt', initialReceipt: this.state.receipt })
    this.setState({ initialReceipt: { ...this.state.initialReceipt, ...this.state.receipt } })
  }

  handleOpenReceiptDetailSimplePopup(item, isAdd, targetIndex = 0) {
    var popupHeader = ''
    isAdd ? (popupHeader = 'Add Collection Receipt Detail') : (popupHeader = 'Edit Collection Receipt Detail')

    if (Object.entries(item).length > 0) {
      item = { ...item, amount: parseFloat(item.amount), balance: parseFloat(item.invoice.remaining_balance) - parseFloat(item.amount) }
    }

    this.setState({ isCheckPayment: item !== undefined && item !== null && Object.entries(item).length > 0 && item.payment_term.name === "Check" })
    this.setState({ openNewAndEditReceiptDetailPopup: true, popupHeader: popupHeader, selectedReceiptDetailsIndex: parseInt(targetIndex), initialReceiptDetail: { ...item, value: parseFloat(item.amount) } })
  }

  handleDeleteReceipt() {
    if (this.state.receiptDetails.length === 1) {
      this.state.receiptDetails.map((receipt_detail) => {
        axios({
          method: 'DELETE',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/receipts/' + this.state.receipt.id + '/receipt_contents/' + receipt_detail.id,
          headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
        }).then(() => {
          this.deleteReceipt()
        }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
      })
    } else {
      this.deleteReceipt()
    }
  }

  deleteReceipt() {
    axios({
      method: 'DELETE',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/receipts/' + this.state.receipt.id,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({ openAlertMessage: true, openDeleteReceiptPopup: false, redirectToIndex: true, alertMessage: 'Deleted Successfully', type: 'error' })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleDeleteReceiptDetails(item, index) {
    axios({
      method: 'DELETE',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/receipts/' + item.receipt_id + '/receipt_contents/' + item.id,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(() => {
      this.state.receiptDetails.splice(index, 1)
      this.setState({ openAlertMessage: true, openDeleteReceiptDetailsPopup: false, alertMessage: 'Deleted Successfully', type: 'error' })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleSaveReceiptDetails(item) {
    var method = ''
    var url = process.env.REACT_APP_API_DOMAIN + '/v1/receipts/' + this.state.receipt.id + '/receipt_contents/'
    var receipt_detail = { invoice_id: item.invoice.id, bank_id: item.bank.id,
                           payment_term_id: parseInt(item.payment_term.id), amount: parseFloat(item.amount),
                           remarks: item.remarks }

    if (this.state.isCheckPayment) {
      receipt_detail.check_no = item.check_no
      receipt_detail.bank_of_check = item.bank_of_check
      receipt_detail.type_of_check = item.type_of_check
      receipt_detail.date_of_check = item.date_of_check
      receipt_detail.pdc = item.pdc
    }

    if (this.state.initialReceiptDetail.id !== undefined && this.state.initialReceiptDetail.id !== null) {
      method = 'PUT'
      url = url + this.state.initialReceiptDetail.id
    } else {
      method = 'POST'
    }

    axios({
      method: method,
      url: url,
      data: receipt_detail,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({ openAlertMessage: true, alertMessage: 'Submitted Successfully', type: 'success' })
      this.handleCloseSimplePopup()
      this.loadReceiptDetails()
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handleSaveReceipt() {
    var receipt = { client_id: this.state.initialReceipt.client.id, branch_id: this.state.initialReceipt.branch.id,
                     reference_no: this.state.initialReceipt.reference_no, date_of_receipt: this.state.initialReceipt.date_of_receipt }

    axios({
      method: 'PUT',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/receipts/' + this.state.receipt.id,
      data: (receipt),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ openAlertMessage: true, alertMessage: 'Submitted Successfully', type: 'success' })
      this.setState({savedReceipt: resp.data})
      this.setState({redirect: true})
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Collection Receipt Number ' + error.response.data.errors.reference_no[0]})
      } else {
        this.setState({ openAlertMessage: true, alertMessage: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error' })
      }
    })
    this.handleCloseSimplePopup()
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openAlertMessage: false, alertMessage: '', type: '' })
  }

  handleChangeTabs(event, newValue) {
    this.setState({value: newValue})
  }

  handlePostReceiptJournal() {
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/journals/' + this.state.journal.id + '/post',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then((resp) => {
      this.setState({ openAlertMessage: true, openPostReceiptJournalPopup: false, alertMessage: 'Submitted Successfully', type: 'success', journal: resp.data })
    })
  }

  handleUnpostReceiptJournal() {
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/journals/' + this.state.journal.id + '/unpost',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then((resp) => {
      this.setState({ openAlertMessage: true, openUnpostReceiptJournalPopup: false, alertMessage: 'Submitted Successfully', type: 'success', journal: resp.data })
    })
  }

  handleOpenPostAndUnpostSimplePopUp(opts) {
    this.setState(opts)
  }

  handleInputChangeReceiptDetailAmount(e) {
    if (![null, undefined, ''].includes(e.floatValue)) {
      this.setState({
        initialReceiptDetail: {
          ...this.state.initialReceiptDetail,
          amount: e.floatValue,
          balance: parseFloat(this.state.initialReceiptDetail.invoice.remaining_balance) - e.floatValue
        }
      })
    } else {
      this.setState({
        initialReceiptDetail: {
          ...this.state.initialReceiptDetail,
          amount: 0,
          balance: parseFloat(this.state.initialReceiptDetail.invoice.remaining_balance)
        }
      })
    }
  }

  padFix(n) {
    return ('00000000'+n.toString()).match(/\S{8}$/);
  }

  openPrint() {
    this.setState({ openPrintPopup: true })
  }
  
  openPrintPDF() {
    this.setState({ openPrintPDFPopup: true })
  }
  
  changeSign() {
    this.setState({ changeSignPopup: true })
  }

  changePrintLayout() {
    return new Promise((resolve) => {
      this.setState({ forPrint: !this.state.forPrint,openPrintPDFPopup: false}, () => resolve());
    });
  } 

  handleInputChangePDF(e) {
    this.setState({ signatory: {  ...this.state.signatory, [e.target.name]: e.target.value  } })
  }

  submitChangeSign() {
    this.setState({receipt: {...this.state.receipt, creator_name: this.state.signatory.creator}, changeSignPopup: false})
  }

  render() {
    const {redirectToIndex} = this.state
    if (redirectToIndex)
      return (<Redirect to={'/receipts'}/>)

    const printPdf = <ReactToPrint
      trigger={() => {
        return <Button color="primary" variant='outlined'>No</Button>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />

    const ChangeSignPrintPdf = <ReactToPrint
      trigger={() => {
        return <Button color="primary" variant='outlined'>Proceed</Button>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />
    return (
      <>
        {
          this.state.load ? (
            <div ref={el => (this.componentRef = el)} className={this.state.forPrint ? styles.tableBodyHeight : null}>
              <CompanyHeader forPrint={this.state.forPrint}/>
              <div className={styles.showTitleHeader}>
                <div><h2>Reference Number: {this.state.receipt.reference_no}</h2></div>
                <div className={this.state.forPrint ? styles.noDisplay: null}>
                  <div className={styles.groupButtonShow}>
                    <Link to="/receipts">
                      <Tooltip title="Back" arrow>
                        <IconButton>
                          <ArrowBackIosOutlinedIcon/>
                        </IconButton>
                      </Tooltip>
                    </Link>
                    <Tooltip title="Edit">
                      <IconButton
                        color="primary"
                        onClick={() => this.handleEditReceiptSimplePopup()}
                      >
                        <EditIcon/>
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete">
                      <IconButton
                        color="secondary"
                        onClick={() => this.handleOpenDeletePopup({ model: "collection of receipt", openDeleteReceiptPopup: true })}
                      >
                        <DeleteIcon/>
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <hr/>
              <div className={styles.detailsBody}>
                <div className={styles.leftShowDetails}>
                  <div className={styles.detailList}>
                    <div><b> Client Name </b></div>
                    <div>:&emsp; { <a href={'/clients/' + this.state.receipt.client.id} target="_blank"
                                     rel="noopener noreferrer">{this.state.receipt.client.name}</a> }</div>
                  </div>

                  <div className={styles.detailList}>
                    <div><b> Branch </b></div>
                    <div>:&emsp; { this.state.receipt.branch.name } </div>
                  </div>

                  <div className={styles.detailList}>
                    <div><b> Date of Receipt </b></div>
                    <div>:&emsp;
                      { moment(this.state.receipt.date_of_receipt).format("MM/DD/YYYY") }
                    </div>
                  </div>
                </div>

                <div className={styles.rightShowDetails}>
                  <div className={styles.detailList}>
                    <div><b> Address </b></div>
                    <div>:&emsp; </div>
                    <div>{ this.state.receipt.client.company_address || this.state.receipt.client.home_address } </div>
                  </div>

                  <div className={styles.detailList}>
                    <div><b> Prepared by </b></div>
                    <div>:&emsp; { this.state.receipt.creator_name } </div>
                  </div>
                </div>
              </div>
              <hr/>

              <div>
                <Tabs value={ this.state.value } onChange={this.handleChangeTabs} indicatorColor={this.state.forPrint? "": "secondary"}
                      className={ styles.tabBody } aria-label="simple tabs example">
                  <Tab className={this.state.forPrint ? styles.noDisplay: styles.tabList} label={<div className={styles.tabListTitle}>
                    {/*<PersonOutlineIcon /> */}
                    <span className={ styles.tabLabel }> <b> COLLECTION RECEIPT DETAILS </b> </span></div>}{...a11yProps(0)} />
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                    {/*<CalendarTodayIcon />*/}
                    <span className={styles.tabLabel}> <b> JOURNAL ENTRIES </b> </span></div>} {...a11yProps(1)} />
                    <Tab className={this.state.forPrint ? styles.noDisplay: styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}> <b> AUDIT LOGS </b> </span></div>} {...a11yProps(2)} />
                </Tabs>

                <TabPanel value={ this.state.value } index={0}>
                  {
                    Object.entries(this.state.invoice).length === 0 ? (
                      <div className={styles.checkDetailsHeader}>
                        <Tooltip title="Add Receipt Detail">
                          <IconButton
                            variant="addAddress"
                            name="add_receipt_details"
                            disabled={Object.keys(this.state.receipt.client).length === 0}
                            onClick={() => this.handleOpenReceiptDetailSimplePopup({}, true)}>
                            <AddCircleOutlineIcon fontSize="md" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    ) : null
                  }

                  {
                    this.state.receiptDetails.length > 0 ? (
                      <TableContainer className="action-button" style={{maxHeight: '575px', position: 'relative'}}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <b>Invoice Reference No</b>
                              </TableCell>
                              <TableCell>
                                <b>Bank</b>
                              </TableCell>
                              <TableCell>
                                <b>Terms of Payment</b>
                              </TableCell>
                              <TableCell>
                                <b>Amount</b>
                              </TableCell>
                              <TableCell>
                                <b>Remarks</b>
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody className={tableStyles.tableBody}>
                            {
                              this.state.receiptDetails.map((item, index) => (
                                <>
                                  <TableRow key={index}>
                                    <TableCell>
                                      { item.invoice.reference_no }
                                    </TableCell>

                                    <TableCell>
                                      { item.bank ? item.bank.name : "-" }
                                    </TableCell>

                                    <TableCell>
                                      { item.payment_term.name }
                                    </TableCell>

                                    <TableCell>
                                      { parseFloat(item.amount).toLocaleString(navigator.language, {minimumFractionDigits: 2}) }
                                    </TableCell>

                                    <TableCell>
                                      { item.remarks }
                                    </TableCell>

                                    <TableCell size="small" className={styles.actionButtonHoverable} align="right">
                                      <Tooltip title="Edit" arrow>
                                        <IconButton
                                          size="small"
                                          id="edit_receipt_detail"
                                          name="edit_receipt_detail"
                                          color="primary"
                                          onClick={() => this.handleOpenReceiptDetailSimplePopup(item, false, index)}>
                                          <EditIcon/>
                                        </IconButton>
                                      </Tooltip>

                                      <Tooltip title="Delete" arrow>
                                        <IconButton
                                          id="delete_receipt_detail"
                                          name="delete_receipt_detail"
                                          size="small"
                                          color="secondary"
                                          onClick={() => this.handleOpenDeletePopup({ model: "receipt detail", openDeleteReceiptDetailsPopup: true, initialReceiptDetail: item, selectedReceiptDetailsIndex: index })}
                                        >
                                          <DeleteIcon/>
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (null)
                  }
                </TabPanel>

                <TabPanel value={this.state.value} index={1} className={styles.tabListBody}>
                  <JournalEntriesDetailsTab
                    receipt={this.state.receipt}
                    journal={this.state.journal}
                    invoice={this.state.invoice}
                    journalEntries={this.state.journalEntries}
                    handleOpenPostAndUnpostSimplePopUp={this.handleOpenPostAndUnpostSimplePopUp}
                    openPrint={this.openPrintPDF}
                    forPrint={this.state.forPrint}
                  />
                </TabPanel>
                <TabPanel value={this.state.value} index={2} className={styles.tabListBody}>
                  <AuditLog gid={this.state.receipt.gid} />
                </TabPanel>
              </div>


              <SimplePopUp
                openPopup={this.state.openNewAndEditReceiptDetailPopup}
                title={this.state.popupHeader}
                handleClose={this.handleCloseSimplePopup}
                maxWidth={true}
              >
                <ReceiptDetailsForm
                  invoicesAutoCompleteLoading={ this.state.invoicesAutoCompleteLoading }
                  banksAutoCompleteLoading={ this.state.banksAutoCompleteLoading }
                  isCheckPayment={ this.state.isCheckPayment }
                  invoices={ this.state.invoices }
                  banks={ this.state.banks }
                  paymentTerms={ this.state.paymentTerms }
                  initialReceiptDetail={ this.state.initialReceiptDetail }
                  handleAutocomplete={ this.handleAutocomplete }
                  handleAutocompleteOnInputChange={ this.handleAutocompleteOnInputChange }
                  handleReceiptDetailsFormInputChange={ this.handleReceiptDetailsFormInputChange }
                  handleSaveReceiptDetails={ this.handleSaveReceiptDetails }
                  handleSwitch={ this.handleSwitch }
                  handleInputChangeReceiptDetailAmount={ this.handleInputChangeReceiptDetailAmount }/>
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeleteReceiptPopup}
                item={this.state.receipt}
                delete={this.handleDeleteReceipt}
                handleDeleteClose={this.handleCloseSimplePopup}
                model={this.state.model}
              />

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeleteReceiptDetailsPopup}
                item={this.state.initialReceiptDetail}
                delete={() => this.handleDeleteReceiptDetails(this.state.initialReceiptDetail, this.state.selectedReceiptDetailsIndex)}
                handleDeleteClose={this.handleCloseSimplePopup}
                model={this.state.model}
              />

              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.openAlertMessage}
                type={this.state.type}
                message={this.state.alertMessage}
              />

              <SimplePopUp
                openPopup={this.state.openEditReceiptPopup}
                title={this.state.popupHeader}
                handleClose={this.handleCloseSimplePopup}
                maxWidth={true}
              >
                <ReceiptForm
                  branches={this.state.branches}
                  clients={this.state.clients}
                  clientsAutoCompleteLoading={this.state.clientsAutoCompleteLoading}
                  branchesAutoCompleteLoading={this.state.branchesAutoCompleteLoading}
                  selectedClient={this.state.selectedClient}
                  initialReceipt={this.state.initialReceipt}
                  receiptDetails={this.state.receiptDetails}
                  initialReceiptDetail={this.state.initialReceiptDetail}
                  handleAutocomplete={this.handleAutocomplete}
                  handleAutocompleteOnInputChange={this.handleAutocompleteOnInputChange}
                  handleReceiptFormInputChange={this.handleReceiptFormInputChange}
                  handleSaveReceipt={this.handleSaveReceipt}
                />
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openPostReceiptJournalPopup}
                title="Post Collection Receipt Journal"
                handleClose={this.handleCloseSimplePopup}
                maxWidth="sm"
              >
                <div>
                  <div>
                    <Typography> Would you like to post this Journal? </Typography>
                  </div>
                  <div align="right"
                       className={styles.actionButton}>
                    <Button color="primary" variant="outlined"
                            onClick={this.handlePostReceiptJournal}
                    >save</Button></div>
                </div>

              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openUnpostReceiptJournalPopup}
                title="Unpost Collection Receipt Journal"
                handleClose={this.handleCloseSimplePopup}
                maxWidth="sm"
              >
                <div>
                  <div>
                    <Typography> Would you like to unpost this Journal? </Typography>
                  </div>
                  <div align="right"
                       className={styles.actionButton}>
                    <Button color="primary" variant="outlined"
                            onClick={this.handleUnpostReceiptJournal}
                    >save</Button></div>
                </div>

              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.openPrintPDFPopup}
                title={"Print Journal Entries"}
                items={this.state.bill}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              > 
                <div>
                <span>
                  <b>Do you want to overwrite signatory person?</b>
                </span>
                <div align="right" className={styles.actionButton}>
                    <div>
                      {printPdf}
                    </div>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.changeSign}
                    >Yes</Button></div>
                </div>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.changeSignPopup}
                title="Change Signatory"
                items={this.state.bill}
                handleClose={this.handleCloseSimplePopup}
                maxWidth={this.state.maxWidth}
              > 
                <form>
                <TextField
                  autoComplete="off"
                  className={styles.textFields}
                  variant="outlined"
                  size="small"
                  name="creator"
                  fullWidth="true"
                  label="Name"
                  onChange={this.handleInputChangePDF}
                  value={this.state.signatory.creator}
                  id="bill-name"
                />
                </form>
                <div align="right" className={styles.actionButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.handleCloseSimplePopup}
                    >Cancel</Button>
                    <div onClick={this.submitChangeSign}>{ChangeSignPrintPdf}</div> 
                </div>
              </SimplePopUp>

              <footer className={this.state.forPrint?null:styles.noDisplay} >
                <div className={styles.signLine}></div>
                <h3 >{this.state.receipt.creator_name}</h3>
              </footer> 
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default ReceiptShow

ReceiptShow.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
}
