import React from 'react'

import PropTypes from 'prop-types'
import styles from '../Invoices.module.css'

import {
  Table, TableHead, TableBody, TableCell, TableRow
} from '@material-ui/core'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {Link} from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {Redirect} from 'react-router'
class PaymentDetailTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      pathItem: {}
    }
  }
  getTotal() {
    var total = 0
    if (this.props.receiptContents.length > 0) {
      this.props.receiptContents.map((content) => {
        total = parseFloat(total) + parseFloat(content.receipt.total_amount)
      })
    }
    return total
  }

  redirectTo(item) {
    this.setState({ pathItem: item, redirect: true })
  }

  render() {
    if (this.state.redirect) {
      if (this.state.pathItem !== null) {
        return (<Redirect
          to={{
              pathname: "/receipts/" + this.state.pathItem.id,
              state: {
                id: this.state.pathItem.id,
                item: this.state.pathItem
              }
            }}
        />)
      }
    }
    return (
      <div>
        <div className={styles.checkDetailsHeader}>
          <div>
            <Tooltip title="Add Payment" arrow>
              <Link name="add_payment_button"
                to={{ pathname: '/invoices/' + this.props.pathname + '/receipts/new' }}>
                <AddCircleOutlineIcon fontSize="medium"/>
              </Link>
            </Tooltip>
          </div>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b> Date of Receipt</b>
              </TableCell>
              <TableCell>
                <b>Receipt Number</b>
              </TableCell>
              <TableCell>
                <b>Terms of Payment</b>
              </TableCell>
              <TableCell>
                <b>Remarks</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell align="right">
                <b>Amount</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.props.receiptContents.sort(function (a, b) {
              if (a.receipt.reference_no < b.receipt.reference_no) return -1;
              if (a.receipt.reference_no > b.receipt.reference_no) return 1;
              return 0;
            }).map((content) => (
              <TableRow key={content.receipt_id} onClick={() => this.redirectTo(content.receipt)} className={styles.tableRowBody}>
                <TableCell>
                  {new Date(content.receipt.date_of_receipt).toLocaleDateString('en-Us', {
                    month: '2-digit',
                    day: '2-digit',
                    year: '2-digit'})}
                </TableCell>

                <TableCell>
                  {content.receipt.reference_no}
                </TableCell>
                <TableCell>
                  {content.payment_term.name}
                </TableCell>
                <TableCell>
                  {content.remarks}
                </TableCell>
                <TableCell>
                  {content.receipt.status}
                </TableCell>
                <TableCell align="right">
                  {parseFloat(content.receipt.total_amount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                </TableCell>

              </TableRow>
            ))}
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="right"><b> Total :</b></TableCell>
              <TableCell
                align="right"><b>{this.getTotal().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </div>
    )
  }
}

export default PaymentDetailTab

PaymentDetailTab.propTypes = {
  classes: PropTypes.object,
  invoice: PropTypes.object,
  pathname: PropTypes.string,
  receiptContents: PropTypes.array,
  activeJournal: PropTypes.func,
  cancelJournal: PropTypes.func,
}
