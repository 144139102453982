// eslint-disable-next-line

import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import { withRouter, Redirect } from 'react-router-dom'
import axios from 'axios'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

import PropTypes from "prop-types";
import { TextField, Button, Grid } from '@material-ui/core'
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import * as CommonFunction from "../../shared/Custom/CommonFunctions"
import styles from './ProductTransaction.module.css'
import AlertMessage from '../../shared/Notify/AlertMessage';
import Cookies from 'js-cookie';
export const Component = withRouter(() => {

})
class ProductTransactions extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      model: 'Product Transactions',
      productTransactions: [],
      cds: [],
      deliveryEmployees: [],
      products: [],
      invoiceCds: [],
      invoiceDeliveries: [],
      invoiceDeliverieDetails: [],
      invoiceProducts: [],
      load: false,
      tableHead: ['date_of_password', 'invoice_no', 'employee.name', 'client.name', 'product.name', 'delivery_no', 'reason', 'status', 'creator_name'],
      searchParams: {},
      initialProductTransaction: {},
      selectedClient: {},
      selectedProductTransactionsIndex: 0,
      totalPages: 1,
      currentPage: 1,
      page: 1,
      withPagination: true,
      clickableTableRow: false,
      isAddProductTransaction: false,
      openSimplePopup: false,
      cdsAutoCompleteLoading: false,
      deliveryDetailsAutoCompleteLoading: false,
      productsAutoCompleteLoading: false,
      isInvoiceProductTransaction: false,
      isDeliveryProductTransaction: false,
      popupHeader: '',
      searchUrl: process.env.REACT_APP_API_DOMAIN + '/v1/product_transactions/search',
      unauthorized: false,
      redirectToHome: false,
      isOpen: false,
      type: '',
      message: ''

    }

    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleOpenSimplePopup = this.handleOpenSimplePopup.bind(this)
    // this.handleFormInputChange = this.handleFormInputChange.bind(this)
    // this.handleAutocomplete = this.handleAutocomplete.bind(this)
    // this.handleSave = this.handleSave.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
  }

  componentDidMount () {
    if(Cookies.get('current_user')) {
      if(!JSON.parse(Cookies.get('current_user')).is_from_cdasia) {
        this.setState({ unauthorized: true, isOpen: true, type: 'error', message: 'You are not authorized to access this page.' })
      }
    else{
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    const params = new URLSearchParams(this.props.location.search)

    // This is to load all params based on URLSearchParams
    params.forEach(function(value, key) {
      if (key !== 'page') {
        searchParams[key] = value
      }
    })

    this.setState({ searchParams: searchParams })
    this.loadProductTransactions(searchUrl)
      }
    }
  }

  loadProductTransactions(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ productTransactions: resp.data.product_transactions, totalPages: resp.data.meta.total_pages, currentPage: resp.data.meta.current_page, load: true })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadProductTransactions([this.state.searchUrl, searchParams].join("?"), false)
  }

  handleSearchInputChange(e) {
    this.setState({ searchParams: { ...this.state.searchParams, [e.target.name]: e.target.value } })
  }

  handleSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadProductTransactions([this.state.searchUrl, searchParams].join("?"))
  }

  clearSearch() {
    const { history } = this.props
    history.push({ search: '' })
    this.setState({ searchParams: {} })
    this.loadProductTransactions(this.state.searchUrl)
  }

  getSearchParams() {
    var searchParams = []

    // This is to load all params based on searchParams state.
    Object.entries(this.state.searchParams).map(([key, value]) => {
      if (value != '' && value != undefined && value != null) {
        searchParams.push([key, value].join("="))
      }
    })

    return searchParams
  }

  handleOpenSimplePopup(item, isAdd, targetIndex = 0) {
    var popupHeader = ''
    isAdd ? (popupHeader = 'Add Product Given Password') : (popupHeader = 'Edit Product Given Password')
    this.setState({ openSimplePopup: true, initialProductTransaction: item, isAddProductTransaction: isAdd,
                    popupHeader: popupHeader, selectedProductTransactionsIndex: parseInt(targetIndex) })
  }

  handleCloseAlert(event, reason) {
    this.setState({ redirectToHome: true })
    if (reason === 'clickaway') {
      return;
    }
    
  }

  render () {
    if(this.state.redirectToHome){
      return(
        <Redirect to={{'pathname':'/'}}/>  
      )
    }
    return (
      <>
        {
          this.state.load ? (
            <div>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <TextField
                    autoComplete="off"
                    className={styles.textFields}
                    type="date"
                    variant="outlined"
                    size="small"
                    name="date_of_password_from"
                    fullWidth={true}
                    label="Start Date"
                    onChange={this.handleSearchInputChange}
                    value={this.state.searchParams.date_of_password_from}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    autoComplete="off"
                    className={styles.textFields}
                    variant="outlined"
                    type="date"
                    size="small"
                    name="date_of_password_to"
                    fullWidth={true}
                    label="End Date"
                    onChange={this.handleSearchInputChange}
                    value={this.state.searchParams.date_of_password_to}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    autoComplete="off"
                    className={styles.textFields}
                    variant="outlined"
                    size="small"
                    name="invoice_no"
                    fullWidth={true}
                    label="Invoice Number"
                    onChange={this.handleSearchInputChange}
                    value={this.state.searchParams.invoice_no}
                    id="invoice_no"
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    autoComplete="off"
                    className={styles.textFields}
                    variant="outlined"
                    size="small"
                    name="delivery_no"
                    fullWidth={true}
                    label="Delivery Number"
                    onChange={this.handleSearchInputChange}
                    value={this.state.searchParams.delivery_no}
                    id="delivery_no"
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    autoComplete="off"
                    className={styles.textFields}
                    variant="outlined"
                    size="small"
                    name="employee_name"
                    fullWidth={true}
                    label="FCSR"
                    onChange={this.handleSearchInputChange}
                    value={this.state.searchParams.employee_name}
                    id="employee_name"
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    autoComplete="off"
                    className={styles.textFields}
                    variant="outlined"
                    size="small"
                    name="client_name"
                    fullWidth={true}
                    label="Client Name"
                    onChange={this.handleSearchInputChange}
                    value={this.state.searchParams.client_name}
                    id="client_name"
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    autoComplete="off"
                    className={styles.textFields}
                    variant="outlined"
                    size="small"
                    name="product_name"
                    fullWidth={true}
                    label="Product Name"
                    onChange={this.handleSearchInputChange}
                    value={this.state.searchParams.product_name}
                    id="product_name"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    autoComplete="off"
                    className={styles.textFields}
                    variant="outlined"
                    size="small"
                    name="reason"
                    fullWidth={true}
                    label="Reason"
                    onChange={this.handleSearchInputChange}
                    value={this.state.searchParams.reason}
                    id="reason"
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    autoComplete="off"
                    className={styles.textFields}
                    variant="outlined"
                    size="small"
                    name="status"
                    fullWidth={true}
                    label="Status"
                    onChange={this.handleSearchInputChange}
                    value={this.state.searchParams.status}
                    id="status"
                  />
                </Grid>
              </Grid>

              <div className={styles.actionButton}>
                <Button variant="outlined" id="clear_search" color="secondary" onClick={() => this.clearSearch()} >Clear</Button>
                <Button variant="outlined" id="search_button" color="primary" onClick={() => this.handleSearch()} >Search</Button>
              </div>

              <SimpleTable
                items={this.state.productTransactions}
                initialItem={this.state.initialProductTransaction}
                model={this.state.model}
                headers={this.state.tableHead}
                icon={<LocalShippingOutlinedIcon fontSize="large" />}
                currentPage={this.state.currentPage}
                totalPages={this.state.totalPages}
                handlePageChange={this.handlePageChange}
                withPagination={this.state.withPagination}
                headerTitle="Product Fiven Password"
                createUsingSimplePopup={true}
                handleClick={this.handleOpenSimplePopup}
              />
            </div>

          ) : (
            this.state.unauthorized?
            <AlertMessage
            isOpen={this.state.isOpen}
              handleCloseAlert={this.handleCloseAlert}
              message={this.state.message}
              type={this.state.type}
            />
            :
            <ProgressBar model={this.state.model}/>
          )
        }
      </>
    )
  }
}

export default withRouter(ProductTransactions)

ProductTransactions.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
