import React from 'react'
import moment from 'moment';
import 'moment-timezone';

import PropTypes from 'prop-types'
import styles from '../Invoices.module.css'

import { Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

class AttachmentsTab extends React.Component {
  render() {
    return (
      <div>
        <div className={styles.checkDetailsHeader}>
          <div>
            <Tooltip title="Add Attachment" arrow>
            <IconButton
              name="add_attachment_button"
              onClick={this.props.openAttachmentForm}
            ><AddCircleOutlineIcon/></IconButton>
            </Tooltip>
          </div>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Description</b>
              </TableCell>
              <TableCell>
                <b>File Name</b>
              </TableCell>
              <TableCell>
                <b>Uploaded by</b>
              </TableCell>
              <TableCell>
                <b>Date Uploaded</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.props.attachments.map((attachment) => (
              <TableRow key={attachment.id}>
                <TableCell>
                  {attachment.description}
                </TableCell>
                <TableCell>
                  <a href={attachment.file_url} target="_blank" rel="noopener noreferrer" download>
                  {attachment.file_name}
                  </a>
                </TableCell>
                <TableCell>
                  {attachment.uploader.email}
                </TableCell>
                <TableCell>
                  { moment(attachment.created_at).format("MM/DD/yyyy") }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </div>
    )
  }
}

export default AttachmentsTab

AttachmentsTab.propTypes = {
  attachments: PropTypes.array,
  openAttachmentForm: PropTypes.func
}
