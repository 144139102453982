import React from 'react'

import axios from 'axios'

import PropTypes from 'prop-types'
import SimplePopUp from '../../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import NestedForm from '../NestedForm'
import {TableRow, TableCell} from "@material-ui/core";
import styles from "../Clients.module.css";

class ShowContactAttributes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contactPersonEmails: [],
      contactPersonNumbers: [],
      initialNested: {id: '', content: '', category: 0},
      nestedItem: {},
      openNestedForm: false,
      openDeletePopup: false,
      nestedTitle: '',
      options: [],
      nestedModel: '',
      isCompany: true


    }
    this.loadAttributes = this.loadAttributes.bind(this)
    this.handleCreateOrUpdateNested = this.handleCreateOrUpdateNested.bind(this)
    this.handleNestedChange = this.handleNestedChange.bind(this)
    this.submitNested = this.submitNested.bind(this)
    this.handleOpenDelete = this.handleOpenDelete.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
  }

  componentDidMount() {
    this.loadAttributes(this.props.id)
  }

  handleCreateOrUpdateNested(title, model, item) {
    this.setState({nestedTitle: title})
    this.setState({nestedItem: item})
    this.setState({nestedModel: model})
    this.setState({openNestedForm: true})
    if (model.toLowerCase() === 'address') {
      this.setState({options: ["Current Address", "Permanent Address"]})
    } else if (model.toLowerCase() === 'contact number') {
      this.setState({options: ["Primary", "Home Phone Number", "Phone Number", "Mobile Number", "Fax Number", "Others"]})
    } else {
      this.setState({options: ["Personal", "Work", "Others"]})
    }

  }

  handleNestedChange(e) {
    this.setState({
      nestedItem: {
        ...this.state.nestedItem,
        [e.target.name]: e.target.value
      }
    })
  }

  submitNested() {
    var method = ''
    var url = ''
    var item = this.state.nestedItem
    if (this.state.nestedModel.toLowerCase() === 'contact number') {
      if (item.id === '') {
        method = 'post'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + this.props.id + '/contact_numbers'
      } else {
        method = 'put'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + this.props.id + '/contact_numbers/' + item.id
      }
      axios({
        method: method,
        url: url,
        data: (item),
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {

        const newData = resp.data
        this.setState({
          contactPersonNumbers: this.state.contactPersonNumbers.filter(contactPersonNumber => contactPersonNumber.id !== item.id)
        })
        this.setState({contactPersonNumbers: [...this.state.contactPersonNumbers, newData]})
        this.setState({openNestedForm: false})
      }).catch(error => console.log(error.response))
    } else {
      if (item.id === '') {
        method = 'post'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + this.props.id + '/email_addresses'
      } else {
        method = 'put'
        url = process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + this.props.id + '/email_addresses/' + item.id
      }
      axios({
        method: method,
        url: url,
        data: (item),
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {

        const newData = resp.data
        this.setState({
          contactPersonEmails: this.state.contactPersonEmails.filter(contactPersonEmail => contactPersonEmail.id !== item.id)
        })
        this.setState({contactPersonEmails: [...this.state.contactPersonEmails, newData]})
        this.setState({openNestedForm: false})
      }).catch(error => console.log(error.response))
    }

  }


  loadAttributes(id) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + id + '/email_addresses',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(async resp => {
      this.setState({contactPersonEmails: resp.data})
      const resp_1 = await axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + id + '/contact_numbers',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      })
      this.setState({contactPersonNumbers: resp_1.data})
    })
  }


  handleClose() {
    this.setState({openNestedForm: false})
    this.setState({openDeletePopup: false})
  }

  handleOpenDelete(model, item) {
    this.setState({nestedItem: item})
    this.setState({nestedModel: model})
    this.setState({openDeletePopup: true})
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.nestedItem.id
    if (this.state.nestedModel.toLowerCase() === 'contact number') {

      axios({
        method: 'delete',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + this.props.id + '/contact_numbers/' + DeleteItemId,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(() => {
        this.setState({
          contactPersonNumbers: this.state.contactPersonNumbers.filter(contactPersonNumber => contactPersonNumber.id !== DeleteItemId)
        })
        this.setState({openDeletePopup: false})
      }).catch(error => console.log(error.response))

    } else {
      axios({
        method: 'delete',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/contact_persons/' + this.props.id + '/email_addresses/' + DeleteItemId,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(() => {
        this.setState({
          contactPersonEmails: this.state.contactPersonEmails.filter(contactPersonEmail => contactPersonEmail.id !== DeleteItemId)
        })
        this.setState({openDeletePopup: false})
      }).catch(error => console.log(error.response))
    }
  }

  render() {
    return (
      <>
        <TableRow>
          <TableCell className={styles.cellWidth}>
            {this.state.contactPersonNumbers.length > 0 ? (
              this.state.contactPersonNumbers.filter((filter) => filter.category_name == 'primary')
                .map((contactNumber) => (
                  <div key={contactNumber.id}>
                    {contactNumber.content}
                  </div>
                ))) : null}
          </TableCell>

          <TableCell className={styles.emailCellWidth}>
            {this.state.contactPersonEmails.length > 0 ? (
              this.state.contactPersonEmails.filter((filter) => filter.category_name == 'primary')
                .map((email) => (
                  <div key={email.id} className={styles.emailCell}>
                    {email.content}
                  </div>
                ))) : null}
          </TableCell>
        </TableRow>
        <div className="contactPersonAddMail">
        </div>
        <SimplePopUp
          openPopup={this.state.openNestedForm}
          title={this.state.nestedTitle}
          handleClose={this.handleClose}
          maxWidth='sm'
        >
          <NestedForm handleSubmit={this.submitNested} handleChange={this.handleNestedChange}
                      item={this.state.nestedItem} options={this.state.options} nestedModel={this.state.nestedModel}/>
        </SimplePopUp>
        <SimpleDeletePopUp
          openDeletePopup={this.state.openDeletePopup}
          item={this.state.nestedItem}
          delete={this.handleDeleteItem}
          handleDeleteClose={this.handleClose}
          model={this.state.nestedModel}
        />
      </>
    )
  }
}

export default ShowContactAttributes
ShowContactAttributes.propTypes = {
  id: PropTypes.string,
  contactNumbers: PropTypes.array,
  emailAddresses: PropTypes.array,
  contactName: PropTypes.object,
  primaryOnly: PropTypes.bool
}
