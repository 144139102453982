import React from 'react'
import styles from './Delivery.module.css'

import PropTypes from 'prop-types'

import { Button, FormControlLabel, TextField, MenuItem, Switch } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CloseIcon from '@material-ui/icons/Close'

class DeliveryDetailsForm extends React.Component {
  render() {
    return(
      <>
        {
          <div>
            <form>
              <div className={styles.groupTextfield}>
                <TextField
                  autoComplete="off"
                  select
                  required
                  className={styles.textFields}
                  variant="outlined"
                  size="small"
                  name="purpose"
                  fullWidth={true}
                  label="Purpose"
                  value={this.props.initialDeliveryDetail.purpose}
                  id="purpose"
                  onChange={this.props.handleDeliveryDetailsFormInputChange}
                >
                  <MenuItem value="" > Please Select </MenuItem>
                  <MenuItem value="Sale">Sale</MenuItem>
                  <MenuItem value="Complimentary">Complimentary</MenuItem>
                  <MenuItem value="Integration/Update">Integration</MenuItem>
                </TextField>

                {
                  this.props.initialDeliveryDetail.purpose !== "Complimentary" ? (
                    <div className={styles.autoCompleteField}>
                      <Autocomplete
                        name="invoice_id"
                        id="invoice_id"
                        key="invoice"
                        size="small"
                        loading={this.props.invoicesAutoCompleteLoading}
                        options={this.props.invoices}
                        getOptionLabel={(option) => option.reference_no || ''}
                        getOptionSelected={(option, value) => option.reference_no === value.reference_no}
                        onChange={this.props.handleAutocomplete}
                        onInputChange={(event) => this.props.handleAutocompleteOnInputChange(event)}
                        fullWidth={true}
                        value={this.props.initialDeliveryDetail.invoice || {}}
                        closeIcon={<CloseIcon fontSize="small" id="clear_invoice_autocomplete" />}
                        renderInput={(params) => <TextField {...params} label="Invoice Number" variant="outlined" required />}
                      />
                    </div>
                  ) : (null)
                }

                <div className={styles.autoCompleteField}>
                  <Autocomplete
                    name="cd_id"
                    id="cd_id"
                    key="cd"
                    size="small"
                    loading={this.props.productsAutoCompleteLoading}
                    options={this.props.products}
                    getOptionLabel={(option) => option.description || ''}
                    getOptionSelected={(option, value) => option.description === value.description}
                    onChange={this.props.handleAutocomplete}
                    onInputChange={(event) => this.props.handleAutocompleteOnInputChange(event)}
                    fullWidth={true}
                    value={this.props.initialDeliveryDetail.cd}
                    closeIcon={<CloseIcon fontSize="small" id="clear_cd_autocomplete" />}
                    renderInput={(params) => <TextField {...params} label="Product Category Description" variant="outlined" required />}
                  />
                </div>
              </div>

              <div className={styles.groupTextfield}>
                <TextField
                  autoComplete="off"
                  className={styles.textFields}
                  fullWidth
                  variant="outlined" required
                  name="quantity"
                  label="Quantity"
                  id="quantity"
                  size="small"
                  type="number"
                  value={this.props.initialDeliveryDetail.quantity}
                  onChange={this.props.handleDeliveryDetailsFormInputChange}
                  inputProps={{ shrink: true }}
                />

                <TextField
                  autoComplete="off"
                  select
                  className={styles.textFields}
                  variant="outlined"
                  size="small"
                  name="media_type"
                  fullWidth={true}
                  label="Media Type"
                  value={this.props.initialDeliveryDetail.media_type || ''}
                  id="media_type"
                  onChange={this.props.handleDeliveryDetailsFormInputChange}
                >
                  <MenuItem value="" > Please Select </MenuItem>
                  <MenuItem value="Compact Disc">Compact Disc</MenuItem>
                  <MenuItem value="Virtual Machine">Virtual Machine</MenuItem>
                  <MenuItem value="Network License">Network License</MenuItem>
                </TextField>

                <div>
                  <FormControlLabel
                    control={<Switch
                      size="medium"
                      checked={this.props.initialDeliveryDetail.is_virtual}
                      onChange={this.props.handleSwitch}
                      id="is_virtual"
                      name="is_virtual" />}
                    label="Is Pulled Out ?"
                  />
                </div>
              </div>

              <div className={styles.groupTextfield}>
                <TextField
                  autoComplete="off"
                  variant="outlined"
                  className={styles.textFields}
                  multiline={true}
                  size="small"
                  required
                  name="remarks"
                  id="remarks"
                  fullWidth={true}
                  label="Remarks"
                  value={this.props.initialDeliveryDetail.remarks}
                  onChange={this.props.handleDeliveryDetailsFormInputChange}
                  InputProps={{
                    shrink: true
                  }}
                />
              </div>
            </form>

            <div className={styles.actionButton}>
              <Button 
              disabled={!this.props.initialDeliveryDetail.purpose || !this.props.initialDeliveryDetail.cd || !this.props.initialDeliveryDetail.quantity || !this.props.initialDeliveryDetail.remarks}
              variant="outlined" id="save-delivery-detail" color="primary" onClick={() => this.props.handleSaveDeliveryDetails(this.props.initialDeliveryDetail)} >Save</Button>
            </div>
          </div>
        }
      </>
    )
  }
}
export default DeliveryDetailsForm

DeliveryDetailsForm.propTypes = {
  invoicesAutoCompleteLoading: PropTypes.bool,
  productsAutoCompleteLoading: PropTypes.bool,
  invoices: PropTypes.array,
  products: PropTypes.array,
  initialDeliveryDetail: PropTypes.object,
  handleAutocomplete: PropTypes.func,
  handleAutocompleteOnInputChange: PropTypes.func,
  handleDeliveryDetailsFormInputChange: PropTypes.func,
  handleSaveDeliveryDetails: PropTypes.func,
  handleSwitch: PropTypes.func
}
