import React from 'react'

import PropTypes from 'prop-types'

import { Button, TextField } from '@material-ui/core'
import styles from "./../Clients/Clients.module.css";
import style from './Invoices.module.css'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';

class AttachmentForm extends React.Component {
  render() {
    const { file } = this.props.attachment;
    let filename = null;
    filename = file
      ? (<span> <b>File Selected</b> - { file.name } </span>)
      : (<span> Drag your files here or click in this area. </span>);

    return (
      <div>
        <form>
          <div className={styles.formAttachBody}>
            <input
              id="file"
              type="file"
              name="file"
              accept="application/pdf, application/vnd.ms-excel"
              className={styles.input}
              onChange={(e) => this.props.onFileChange(e)}
            />
            <label
              className={styles.filelabel}
              htmlFor="file">
              <VerticalAlignBottomIcon fontSize="large"/>
              <br/>
              {filename}
            </label>

            <TextField
              autoComplete="off"
              variant="outlined"
              className={styles.textFields}
              size="small"
              name="description"
              fullWidth
              label="Description"
              onChange={this.props.onchange}
              value={this.props.attachment.description}
              InputLabelProps={{
                shrink: true
              }}
            />
            <p className={styles.fileError}><b>{this.props.error}</b></p>
          </div>
        </form>
        <div className={style.actionAttactmentButton}>
          <Button variant="outlined" color="primary" onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default AttachmentForm

AttachmentForm.propTypes = {
  onFileChange: PropTypes.func,
  attachment: PropTypes.object,
  submit: PropTypes.func,
  error: PropTypes.func,
  onchange: PropTypes.func
}
