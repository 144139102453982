import React from 'react'

import PropTypes from 'prop-types'
import styles from './../Invoices/Invoices.module.css'

import {
  Table, TableHead, TableBody, TableCell, TableRow
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import moment from 'moment';
import 'moment-timezone';

class ProductTransactionsTab extends React.Component {
  render() {
    return (
      <div>
        <div className={styles.checkDetailsHeader}>
          <div>
            <Tooltip title="Add Product Given Password" arrow>
              <IconButton
                variant="addProductTransaction"
                name="add_product_transaction"
                color="inherit"
                onClick={() => this.props.handleOpenProductTransactionSimplePopup(this.props.initialProductTransaction, true)} >
                <AddCircleOutlineIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
               <b> Date Given</b>
              </TableCell>
              <TableCell>
                <b>Invoice Reference Number</b>
              </TableCell>
              <TableCell>
                <b>FCSR</b>
              </TableCell>
              <TableCell>
                <b>Product Category Description</b>
              </TableCell>
              <TableCell>
                <b>Delivery Reference Number</b>
              </TableCell>
              <TableCell>
                <b>Reason</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>ICSR</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              this.props.productTransactions.map((item) => (
                <TableRow key={item.id}>
                  <TableCell> { moment(item.date_of_password).format("MM/DD/YYYY") } </TableCell>

                  <TableCell> 
                    {
                      item.invoice_id ? (
                        <a href={`/invoices/${item.invoice_id}`} target="_blank" rel="noopener noreferrer">
                          { item.invoice_no ? (item.invoice_no) : ("n/a")}
                        </a>
                      ) : (item.invoice_no)
                    }
                  </TableCell>

                  <TableCell> { item.employee_name } </TableCell>
                  <TableCell> { item.cd.description } </TableCell>

                  <TableCell> 
                    {
                      item.delivery_id ? (
                        <a href={`/deliveries/${item.delivery_id}`} target="_blank" rel="noopener noreferrer">
                          { item.delivery_no ? (item.delivery_no) : ("n/a")}
                        </a>
                      ) : (item.delivery_no)
                    }
                  </TableCell>

                  <TableCell> { item.reason } </TableCell>
                  <TableCell> { item.status } </TableCell>
                  <TableCell> { item.creator_name } </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default ProductTransactionsTab

ProductTransactionsTab.propTypes = {
  productTransactions: PropTypes.array,
  initialProductTransaction: PropTypes.object,
  handleOpenProductTransactionSimplePopup: PropTypes.func
}
