import React from 'react'
import PropTypes from 'prop-types'
import { Button, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CloseIcon from '@material-ui/icons/Close'
import styles from './ProductTransaction.module.css'

class InvoiceProductTransactionForm extends React.Component {
  render() {
    return (
      <>
        {
          <div>
            <form>
              <div className={styles.groupTextfield}>
                <div className={styles.addressBody}>
                  <div>
                    Option :
                  </div>
                  <div>
                    { this.props.initialProductTransaction.option }
                  </div>
                </div>

                <div className={styles.addressBody}>
                  <div className={styles.referenceNumber}>
                    Invoice Number :
                  </div>
                  <div>
                    { this.props.initialProductTransaction.invoice_no }
                  </div>
                </div>
              </div>

              <div className={styles.groupTextfield}>
                <div className={styles.autoCompleteField}>
                  <Autocomplete
                    name="delivery_id"
                    id="delivery_id"
                    key="delivery"
                    size="small"
                    disabled={this.props.deliveries.length < 1}
                    options={this.props.deliveries }
                    getOptionLabel={(option) => option ? option.reference_no : ''}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={this.props.handleAutocomplete}
                    fullWidth={true}
                    value={this.props.initialProductTransaction.delivery}
                    closeIcon={<CloseIcon fontSize="small" id="clear_delivery_autocomplete" />}
                    renderInput={(params) => <TextField {...params} label="Delivery Number" variant="outlined" />}
                  />
                </div>

                <div className={styles.autoCompleteField}>
                  <Autocomplete
                    name="cd_id"
                    id="cd_id"
                    key="cd"
                    size="small"
                    options={this.props.cds}
                    getOptionLabel={(option) => option ? option.description : ''}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={this.props.handleAutocomplete}
                    fullWidth={true}
                    value={this.props.initialProductTransaction.cd}
                    closeIcon={<CloseIcon fontSize="small" id="clear_cd_autocomplete" />}
                    renderInput={(params) => <TextField {...params} label="Product Category Description" variant="outlined" required />}
                  />
                </div>

                <div className={styles.autoCompleteField}>
                  <Autocomplete
                    name="product_id"
                    id="product_id"
                    key="product"
                    size="small"
                    options={this.props.products}
                    getOptionLabel={(option) => option ? option.name : ''}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={this.props.handleAutocomplete}
                    fullWidth={true}
                    value={this.props.initialProductTransaction.product}
                    closeIcon={<CloseIcon fontSize="small" id="clear_product_autocomplete" />}
                    renderInput={(params) => <TextField {...params} label="Product" variant="outlined" required />}
                  />
                </div>
              </div>

              <div className={styles.groupTextfield}>
                <div className={styles.addressBody}>
                  <div>
                    Client Name :
                  </div>
                  <div>
                    { this.props.client.name }
                  </div>
                </div>

                <div className={styles.addressBody}>
                  <div>
                    Address :
                  </div>
                  <div>
                    { this.props.client.company_address || this.props.client.home_address }
                  </div>
                </div>
              </div>

              <div className={styles.groupTextfield}>
                <div className={styles.autoCompleteField}>
                  <Autocomplete
                    name="employee_id"
                    id="employee_id"
                    key="employee"
                    size="small"
                    options={(this.props.deliveryEmployees).sort(function (a, b) {
                      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                      return 0;
                    })}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option, value) => option.name === value.name}
                    onChange={this.props.handleAutocomplete}
                    fullWidth={true}
                    value={this.props.initialProductTransaction.employee}
                    closeIcon={<CloseIcon fontSize="small" id="clear_employee_autocomplete" />}
                    renderInput={(params) => <TextField {...params} label="FSCR" variant="outlined" required />}
                  />
                </div>

                <TextField
                  autoComplete="off"
                  className={styles.textFields}
                  type="date"
                  variant="outlined"
                  size="small"
                  required
                  name="date_of_password"
                  fullWidth={true}
                  label="Date Password Given"
                  onChange={this.props.handleInputChange}
                  value={this.props.initialProductTransaction.date_of_password}
                  id="date_of_password"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>

              <div className={styles.groupTextfield}>
                <TextField
                  autoComplete="off"
                  variant="outlined"
                  className={styles.textFields}
                  multiline={true}
                  size="sm"
                  required
                  name="reason"
                  id="reason"
                  fullWidth={true}
                  label="Reason"
                  onChange={this.props.handleInputChange}
                  value={this.props.initialProductTransaction.reason}
                  InputProps={{
                    shrink: true
                  }}
                />

                <TextField
                  autoComplete="off"
                  variant="outlined"
                  className={styles.textFields}
                  multiline={true}
                  size="sm"
                  required
                  name="status"
                  id="status"
                  fullWidth={true}
                  label="Status"
                  onChange={this.props.handleInputChange}
                  value={this.props.initialProductTransaction.status}
                  InputProps={{
                    shrink: true
                  }}
                />
              </div>

            <div className={styles.actionButton}>
              <Button variant="outlined" id="product_transaction_submit" color="primary" onClick={this.props.handleSaveProductTransaction}>Save</Button>
            </div>
            </form>
          </div>
        }
      </>
    )
  }
}
export default InvoiceProductTransactionForm

InvoiceProductTransactionForm.propTypes = {
  client: PropTypes.object,
  initialProductTransaction: PropTypes.object,
  cds: PropTypes.array,
  deliveries: PropTypes.array,
  deliveryEmployees: PropTypes.array,
  products: PropTypes.array,
  handleAutocomplete: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleSaveProductTransaction: PropTypes.func
}