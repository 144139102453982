// eslint-disable-next-line


import React from 'react'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import axios from 'axios'
import Profile from "./Profile";
import ProfileForm from "./ProfileForm";
import AvatarForm from "./AvatarForm";
import AlertMessage from "../../shared/Notify/AlertMessage";
import Cookies from 'js-cookie';

class UpdateProfile extends React.Component {
  constructor (props) {
    const currentUser = JSON.parse(Cookies.get("current_user"))
    super(props)
    this.state = {
      model: 'Profile',
      profile: {id: currentUser.id, name:  currentUser.name, email: currentUser.email},
      openEditProfile: false,
      openEditAvatar: false

    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.openEditProfile = this.openEditProfile.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSubmitAvatar = this.handleSubmitAvatar.bind(this)
    this.openEditAvatar = this.openEditAvatar.bind(this)

  }

  componentDidMount () {
    this.setState({ load: true })
  }

  handleSubmit() {
    const item = this.state.profile
    axios({
      method: 'put',
      url:  process.env.REACT_APP_API_DOMAIN + '/v1/users/' + item.id,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ profile: resp.data })
      Cookies.set("current_user", JSON.stringify({ name:  resp.data.name, email:  resp.data.email, id:  resp.data.id}))
      this.setState({ openEditProfile: false, isOpen: true,
        message: 'Submitted Successfully',
        type: 'success' })
    })
  }

  handleSubmitAvatar() {
    const item = this.state.profile
    const avatar = item.avatar
    const formdata = new FormData()
    formdata.append('avatar', avatar)
    axios({
      method: 'put',
      url:  process.env.REACT_APP_API_DOMAIN + '/v1/users/' + item.id,
      data: (formdata),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ profile: resp.data })
      this.setState({ openEditAvatar: false, isOpen: true,
        message: 'Submitted Successfully',
        type: 'success' })
    })
  }

  handleInputChange(e) {
    this.setState({ profile: { ...this.state.profile, [e.target.name]: e.target.value }} )}

  onFileChange(e) {
    this.setState({ profile: { ...this.state.profile,  avatar: e.target.files[0]} } )}

  handleCloseAlert (event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  openEditProfile() {
    this.setState({openEditProfile: true})
  }

  openEditAvatar() {
    this.setState({openEditAvatar: true})
  }

  handleClose () {
    this.setState({ openEditProfile: false, openEditAvatar: false})
  }

  render () {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <Profile
                profile={this.state.profile}
                openEditProfile={this.openEditProfile}
                openEditAvatar={this.openEditAvatar}
              />

              <SimplePopUp
                openPopup={this.state.openEditProfile}
                title="Edit Profile"
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
              <ProfileForm
                submit={this.handleSubmit}
                item={this.state.profile}
                onchange={this.handleInputChange}
              />
              </SimplePopUp>
              <SimplePopUp
                openPopup={this.state.openEditAvatar}
                title="Edit Profile Picture"
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <AvatarForm
                  handleSubmitAvatar={this.handleSubmitAvatar}
                  item={this.state.profile}
                  onchange={this.handleInputChange}
                  onFileChange={this.onFileChange}
                />
              </SimplePopUp>
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : null}
      </>
    )
  }
}

export default UpdateProfile

