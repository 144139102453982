import React from 'react'

import PropTypes from 'prop-types'


import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Grid } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import AddBoxIcon from '@material-ui/icons/AddBox'

import EmailAddress from '../EmailAddress/EmailAddress'
import ContactNumber from '../ContactNumber/ContactNumber'
import styles from '../Clients.module.css'

class ContactPersonForm extends React.Component {


render(){
    return(
        <>
      <form style={{ display: 'flex' }} >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              autoComplete="off"
              size="small"
              style={{ width: '100%' }}
              className={styles.textfield}
              variant="outlined"
              name="name"
              value={this.props.contactPerson.name}
              onChange={this.props.handleChange}
              label="Name"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoComplete="off"
              size="small"
              style={{ width: '100%' }}
              className={styles.textfield}
              variant="outlined"
              name="designation"
              value={this.props.contactPerson.designation}
              onChange={this.props.handleChange}
              label="Designation"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoComplete="off"
              style={{ width: '100%' }}
              size="small"
              className={styles.textfield}
              variant="outlined"
              name="department"
              value={this.props.contactPerson.department}
              onChange={this.props.handleChange}
              label="Department"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              autoComplete="off"
              style={{ width: '100%' }}
              size="small"
              className={styles.textfield}
              variant="outlined"
              name="remarks"
              value={this.props.contactPerson.remarks}
              onChange={this.props.handleChange}
              label="Remarks"
            />
          </Grid>
        </Grid>
      </form>
      {
        this.props.contactState?(
          <>
        {
        this.props.emailAddresses!==undefined?(
          <div elevation={0}>
            <div className={styles.contactHeader}>
              <h4 className={styles.setLabelWidth}>Email Address</h4>
              <IconButton
                name="add-emailAddress"
                color="primary"
                variant="outlined"
                onClick={this.props.addEmailAddress}>
                <AddBoxIcon />
              </IconButton>
            </div>
            {
              this.props.emailAddresses.map((val, idx) => (
                <EmailAddress
                  key={`content-${idx}`}
                  idx={idx}
                  emailAddresses={this.props.emailAddresses}
                  handleEmailAddressChange={this.props.handleEmailAddressChange}
                  removeEmail={this.props.removeEmail}
                />
              ))
            }
          </div>
             ):(null)
            }
            {
              this.props.contactNumbers!==undefined?(
            <div elevation={0}>
            <div className={styles.contactHeader}>
              <h4 className={styles.setLabelWidth}>Contact Number</h4>
              <IconButton
                name="add-contactNumber"
                color="primary"
                variant="outlined"
                onClick={this.props.addContactNumber}>
                <AddBoxIcon />
              </IconButton>
            </div>
            {
              this.props.contactNumbers.map((val, idx) => (
                <ContactNumber
                  key={`content-${idx}`}
                  idx={idx}
                  contactNumbers={this.props.contactNumbers}
                  handleContactNumberChange={this.props.handleContactNumberChange}
                  handleNumberChange={this.props.handleNumberChange}
                  removeContactNumber={this.props.removeContactNumber}
                />
              ))

            }
          </div>
            ):(null)
          }
          </>
          ):(null)
      }
      <div className={styles.actionButton}>
        <Button variant="outlined" name="submit" onClick={this.props.submitContact} id="contact-submit" color="primary" >Save</Button>
      </div>
    </>
    )
}

}
export default ContactPersonForm

ContactPersonForm.propTypes={
    contactPerson: PropTypes.object,
    contactNumbers:PropTypes.array,
    emailAddresses: PropTypes.array,
    handleContactNumberChange: PropTypes.func,
    removeContactNumber: PropTypes.func,
    handleNumberChange: PropTypes.func,
    addContactNumber: PropTypes.func,
    addEmailAddress: PropTypes.func,
    handleEmailAddressChange: PropTypes.func,
    removeEmail: PropTypes.func,
    submitContact: PropTypes.func,
    contactState: PropTypes.bool,
    handleChange: PropTypes.func

}
