// eslint-disable-next-line
import React from 'react'

import AmpStoriesIcon from '@material-ui/icons/AmpStories'

import axios from 'axios'

import PropTypes from 'prop-types'

import ProgressBar from "../../ProgressBar/ProgressBar";
import { withRouter } from 'react-router-dom'
import IncomeSearchForm from './IncomeSearchForm'
import IncomeStatementTable from './IncomeStatementTable'
import AlertMessage from '../../Notify/AlertMessage'
import ReactToPrint from 'react-to-print';
import { IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print'
export const Component = withRouter(() => {

})

class ConsolidatedIncomeStatements extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            model: 'Consolidated Income Statement',
            incomeStatements: [],
            account: { months: [],is_posted: 'true' },
            title: '',
            load: false,
            tableHead: ['name', 'code', 'name type', 'sales', 'provision for income tax', 'income_special_projects', 'gross_profit'],
            branch: {},
            branches: [],
            withPagination: true,
            currentPage: 1,
            totalPages: 1,
            months: [
                { value: 1, name: "January" },
                { value: 2, name: "February" },
                { value: 3, name: "March" },
                { value: 4, name: "April" },
                { value: 5, name: "May" },
                { value: 6, name: "June" },
                { value: 7, name: "July" },
                { value: 8, name: "August" },
                { value: 9, name: "September" },
                { value: 10, name: "October" },
                { value: 11, name: "November" },
                { value: 12, name: "December" }
            ],
            urlPrint: {},
            searchUrl: process.env.REACT_APP_API_DOMAIN + '/v1/accounts/consolidated_income_statement',
            isOpen: false,
            message: '',
            type: '',
            forPrint: false
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleAutoComplete = this.handleAutoComplete.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.changePrintLayout = this.changePrintLayout.bind(this)
    }

    componentDidMount() {
        var searchUrl = this.state.searchUrl + this.props.location.search
        var searchParams = {}
        const query = new URLSearchParams(this.props.location.search)
        query.forEach(function (value, key) {
            if (key !== 'months') {
                if (key === 'code') {
                    searchParams[key] = value.replace(/\*/g, '')
                } else {
                    searchParams[key] = value
                }
            }
        })
        if (query.get('branch_id') !== undefined && query.get('branch_id') !== null && query.get('branch_id') !== '') {
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + query.get('branch_id'),
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                searchParams["branch"] = resp.data
            })
        }
        if (query.get('months') !== undefined && query.get('months') !== null) {
            var months = query.get('months').split(',')
            var searchitemMonth = []
            this.state.months.map((stateMonth) => {
                months.map((queryMonth) => {
                    if (stateMonth.value === parseInt(queryMonth)) {
                        searchitemMonth.push(stateMonth)
                    }
                })
            })
            searchParams["months"] = searchitemMonth 
        }

        this.setState({ account: searchParams, load:true })
        this.loadIncomeStatements(searchUrl,this.props.location.search?true:false,true )
    }

    getSearchParams() {
        var searchParams = []
        Object.entries(this.state.account).map(([key, value]) => {
          if (value != '' &&key != 'months' &&key != 'branch' && value != undefined && value != null) {
            searchParams.push([key, value ].join("="))
          }
        })
        if(this.state.account.branch){
            if(this.state.account.branch.id){
                searchParams.push("branch_id="+this.state.account.branch.id)
            }
        }
        if (this.state.account.months !== undefined && this.state.account.months !== null ) {
            if (this.state.account.months.length >0) {
                var months = ""
                this.state.account.months.map((month, idx) => {
                    if (idx === this.state.account.months.length - 1) {
                        months = months + month.value
                    } else {
                        months = months + month.value + ','
                    }
                })
                searchParams.push("months="+months)
            }

        }
        return searchParams
      }

      loadIncomeStatements(url,withSearchParams,firstLoad) {
        if(withSearchParams){
            axios({
                method: 'get',
                url: url,
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ incomeStatements: resp.data.account ,withResultsLabel: true})
            this.setState({ urlPrint: resp.data.meta })
            this.setState({ load: true}) 
            }).catch(error => {
                this.setState({
                  isOpen: true,
                  message: error.response.status.toString() + " Unexpected Error Occurred",
                  type: 'error'
                })
              })
        }else{
            this.setState({ load: true })
        }
        if(firstLoad){
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ branches: resp.data })
            })
        }
    }

    handleSubmit() {
        const { history } = this.props
        var searchParams = this.getSearchParams().join("&")
        const params = new URLSearchParams()
        params.append("/search", searchParams)
        history.push({ search: searchParams })
        this.loadIncomeStatements([this.state.searchUrl, searchParams].join("?"),true,false)
    }

    handleInputChange(e) {
        this.setState({
            account: {
                ...this.state.account,
                [e.target.name]: e.target.value
            }
        })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleSubmit()
        }
    }

    clearSearch() {
        const { history } = this.props
        history.push({ search: '' })
        this.setState({incomeStatements: []})
        this.setState({ account: { as_of_date: '', is_posted: '', branch: {}, months: [] } })
    }

    handleAutoComplete(event, values) {
        this.setState({
            account: {
                ...this.state.account,
                branch: values
            }
        })
    }
    handleSelectChange(event, values) {
        this.setState({
            account: {
                ...this.state.account,
                months: values
            }
        })
    }

    handleCloseAlert (event, reason) {
        if (reason === 'clickaway') {
          this.setState({isOpen: false})
        }
      }

      changePrintLayout(){
        return new Promise((resolve) => {
            this.setState({ forPrint: !this.state.forPrint }, () => resolve());
          });        
    }


    render() {
        const print = 
            <ReactToPrint
            trigger={() => <IconButton color="primary"><PrintIcon /></IconButton>}
            onBeforeGetContent={this.changePrintLayout}
            onAfterPrint={this.changePrintLayout}
            content={() => this.componentRef}
            
            pageStyle={`@page {
                size: portrait; margin:  5px 10px 10px 15px !important;
            }`}
            />
        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            <IncomeSearchForm
                                item={this.state.account}
                                branches={this.state.branches}
                                handleSelectChange={this.handleSelectChange}
                                months={this.state.months}
                                handleAutoComplete={this.handleAutoComplete}
                                search={this.handleSubmit}
                                clearSearch={this.clearSearch}
                                handleChange={this.handleInputChange}
                            />
                            <IncomeStatementTable
                                months={this.state.months}
                                urlPrint={this.state.urlPrint}
                                item={this.state.incomeStatements}
                                model={this.state.model}
                                headers={this.state.tableHead}
                                icon={<AmpStoriesIcon fontSize="large" />}
                                searchItem={this.state.account}
                                ref={el => (this.componentRef = el)}
                                print={print}
                                forPrint={this.state.forPrint}
                            />
                            <AlertMessage
                                handleCloseAlert={this.handleCloseAlert}
                                isOpen={this.state.isOpen}
                                type={this.state.type}
                                message={this.state.message}
                            />
                        </div>
                    ) : (
                        <ProgressBar model={this.state.model} />
                    )}
            </>
        )
    }
}

export default withRouter(ConsolidatedIncomeStatements)

ConsolidatedIncomeStatements.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
}
