// eslint-disable-next-line
import React from 'react'

import axios from 'axios'

import PropTypes from 'prop-types'

import ConsolidatedBalanceSheetTable from './ConsolidatedBalanceSheetTable'
import ProgressBar from "../../ProgressBar/ProgressBar";
import { withRouter } from 'react-router-dom'
import ConsolidatedBalanceSheetsSearch from './ConsolidatedBalanceSheetsSearch'
import ClassIcon from '@material-ui/icons/Class';
import PrintIcon from '@material-ui/icons/Print'
import ReactToPrint from "react-to-print";
import { IconButton } from '@material-ui/core';
export const Component = withRouter(() => {

})

class ConsolidatedBalanceSheets extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            model: 'Consolidated Balance Sheet',
            balanceSheets: {},
            searchParams: {is_posted: 'true'},
            title: '',
            load: false,
            tableHead: ['name', 'code', 'balance', 'compared_balance'],
            branch: {},
            branches: [],
            urlPrint: {},
            forPrint: false,
            searchUrl: process.env.REACT_APP_API_DOMAIN + '/v1/accounts/consolidated_balance_sheet'
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleAutoComplete = this.handleAutoComplete.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.changePrintLayout = this.changePrintLayout.bind(this)
    }

    componentDidMount() {
        var searchUrl = this.state.searchUrl + this.props.location.search
        var searchParams = {is_posted: 'true'}
        const params = new URLSearchParams(this.props.location.search)
        params.forEach(function(value, key) {
            if (key !== 'page' ) {
                searchParams[key] = value
            }
        })

        if (searchParams.branch_id !== null && searchParams.branch_id !== undefined && searchParams.branch_id !== '') {
            axios({
              method: 'get',
              url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + searchParams.branch_id,
              headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
            }).then(resp => {
              this.setState({ searchParams: { ...this.state.searchParams, branch_id: resp.data } })
            })
        }

        this.setState({ searchParams: searchParams })
        this.loadBalanceSheets(searchUrl, this.props.location.search ? true : false)

    }

    loadBalanceSheets(url,searchLoad) {
        if (searchLoad) { 
            axios({
                method: 'get',
                url: url,
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ balanceSheets: resp.data.account })
                this.setState({urlPrint: resp.data.meta})
                axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
                    headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
                }).then(resp => {
                    this.setState({ branches: resp.data })
                    this.setState({ load: true })
                })
            }).catch((error) => {
                if(error.response)
                {this.setState({load: true, isOpen: true, message: error.response.status + " Unexpected Problem Occurred",type: 'error'})}
                })
        }else {
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ branches: resp.data,load: true })
            })
        }
    }

    handleSubmit() {
        this.setState({ load: true })
        const { history } = this.props
        var searchParams = this.getSearchParams().join("&")
        const params = new URLSearchParams()
        params.append("/search", searchParams)
        history.push({ search: searchParams })
        this.loadBalanceSheets([this.state.searchUrl, searchParams].join("?"), true, false)
    }

    handleInputChange(e) {
        this.setState({ searchParams: { ...this.state.searchParams, [e.target.name]: e.target.value  } })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleSubmit()
        }
    }

    getSearchParams() {
        var searchParams = []
        // This is to load all params based on searchParams state.
        Object.entries(this.state.searchParams).map(([key, value]) => {
          if (value != '' && value != undefined && value != null) {
            if(key === "branch_id") {
              searchParams.push([key, value.id].join("="))
            }else {
              searchParams.push([key, value].join("="))
            }
          }
        })
    
        return searchParams
      }

    clearSearch() {
        const { history } = this.props
        history.push({ search: '' })
        this.setState({balanceSheets: {},urlPrint: {}})
        this.setState({ searchParams: { date_to_compare: '', as_of_date: '', is_posted: '', branch_id: {} } })
    }

    handleAutoComplete(event, values) {
        this.setState({ searchParams: { ...this.state.searchParams,  branch_id: values }  })
    }

    changePrintLayout() {
        return new Promise((resolve) => {
            this.setState({ forPrint: !this.state.forPrint }, () => resolve());
          });     
      }

    render() {
        const printPdf = <ReactToPrint
        trigger={() => {
            return <IconButton color="primary"><PrintIcon /></IconButton>
        }}
        onBeforeGetContent={this.changePrintLayout}
        onAfterPrint={this.changePrintLayout}
        content={() => this.componentRef}
         />
        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            <ConsolidatedBalanceSheetsSearch
                                item={this.state.searchParams}
                                branches={this.state.branches}
                                search={this.handleSubmit}
                                clearSearch={this.clearSearch}
                                handleChange={this.handleInputChange}
                                handleKeyPress={this.handleKeyPress}
                                handleAutoComplete={this.handleAutoComplete}
                            />
                             <ConsolidatedBalanceSheetTable
                                item={this.state.balanceSheets}
                                model={this.state.model}
                                headers={this.state.tableHead}
                                icon={this.state.forPrint ? null:<ClassIcon fontSize="large" />}
                                searchItem={this.state.searchParams}
                                urlPrint={this.state.urlPrint}
                                forPrint={this.state.forPrint}
                                printPdf={printPdf}
                                ref={el => (this.componentRef = el)}
                            />


                        </div>
                    ) : (
                        <ProgressBar model={this.state.model} />
                    )}
            </>
        )
    }
}

export default withRouter(ConsolidatedBalanceSheets)

ConsolidatedBalanceSheets.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
}
