import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import SimpleTable from '../SimpleTable/SimpleTable';
class AuditLog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			logs: [],
			load: false
		}
	}


	componentDidMount() {
		if (this.props.gid) {
			this.loadAudit(this.props.gid)
		}
	}

	loadAudit(gid) {
		axios({
			method: 'get',
			url: process.env.REACT_APP_API_DOMAIN + '/v1/audit_logs?gid=' + gid,
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ logs: resp.data, load: true })
		}).catch(error => {
			if (error.response.status === 422) {
				this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
			} else {
				this.setState({isOpen: true,message: " Unexpected Error Occurred",type: 'error'})
		}})}

	render() {
		return (
					this.state.load ?
						this.state.logs.length>0?
						<SimpleTable
							items={this.state.logs}
							model={"Audit Log"}
							headers={['event', 'name', 'logged_at']}
							withShow={false}
							withEdit={false}
							noHeader={true}
						/>: <div>No changes yet</div>

						: null
		)
	}
}

export default AuditLog

AuditLog.propTypes = {
	gid: PropTypes.string
}
