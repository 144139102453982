import React from 'react'
import styles from './ARReports.module.css'

import PropTypes from 'prop-types'

import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead, Tooltip
} from '@material-ui/core'
import TableContainer from "@material-ui/core/TableContainer"

import {Redirect} from 'react-router'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CompanyHeader from '../../shared/CompanyHeader/CompanyHeader'

class ARReportsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      pathItem: {}
    }
  }

  redirectTo(item) {
    this.setState({redirect: true})
    this.setState({pathItem: item})
  }

  amountTotal(item) {
    var totalAmount = 0
    if (item.length > 0) {
      item.map((content) => {
        totalAmount = parseFloat(totalAmount) + parseFloat(content.total)
      })
    }
    return totalAmount
  }

  amountBalance(item) {
    var totalBalance = 0
    if (item.length > 0) {
      item.map((content) => {
        totalBalance = parseFloat(totalBalance) + parseFloat(content.balance)
      })
    }
    return totalBalance
  }

  amountPayment(item) {
    var totalPayment = 0
    if (item.length > 0) {
      item.map((content) => {
        totalPayment = parseFloat(totalPayment) + parseFloat(content.payment)
      })
    }
    return totalPayment
  }


  render() {

    const groupBy = (array) => {
      return array.reduce((result, currentValue) => {
        (result[currentValue.client_name] = result[currentValue.client_name] || []).push(
          currentValue
        );
        return result;
      }, {});
    };
    const clientsGroupBy = groupBy(this.props.items, "client_name");

    if (this.state.redirect) {
      if (this.state.pathItem !== null) {
        return (<Redirect
          to={
            {
              pathname: 'invoices/' + this.state.pathItem.id,
              state: {
                id: this.state.pathItem.id,
                item: this.state.pathItem
              }
            }
          }
        />)
      }
    }
    return (
      <div className={this.props.forPrint ? styles.tableBodyHeight : null}>
        <CompanyHeader forPrint={this.props.forPrint}/>
        <div className={styles.headerBody}>
          <div className={styles.tileBody}>
            {this.props.icon}
            <h2 className={styles.title}>
              <div className={styles.head}>
                <div>{this.props.model.replace('_', ' ') + 's'}</div>
              </div>
            </h2>
          </div>
          {this.props.withPrint ? (
            <div className={styles.buttonDisbursement}>
              {clientsGroupBy.length !== undefined ? (
                <Tooltip title="Print Sale register" arrow>
                  <div className={this.props.forPrint? styles.noDisplay:null}>
                    {this.props.printPdf}
                  </div>
                </Tooltip>
              ) : null}
              {clientsGroupBy.length !== undefined ? (
                <Tooltip title="Download excel File" arrow className={this.props.forPrint? styles.noDisplay:null}>
                  <a href={`${this.props.urlPrint.export_excel_url}`} target="_blank"
                     rel="noopener noreferrer">
                    <CloudDownloadIcon/>
                  </a>
                </Tooltip>
              ) : null}
            </div>
          ) : null}
        </div>

        <TableContainer style={{maxHeight: '575px'}}>
          <Table size="small" stickyHeader aria-label="customized sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={styles.clientName}>
                  <b>Client Name</b>
                </TableCell>
                <TableCell className={styles.customCell}>
                  <TableCell className={styles.ref}>
                    <b>Reference No</b>
                  </TableCell>
                  <TableCell className={styles.dateInvoice}>
                    <b>Date Of Invoice</b>
                  </TableCell>
                  <TableCell className={styles.dateDue}>
                    <b>Due Date</b>
                  </TableCell>
                  <TableCell className={styles.amountTotal}>
                    <b>Total</b>
                  </TableCell>
                  <TableCell className={styles.amountPayment}>
                    <b>Payment</b>
                  </TableCell>
                  <TableCell className={styles.amountBalance}>
                    <b>Balance</b>
                  </TableCell>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={styles.tableBody}>
              <>
                {Object.keys(clientsGroupBy).sort(function (a, b) {
                  if (a < b) return -1;
                  if (a > b) return 1;
                  return 0;
                }).map((item, i) => (
                  <TableRow key={i}>
                    <TableCell className={styles.clientName}>
                      {item}
                    </TableCell>
                    {clientsGroupBy[item].map((client, index) => (
                      <>
                        <TableRow key={client} onClick={() => this.redirectTo(client)} className={styles.linkable}>
                          <TableCell className={styles.ref}>
                            {client.reference_no}
                          </TableCell>
                          <TableCell className={styles.dateInvoice}>
                            {new Date(client.date_of_invoice).toLocaleDateString('en-Us', {
                              month: '2-digit',
                              day: '2-digit',
                              year: '2-digit'
                            })}
                          </TableCell>
                          <TableCell className={styles.dateDue}>
                            {new Date(client.date_of_maturity).toLocaleDateString('en-Us', {
                              month: '2-digit',
                              day: '2-digit',
                              year: '2-digit'
                            })}
                          </TableCell>
                          <TableCell className={styles.amountTotal}>
                            {client.total.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                          </TableCell>
                          <TableCell className={styles.amountPayment}>
                            {client.payment.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                          </TableCell>
                          <TableCell className={styles.amountBalance}>
                            {client.balance.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                          </TableCell>
                        </TableRow>
                        <>
                          {
                            index + 1 === clientsGroupBy[item].length ? (
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell className={styles.textAlignRight}> <b>Total</b></TableCell>
                                <TableCell className={styles.textAlignRight}><b>{this.amountTotal(clientsGroupBy[item]).toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
                                <TableCell className={styles.textAlignRight}><b>{this.amountPayment(clientsGroupBy[item]).toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
                                <TableCell className={styles.textAlignRight}><b>{this.amountBalance(clientsGroupBy[item]).toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
                              </TableRow>
                            ) : null
                          }
                        </>

                      </>
                    ))}
                  </TableRow>
                ))}
              </>
            </TableBody>

          </Table>
        </TableContainer>

      </div>
    )
  }
}

export default ARReportsTable

ARReportsTable.propTypes = {
  items: PropTypes.array,
  model: PropTypes.string,
  icon: PropTypes.string,
  urlPrint: PropTypes.string,
  withPrint: PropTypes.bool,
  printPdf: PropTypes.object,
  forPrint: PropTypes.bool,
}
