import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios"
import {Redirect} from 'react-router'
import ClientNewForm from "./ClientNewForm";
import * as CommonFunction from "../../shared/Custom/CommonFunctions"

class ClientsNewAndUpdate extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      model: 'Client',
      clients: [],
      emailAddress: [],
      contactNumbers: [],
      forEmailContent: [],
      load: false,
      withDelete: false,
      updateClassification: {},
      client: {
        id: '',
        name: '',
        classification_id: '',
        company_address: '',
        business_type: '',
        remarks: '',
        home_address: '',
        tax_exempted: false,
        tin_no: '',
        reference_no: '',
      },
      email_addresses_attributes: [{category: 0, content: ''}],
      contact_numbers_attributes: [{category: 0, content: ''}],
      classifications: [],
      redirect: false,
      errorMessages: {}
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleAutocompleteClassification = this.handleAutocompleteClassification.bind(this)
    this.addEmailAddress = this.addEmailAddress.bind(this)
    this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this)
    this.removeEmail = this.removeEmail.bind(this)
    this.handleSubmitClient = this.handleSubmitClient.bind(this)
    this.addNumber = this.addNumber.bind(this)
    this.handleNumberChange = this.handleNumberChange.bind(this)
    this.removeNumber = this.removeNumber.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.padFix = this.padFix.bind(this)
  }


  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.props.match.params.id,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ client: {...resp.data, reference_no: this.padFix(resp.data.reference_no)[0]}, updateClassification: resp.data.classification })
        this.handleLoadData(process.env.REACT_APP_API_DOMAIN + '/v1/clients/classifications', "classifications")
        this.handleLoadData(process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.props.match.params.id + '/email_addresses', "email_addresses_attributes")
        this.handleLoadData(process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + this.props.match.params.id + '/contact_numbers', "contact_numbers_attributes")
        this.setState({ load: true })
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    } else {
      this.loadClients(process.env.REACT_APP_API_DOMAIN + '/v1/clients/search')
    }

  }

  handleLoadData (url, data) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ [data]: resp.data })
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
  }

  loadClients(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ clients: resp.data.clients, load: true })
      this.handleLoadData(process.env.REACT_APP_API_DOMAIN + '/v1/clients/classifications', "classifications")
    }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    axios({
      method: 'get',
      url:  process.env.REACT_APP_API_DOMAIN + '/v1/clients/generate_ref_no',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ client: {...this.state.client, reference_no: this.padFix(resp.data.reference_no)[0] } })
    }).catch(error => console.log(error.response))
  }

  padFix(n) {
    return ('00000000'+n.toString()).match(/\S{8}$/);
  }

  handleInputChange(e) {
    if(e.target.name==="reference_no"){
      this.setState({
        client: {
          ...this.state.client,
          reference_no: this.padFix(e.target.value)[0]
        }
      })
    }else {
    this.setState({
      client: {
        ...this.state.client,
        [e.target.name]: e.target.value
      }
    })
  }
  }

  handleSwitch(event) {
    const value = event.target.checked
    this.setState({
      client: {
        ...this.state.client,
        [event.target.name]: value
      }
    })
  }

  handleAutocompleteClassification(event, values) {
    if (values !== null) {
      this.setState({updateClassification: values})
      this.setState({
        client: {
          ...this.state.client,
          classification_id: values.id
        }
      })
    }
  }


  handleSubmitClient() {
    var item = {}
    item['name'] = this.state.client.name
    item['id'] = this.state.client.id
    item['classification_id'] = this.state.client.classification_id
    item['business_type'] = this.state.client.business_type
    item['company_address'] = this.state.client.company_address
    item['home_address'] = this.state.client.home_address
    item['remarks'] = this.state.client.remarks
    item['tin_no'] = this.state.client.tin_no
    item['tax_exempted'] = this.state.client.tax_exempted
    item['reference_no'] = this.state.client.reference_no
    if (this.props.match.params.id === undefined) {
      item['email_addresses_attributes'] = this.state.email_addresses_attributes
      item['contact_numbers_attributes'] = this.state.contact_numbers_attributes
    }

    var method = ''
    var url = ''
    if (this.props.match.params.id !== undefined) {
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/clients/' + item.id
    }
    else {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/clients'
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      const newData = resp.data
      this.setState({
        clients: this.state.clients.filter(client => client.uid !== item.uid)
      })
      this.setState({clients: [...this.state.clients, newData]})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({client: newData})
      this.setState({redirect: true})
    }).catch(error => {
      if (error.response.status === 422) {
        var errors = {}
        Object.keys(error.response.data.errors[0].detail.errors).map((field) => {
          errors[field] = error.response.data.errors[0].detail.errors[field][0]
        })
        this.setState({errorMessages: errors})
      } else {
        this.setState({isOpen: true, message: error.response.status.toString() + " Unexpected Error Problem Occurred", type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }


  addEmailAddress() {
    this.setState({email_addresses_attributes: [...this.state.email_addresses_attributes, {...this.state.email_addresses_attributes}]})
  }

  handleEmailAddressChange(e) {
    const updatedEmailAddress = [...this.state.email_addresses_attributes]
    updatedEmailAddress[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
    this.setState({email_addresses_attributes: updatedEmailAddress})
  }

  removeEmail(idx) {
    this.state.email_addresses_attributes.splice(idx, 1)
    this.setState({email_addresses_attributes: [...this.state.email_addresses_attributes]})

  }

  addNumber() {
    this.setState({contact_numbers_attributes: [...this.state.contact_numbers_attributes, {...this.state.contact_numbers_attributes}]})
  }

  handleNumberChange(e) {
    const updatedEmailAddress = [...this.state.contact_numbers_attributes]

    updatedEmailAddress[e.target.dataset.id][e.target.dataset.fieldType] = e.target.value
    if (updatedEmailAddress[e.target.dataset.id]["id"] !== undefined ) {
      this.setState({ forEmailContent: [...this.state.forEmailContent, updatedEmailAddress[e.target.dataset.id]] })
    }
    this.setState({contact_numbers_attributes: updatedEmailAddress})
  }

  removeNumber(idx) {
    this.state.contact_numbers_attributes.splice(idx, 1)
    this.setState({contact_numbers_attributes: [...this.state.contact_numbers_attributes]})
  }

  render() {
    const {redirect} = this.state
    if (redirect)
      return (
        <Redirect to={{
          pathname: '/clients/' + this.state.client.id
        }}/>)
    return (

      this.state.load ? (
        <ClientNewForm
          handleSubmit={this.handleSubmitClient}
          handleAutocompleteClassification={this.handleAutocompleteClassification}
          handleInputChange={this.handleInputChange}
          updateClassification={this.state.updateClassification}
          showNestedAtrributes={this.props.match.params.id}
          handleSwitch={this.handleSwitch}
          classifications={this.state.classifications}
          item={this.state.client}
          addEmailAddress={this.addEmailAddress}
          handleEmailAddressChange={this.handleEmailAddressChange}
          removeEmail={this.removeEmail}
          addNumber={this.addNumber}
          error={this.state.errorMessages}
          handleNumberChange={this.handleNumberChange}
          removeNumber={this.removeNumber}
          contactNumber={this.state.contact_numbers_attributes}
          emailAddress={this.state.email_addresses_attributes}
        />
      ) : null
    )
  }
}

export default ClientsNewAndUpdate

ClientsNewAndUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  location: PropTypes.string
}
