// eslint-disable-next-line
import React from 'react'

import axios from 'axios'

import PropTypes from 'prop-types'

import TrialBalanceTable from './TrialBalanceTable'
import ProgressBar from "../../ProgressBar/ProgressBar";
import { withRouter } from 'react-router-dom'
import TrialBalanceSearchForm from './TrialBalanceSearchForm'
import * as CommonFunction from "../../Custom/CommonFunctions"
import PrintIcon from '@material-ui/icons/Print'
import ReactToPrint from "react-to-print";
import { IconButton } from '@material-ui/core';
export const Component = withRouter(() => {

})

class TrialBalances extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            model: 'Trial Balance',
            trialBalances: [],
            account: {is_posted: 'true'},
            title: '',
            load: false,
            tableHead: ['name','name_type','code','amount_balance'],
            branch: {},
            branches: [],
            urlPrint: {},
            searchUrl: process.env.REACT_APP_API_DOMAIN + '/v1/accounts/trial_balance',
            isOpen: false,
            forPrint: false,
            message: '',
            type: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleAutoComplete = this.handleAutoComplete.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.changePrintLayout = this.changePrintLayout.bind(this)
    }

    componentDidMount() {
        var searchUrl = this.state.searchUrl + this.props.location.search
        var searchParams = {is_posted: true}
        const query = new URLSearchParams(this.props.location.search)      
        query.forEach(function (value, key) {
            if (key !== 'branch_id') {
                searchParams[key] = value
            }
        })
        if (query.get('branch_id') !== undefined && query.get('branch_id') !== null && query.get('branch_id') !== '') {
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + query.get('branch_id'),
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                searchParams["branch"] = resp.data
                this.setState({ account: searchParams })
            })
        }else{this.setState({ account: searchParams })}
        this.loadTrialBalances(searchUrl,this.props.location.search?true:false,true )

    }

    loadTrialBalances(url,withSearchParams,firstLoad) {

        if(withSearchParams){
            axios({
                method: 'get',
                url: url,
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                const newData = resp.data.accounts.map((item) => ({
                    id: item.id,name: item.name,code: item.code,
                    amount_balance: item.amount_balance,
                    debit: item.amount_balance>0?(item.amount_balance):0,
                    credit: item.amount_balance<0?(item.amount_balance*-1):0
                }))
                this.setState({ trialBalances: newData ,urlPrint: resp.data.meta, load: true })
            }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))}
        if(firstLoad){
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {this.setState({ branches: resp.data,load: true })})
        }else{this.setState({ load: true })}
    }

    handleSubmit() {
        this.setState({load: false})
        const { history } = this.props
        var searchParams = this.getSearchParams().join("&")
        const params = new URLSearchParams()
        params.append("/search", searchParams)
        history.push({ search: searchParams })
        this.loadTrialBalances([this.state.searchUrl, searchParams].join("?"),true,false)
    }

    getSearchParams() {
        var searchParams = []
        Object.entries(this.state.account).map(([key, value]) => {
          if (value != '' &&key != 'months' &&key != 'branch' && value != undefined && value != null) {
            searchParams.push([key, value ].join("="))
          }
        })
        if(this.state.account.branch){
            if(this.state.account.branch.id){
                searchParams.push("branch_id="+this.state.account.branch.id)
            }
        }
        return searchParams
      }

      handleCloseAlert (event, reason) {
        if (reason === 'clickaway') {this.setState({isOpen: false})}
      }

    handleInputChange(e) {
        this.setState({
            account: {
                ...this.state.account,
                [e.target.name]: e.target.value
            }
        })
    }

    handleKeyPress(e) {
        if(e.key==='enter'){
            this.handleSubmit()
        }
    }

    clearSearch() {
        const { history } = this.props
        history.push({ search: '' })
        this.setState({ account: { as_of_date: '', is_posted: '', branch: {} } })
        this.setState({trialBalances: [],urlPrint: {}})
    }

    handleAutoComplete(event, values) {
        this.setState({
            account: {
                ...this.state.account,
                branch: values
            }
        })
    }

    changePrintLayout() {
        return new Promise((resolve) => {
            this.setState({ forPrint: !this.state.forPrint }, () => resolve());
          });     
      }

    render() { 
        const printPdf = <ReactToPrint
        trigger={() => {
            return <IconButton color="primary"><PrintIcon /></IconButton>
        }}
        onBeforeGetContent={this.changePrintLayout}
        onAfterPrint={this.changePrintLayout}
        content={() => this.componentRef}
         />
        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            <TrialBalanceSearchForm
                                item={this.state.account}
                                branches={this.state.branches}
                                handleChange={this.handleInputChange}
                                handleAutoComplete={this.handleAutoComplete}
                                search={this.handleSubmit}
                                clearSearch={this.clearSearch}
                            />
                            <TrialBalanceTable
                                items={this.state.trialBalances}
                                model={this.state.model}
                                urlPrint={this.state.urlPrint}
                                forPrint={this.state.forPrint}
                                printPdf={printPdf}
                                ref={el => (this.componentRef = el)}
                            />

                        </div>
                    ) : (
                        <ProgressBar model={this.state.model} />
                    )}
            </>
        )
    }
}

export default withRouter(TrialBalances)

TrialBalances.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
}
