// eslint-disable-next-line
import React from 'react'

import PropTypes from 'prop-types'

import ARReportsSearchForm from './ARReportsSearchForm'
import PaymentIcon from '@material-ui/icons/Payment'
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import AlertMessage from "../../shared/Notify/AlertMessage";

export const Component = withRouter(() => {
})
import styles from './ARReports.module.css'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import ARReportsTable from "./ARReportsTable";
import * as CommonFunction from "../../shared/Custom/CommonFunctions"
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import IconButton from '@material-ui/core/IconButton'

class ARReports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Accounts Receivable Report',
      saleRegisters: [],
      branches: [],
      searchParams: {tax_exempted: 'null'},
      title: '',
      accountId: '',
      load: false,
      tableHead: ["client_name", "reference_no", "date_of_invoice", "date_of_maturity", 'Total', 'payment', 'balance'],
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      clientName: [],
      searchInvoices: [],
      withPrint: true,
      urlPrint: '',
      totalPages: 1,
      currentPage: 1,
      withPagination: true,
      grandTotalAmount: 0,
      forPrint: false,
      searchUrl: process.env.REACT_APP_API_DOMAIN + '/v1/invoices/invoice_register?report_type=ar&status=for_approval,for_posting,posted&is_paid=false&per_page=1000000&'
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleARRSearch = this.handleARRSearch.bind(this)
    this.changePrintLayout = this.changePrintLayout.bind(this)
  }

  componentDidMount() {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {tax_exempted: 'null'}
    const params = new URLSearchParams(this.props.location.search)
    // This is to load all params based on URLSearchParams
    params.forEach(function(value, key) {
      if (key !== 'page' ) {
        searchParams[key] = value
      }
    })

    if (searchParams.branch_id !== null && searchParams.branch_id !== undefined && searchParams.branch_id !== '') {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + searchParams.branch_id,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ searchParams: { ...this.state.searchParams, branch_id: resp.data } })
      })
    }

    this.setState({ searchParams: searchParams })
    this.loadARR(searchUrl, this.props.location.search ? true : false)
  }

  loadARR(url,withSearchParams) {
    if (withSearchParams) {
      axios({
        method: 'get',
        url: url,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
          var newArray = []
          resp.data.invoices.map((value) => {
            value.client_name === value.client_name

            return newArray.push(value)
          })
          this.setState({clientName: newArray})
          this.setState({load: true})
          this.setState({searchInvoices: resp.data.invoices})
          this.setState({urlPrint: resp.data.meta})
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            this.setState({ branches: resp.data })
          })
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
      }else{
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            this.setState({ branches: resp.data })
            this.setState({ load: true })
          })
      }
  }


  handleSearch() {
    this.setState({clientName: [], load:false})
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadARR([this.state.searchUrl, searchParams].join("?"), true, false)
  }

  getSearchParams() {
    var searchParams = []
    // This is to load all params based on searchParams state.
    Object.entries(this.state.searchParams).map(([key, value]) => {
      if (value != '' && value != undefined && value != null) {
        if(key === "branch_id") {
          searchParams.push([key, value.id].join("="))
        }else{
          searchParams.push([key, value].join("="))
        }
      }
    })

    return searchParams
  }

  handleSearchInputChange(e) {
    this.setState({
      searchParams: {
        ...this.state.searchParams,
        [e.target.name]: e.target.value
      }
    })
  }

  handleInputChange(e) {
    this.setState({
      searchParams: {
        ...this.state.searchParams,
        [e.target.name]: e.target.value
      }
    })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  clearSearch() {
    const { history } = this.props
    history.push({ search: '' })
    this.setState({ searchParams: { as_of_date: '', tax_exempted: 'null', is_posted: '', branch_id: {} } })
    this.setState({ clientName: [],urlPrint: {}, searchInvoices: [] })
  }

  handleARRSearch(event, values) {
    this.setState({ searchParams: { ...this.state.searchParams,branch_id: values } })
  }

  grandTotalAmount() {
    var totalAmount = 0
    if (this.state.searchInvoices.length > 0) {
      this.state.searchInvoices.map((content) => {
        totalAmount = parseFloat(totalAmount) + parseFloat(content.total)
      })
    }
    return totalAmount
  }

  grandTotalPayment() {
    var totalPayment = 0
    if (this.state.searchInvoices.length > 0) {
      this.state.searchInvoices.map((content) => {
        totalPayment = parseFloat(totalPayment) + parseFloat(content.payment)
      })
    }
    return totalPayment
  }

  grandTotalBalance() {
    var totalBalance = 0
    if (this.state.searchInvoices.length > 0) {
      this.state.searchInvoices.map((content) => {
        totalBalance = parseFloat(totalBalance) + parseFloat(content.balance)
      })
    }
    return totalBalance
  }

  changePrintLayout() {
    return new Promise((resolve) => {
        this.setState({ forPrint: !this.state.forPrint }, () => resolve());
      });     
  }

  render() {
    const printPdf = <ReactToPrint
      trigger={() => {
          return <IconButton color="primary"><PrintIcon /></IconButton>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />
    return (
      <>
        {
          this.state.load ? (
            <div>
              <ARReportsSearchForm
                clearSearch={this.clearSearch}
                item={this.state.searchParams}
                branches={this.state.branches}
                search={this.handleSearch}
                handleChange={this.handleSearchInputChange}
                handleARRSearch={this.handleARRSearch}
                handleKeyPress={this.handleKeyPress}
              />
            <div ref={el => (this.componentRef = el)} >
              <ARReportsTable
                items={this.state.clientName}
                clientName={this.state.clientName}
                withPrint={this.state.withPrint}
                model={this.state.model}
                headers={this.state.tableHead}
                icon={this.state.forPrint?null:<PaymentIcon fontSize="large"/>}
                currentPage={this.state.currentPage}
                totalPages={this.state.totalPages}
                urlPrint={this.state.urlPrint}
                handlePageChange={this.handlePageChange}
                printPdf={printPdf}
                forPrint={this.state.forPrint}
              />
              {this.state.searchInvoices.length > 0 ? (
                <Table>
                  <TableRow className="fixWidth">
                    <TableCell className={styles.clientName}></TableCell>
                    <TableCell className={styles.ref}></TableCell>
                    <TableCell className={styles.dateInvoice}></TableCell>
                    <TableCell className={styles.grandTotal}>Grand Total</TableCell>
                    <TableCell
                      className={styles.textAlignRight}><b>{this.grandTotalAmount().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b></TableCell>
                    <TableCell
                      className={styles.textAlignRight}><b>{this.grandTotalPayment().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b>
                    </TableCell>
                    <TableCell
                      className={styles.textAlignRight}><b>{this.grandTotalBalance().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</b>
                    </TableCell>
                  </TableRow>
                </Table>
              ) : null}
            </div>
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar/>
          )}
      </>
    )
  }
}

export default withRouter(ARReports)

ARReports.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
