// eslint-disable-next-line
import React from 'react'

import Cookies from 'js-cookie';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
} from 'react-router-dom'
import clsx from 'clsx'
import axios from 'axios'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ProgressBar from './shared/ProgressBar/ProgressBar'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import HomeIcon from '@material-ui/icons/Home'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ClassIcon from '@material-ui/icons/Class';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import PaymentIcon from '@material-ui/icons/Payment';
import Grid from '@material-ui/core/Grid'
import style from './App.module.css'
import CollectionRegister from "./pages/CollectionRegisters/CollectionRegister";
import TermOfPayments from "./pages/TermOfPayments/TermOfPayments";
import SaleRegister from "./pages/SaleRegisters/SaleRegister";
import Classifications from "./pages/Classifications/Classifications";
import ARReports from "./pages/ARReports/ARReports";
import Products from "./pages/Products/Products";
import Clients from './pages/Clients/Clients'
import IRReports from "./pages/IRReports/IRReports";
import ClientsNewUpdate from "./pages/Clients/ClientsNewAndUpdate";
import ClientsShow from "./pages/Clients/ClientsShow";
import Deliveries from './pages/Deliveries/Deliveries'
import Receipts from './pages/Receipts/Receipts'
import Invoices from "./pages/Invoices/Invoices";
import InvoicesNewAndUpdate from "./pages/Invoices/InvoicesNewAndUpdate";
import InvoiceShow from "./pages/Invoices/InvoiceShow"
import DeliveryNew from './pages/Deliveries/DeliveryNew'
import DeliveryShow from './pages/Deliveries/DeliveryShow'
import ReceiptNew from './pages/Receipts/ReceiptNew'
import ReceiptShow from './pages/Receipts/ReceiptShow'
import AccountLink from "./shared/ModuleLinks/AccountLink";
import CwtTypes from './pages/CwtTypes/CwtTypes'

import FilterNoneIcon from '@material-ui/icons/FilterNone'
import AssignmentIcon from '@material-ui/icons/Assignment';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import NotesIcon from '@material-ui/icons/Notes';
import ClearAllIcon from '@material-ui/icons/ClearAll';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Journals from './shared/JournalComponent/Journals/Journals'
import JournalShow from './shared/JournalComponent/Journals/JournalShow'
import JournalsNewAndUpdate from './shared/JournalComponent/Journals/JournalsNewAndUpdate'
import JournalRegisters from './shared/JournalComponent/GeneralJournalRegisters/JournalRegisters'
import BalanceSheets from './shared/JournalComponent/BalanceSheets/BalanceSheets'
import ConsolidatedBalanceSheets from './shared/JournalComponent/ConsolidatedBalanceSheets/ConsolidatedBalanceSheets'
import ConsolidatedIncomeStatements from './shared/JournalComponent/IncomeStatements/ConsolidatedIncomeStatements'
import TrialBalances from './shared/JournalComponent/TrialBalances/TrialBalances'
import GeneralLedgers from './shared/JournalComponent/GeneralLedgers/GeneralLedgers'
import Branches from "./shared/Settings/Branches/Branches";
import Banks from "./shared/Settings/Banks/Banks";
import Accounts from "./shared/Settings/Accounts/Accounts";
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import SettingsSystemDaydreamOutlinedIcon from '@material-ui/icons/SettingsSystemDaydreamOutlined';
import SettingsInputCompositeOutlinedIcon from '@material-ui/icons/SettingsInputCompositeOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import logo from '../../src/components/images/lexLogo.png'
import IncomeStatements from "./shared/JournalComponent/IncomeStatements/IncomeStatements";
import Departments from "./shared/Settings/Departments/Departments";
import ProductTransactions from './pages/ProductTransactions/ProductTransactions';
import VpnKeyOutlined from '@material-ui/icons/VpnKeyOutlined';
import Permissions from "./shared/Settings/Permissions/Permissions";
import PermissionNewUpdate from "./shared/Settings/Permissions/PermissionNewUpdate";
import UpdateProfile from './pages/Profile/UpdateProfile'
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ChangePasswordForm from './shared/Appbar/ChangePasswordForm';

import SimplePopUp from './shared/SimplePopUp/SimplePopUp'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import BusinessIcon from '@material-ui/icons/Business';
class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true,
      linkOpen: false,
      token: '',
      signIn: false,
      apps: [],
      tenants: [],
      currentTenant: '',
      anchorEl: null,
      openPopup: false,
    }
    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.toggleLink = this.toggleLink.bind(this)
    this.logOut = this.logOut.bind(this)
    this.setToken = this.setToken.bind(this)
    this.setLoad = this.setLoad.bind(this)
    this.loadAttribute = this.loadAttribute.bind(this)
    this.toggleAcount = this.toggleAcount.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.changeTenant = this.changeTenant.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleClosePopup = this.handleClosePopup.bind(this)
  }

  componentDidMount() {
    var token = Cookies.get('token')

    if (token) {
      localStorage.setItem('api_key', token)
      this.loadAttribute()
    } else {
      this.logOut()
    }
  }

  setToken(token) {
    this.setState({ token: token })
  }

  setLoad(load) {
    this.setState({ load: load })
  }

  toggleDrawer() {
    this.setState({ open: !this.state.open })
  }

  toggleLink() {
    this.setState({linkOpen: !this.state.linkOpen})
  }

  handleChangePassword() {
    this.setState({ openPopup: true, anchorEl: null })
  }

  handleClosePopup() {
    this.setState({ openPopup: false })
  }

  loadAttribute() {
   this.loadApps()
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/user/tenants',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({tenants: resp.data.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      })})
      if(Cookies.get("current_user")){
        resp.data.map((tenant) => {
          if (tenant.id === JSON.parse(Cookies.get("current_user")).tenant_id) {
            this.setState({currentTenant: tenant.name})
          }
        })
        localStorage.setItem('current_user',Cookies.get("current_user"))
      }
    })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/profiles',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      const current_user = resp.data
      localStorage.setItem("current_user", JSON.stringify(current_user))
      this.setState({ load: true, signIn: true })
    })
  }

  toggleAcount(event) {
    this.setState({ anchorEl: event.currentTarget })
  }
  
  handleClose() {
    this.setState({ anchorEl: null })
  }

  logOut() {
    this.clearSession();
    this.setState({ 
      anchorEl: null,
      load: false
    })
    location.href = 'https://'+ process.env.REACT_APP_GATEWAY_SUBDOMAIN + process.env.REACT_APP_DOMAIN + '/login'
  }

  loadApps() {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/apps',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      localStorage.setItem('apps', JSON.stringify(resp.data))
      if(resp.data.length>0){ 
      var apps=[]
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/user',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(res => {
        localStorage.setItem('user_apps', JSON.stringify(res.data.app_ids))
        if(res.data.app_ids.length>0){
          apps = resp.data.filter(({id}) => res.data.app_ids.includes(id))
          this.setState({apps: apps}) 
        }
      })
    }
    })
  }

  clearSession() {
    localStorage.removeItem('api_key')
    Cookies.remove('token')
    Cookies.remove('current_user')
    Cookies.remove('cashvoucher_prefix')
    Cookies.remove('checkvoucher_prefix')
    Cookies.remove('token', { domain: process.env.REACT_APP_DOMAIN })
    Cookies.remove('current_user', { domain: process.env.REACT_APP_DOMAIN })
    Cookies.remove('cashvoucher_prefix', { domain: process.env.REACT_APP_DOMAIN })
    Cookies.remove('checkvoucher_prefix', { domain: process.env.REACT_APP_DOMAIN })
  }

  changeTenant(id) {
    axios({
      method: 'put',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/user/tenants/' + id + "/switch",
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({currentTenant: resp.data.name})
      Cookies.set("current_user", JSON.stringify({  tenant_id: resp.data.id }))
      location.reload()
    })
  }

  render() {
    const { anchorEl } = this.state
    const { tenants } = this.state
    var current_user = {}
    if(localStorage.getItem("current_user")){
      current_user = JSON.parse(localStorage.getItem("current_user"))
    }
    
    let mainMenuTabs = [
      { label: 'Home', icon: <HomeIcon/>, link: '/' },
      { label: 'Clients', icon: <PeopleOutlineIcon/>, link: '/clients' },
      { label: 'Invoices', icon: <DescriptionOutlinedIcon/>, link: '/invoices' }
    ]

    if (![null, undefined].includes(current_user) && current_user.is_from_cdasia) {
      const tabs = [{ label: 'Deliveries', icon: <LocalShippingOutlinedIcon/>, link: '/deliveries' },
                    { label: 'Receipts/Collections', icon: <ReceiptIcon/>, link: '/receipts' },
                    { label: 'Product Given Passwords', icon: <VpnKeyOutlined/>, link: '/product_transactions' }]
      mainMenuTabs = [ ...mainMenuTabs, ...tabs ]
    } else {
      mainMenuTabs.push(
        { label: 'Receipts/Collections', icon: <ReceiptIcon/>, link: '/receipts' })
    }

    const Tenants = [
      { parentLabel: this.state.currentTenant,  parentIcon: <BusinessIcon />, tenants },]

    const parentMenuTabs = [
      {label: 'Reports', icon: <ListAltIcon/>, link: '/settings'},
      {label: 'AR Settings', icon: <PermDataSettingIcon/>, link: '/settings'}
    ]
    const GeneralAccounting = [
      {
        menuLabel: 'General Accounting', menuIcon: <FilterNoneIcon />,
        children: [
          { label: 'Journal Voucher', icon: <CreditCardIcon />, link: '/journals' },
        ],
        childrenWithSubs: [
          {
            parentLabel: 'Reports', parentIcon: <NotesIcon />,
            children: [
              { label: 'General Journal Register', icon: <AssignmentIcon />, link: '/journal_registers' },
              { label: 'General Ledger Report', icon: <ClearAllIcon />, link: '/general_ledgers' },
              { label: 'Balance Sheet', icon: <ListAltIcon />, link: '/balance_sheets' },
              { label: 'Consolidated Balance Sheet(*)', icon: <ClassIcon />, link: '/consolidated_balance_sheets' },
              { label: 'Trial Balance', icon: <SignalCellularAltIcon />, link: '/trial_balances' },
              { label: 'Income Statement', icon: <AccountBalanceIcon />, link: '/income_statements' },
              { label: 'Consolidated Income Statement(*)', icon: <AmpStoriesIcon />, link: '/consolidated_income_statements' }
            ]
          }
        ]
      }
    ]
    const Settings = [
      {
        menuLabel: 'Settings', menuIcon: <SettingsOutlinedIcon />,
        children: [
          { label: 'Account Setup', icon: <SettingsSystemDaydreamOutlinedIcon />, link: '/accounts' },
          { label: 'Branches', icon: <SettingsInputCompositeOutlinedIcon />, link: '/branches' },
          { label: 'Banks', icon: <AccountBalanceOutlinedIcon />, link: '/banks' },
        ],
        childrenWithSubs: [
          {
            parentLabel: 'Users Access and Permissions', parentIcon: <NotesIcon />,
            children: [
              { label: 'Permissions', icon: <AssignmentIcon />, link: '/permissions' },
            ]
          }
        ]
      }
    ]
    const subMenuTabs = [
      {parentLabel: 'Reports', childLabel: 'Sales Registers', childIcon: <TrendingUpIcon/>, link: '/sales_registers'},
      {
        parentLabel: 'Reports',
        childLabel: 'Collection Registers',
        childIcon: <TrendingUpIcon/>,
        link: '/collection_registers'
      },
      {parentLabel: 'Reports', childLabel: 'A/R Report', childIcon: <TrendingUpIcon/>, link: '/invoice_registers'},
      {parentLabel: 'Reports', childLabel: 'I/R Report', childIcon: <TrendingUpIcon/>, link: '/ir_invoice_registers'},
      {parentLabel: 'AR Settings', childLabel: 'Classifications', childIcon: <ClassIcon/>, link: '/classifications'},
      {parentLabel: 'AR Settings', childLabel: 'Products', childIcon: <WorkOutlineIcon/>, link: '/products'},
      {parentLabel: 'AR Settings', childLabel: 'CWT Types', childIcon: <PermDataSettingIcon/>, link: '/cwt_types'},
      {
        parentLabel: 'AR Settings',
        childLabel: 'Term of Payments',
        childIcon: <PaymentIcon/>,
        link: '/terms_of_payments'
      }
    ]

    return (
      <>
        {
          this.state.load ? (
            <Router>
              <div className={style.root}>
                <CssBaseline/>

                <AppBar
                  position="fixed"
                  className={clsx(style.appBar, {
                    [style.appBarShift]: this.state.open
                  })}
                >
                  <Toolbar>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={this.toggleDrawer}
                      edge="start"
                      className={clsx(style.menuButton, {
                        [style.hide]: this.state.open
                      })}
                    >
                      <MenuIcon/>
                    </IconButton>
                    <div className={style.headerTitle}>
                      <div className={style.hederListBody}>
                        <Typography variant="h6" noWrap style={{flexGrow: 1}}>
                          {process.env.REACT_APP_TITLE}
                        </Typography>

                        <div className={style.linkList}>
                          <div>
                            <AccountLink
                              toggleLink={this.toggleLink}
                              linkOpen={this.state.linkOpen}
                              apps={this.state.apps}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={style.rightSideLogout}>
                        <div className={style.headerCurrentUser}>
                          <h3><b>Welcome, <span>{ current_user ? current_user.name : null}</span></b></h3>
                        </div>

                        <Tooltip title="manage account"  arrow>
                        <Button aria-controls="fade-menu" aria-haspopup="true" onClick={this.toggleAcount}>
                        {anchorEl ? <ExpandLessIcon style={{ color: '#fff' }}/> : <ExpandMoreIcon style={{ color: '#fff' }}/> }
                        </Button>
                        </Tooltip>
                          <Menu
                            id="fade-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                            TransitionComponent={Fade}
                            className={style.accountBody}
                          >
                            <Link to={'/profile'} className={style.profileLink}>
                              <MenuItem onClick={this.handleClose} className={style.accountButton}><AccountCircleIcon/> Profile</MenuItem>
                            </Link>
                            <Divider light />
                            <MenuItem onClick={this.handleChangePassword} className={style.accountButton}><LockOpenIcon/>Change password</MenuItem>
                            <Divider light />
                            <MenuItem onClick={()=>this.logOut()} className={style.accountButton}><ExitToAppSharpIcon/>Log Out</MenuItem>
                          </Menu>
                      </div>
                    </div>
                  </Toolbar>
                </AppBar>

                <Drawer
                  variant="permanent"
                  className={clsx(style.drawer, {
                    [style.drawerOpen]: this.state.open,
                    [style.drawerClose]: !this.state.open
                  })}
                  classes={{
                    paper: clsx({
                      [style.drawerOpen1]: this.state.open,
                      [style.drawerClose2]: !this.state.open
                    })
                  }}
                >
                  <div className={style.toolbar}>
                    <div className={style.companyLogo}>
                      <img src={process.env.REACT_APP_LOGO_URL ? process.env.REACT_APP_LOGO_URL : logo }  alt="Company Logo" className={style.companyLogo}/>
                    </div>
                    <IconButton onClick={this.toggleDrawer}>
                      {style.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                  </div>
                  <Divider/>

                  <List name="sidenav">
                  {Tenants.map((menu,idx) => (
                      <ListItem style={{ padding: '0' }} key={idx} >
                        <Accordion style={{ borderRadius: 'unset', boxShadow: 'inherit' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className={style.flexCustom}
                          >
                            <ListItemIcon>{menu.parentIcon}</ListItemIcon>
                            <ListItemText primary={menu.parentLabel} className={style.parentLabel} />
                          </AccordionSummary>
                          <AccordionDetails style={{ paddingTop: '0', paddingBottom: '0', padding: '0', display: "block"}} >
                            <List style={{ paddingTop: '0', paddingBottom: '0', padding: '0' }} >
                              {menu.tenants.map((tenant,idx) => (
                                tenant.name !== menu.parentLabel ? (
                                  <ListItem className={style.subTenant} button key={idx}  onClick={() => this.changeTenant(tenant.id)}>
                                    <ul><li><ListItemText primary={tenant.name} /></li></ul>
                                  </ListItem>
                                ):null
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </ListItem>
                    ))}
                    <Divider light />
                    {
                      mainMenuTabs.map((menu) => (
                        <ListItem button key={menu.label} name={menu.link} component={Link} to={menu.link}>
                          <Tooltip title={menu.label}>
                            <ListItemIcon>{menu.icon}</ListItemIcon>
                          </Tooltip>
                          <ListItemText primary={menu.label} className={style.parentLabel}/>
                        </ListItem>
                      ))
                    }

                    {parentMenuTabs.map((parentMenuTab) => (
                        <ListItem key={parentMenuTab.label} disableGutters={true}>
                          <Accordion style={{borderRadius: 'unset', boxShadow: 'inherit'}}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon/>}
                              className={style.flexCustom}
                            >
                              <ListItemIcon>{parentMenuTab.icon}</ListItemIcon>
                              <ListItemText primary={parentMenuTab.label}  className={style.parentLabel}/>
                            </AccordionSummary>

                            <AccordionDetails className={style.subBody}>
                              <List className={style.listItem}>
                                {
                                  subMenuTabs.map((subMenuTab) =>
                                    subMenuTab.parentLabel === parentMenuTab.label ?
                                      (
                                        <ListItem key={subMenuTab.childLabel} style={{paddingTop: '0'}} button component={Link}
                                                  to={subMenuTab.link}>
                                          <Tooltip title={subMenuTab.childLabel}>
                                            <ListItemIcon>{subMenuTab.childIcon}</ListItemIcon>
                                          </Tooltip>
                                          <ListItemText primary={subMenuTab.childLabel}/>
                                        </ListItem>
                                      ) : (null)
                                  )
                                }
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </ListItem>
                      ))
                    }
                    {GeneralAccounting.map((menu,idx) => (
                      <ListItem style={{ padding: '0' }} key={idx} disableGutters={true}>
                        <Accordion style={{ borderRadius: 'unset', boxShadow: 'inherit' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className={style.flexCustom}
                          >
                            <ListItemIcon>{menu.menuIcon}</ListItemIcon>
                            <ListItemText primary={menu.menuLabel} className={style.parentLabel} />
                          </AccordionSummary>
                          <AccordionDetails style={{ paddingTop: '0', paddingBottom: '0', padding: '0'  }} >
                            <List className={style.subMenuFirstGen}>
                              {menu.children.map((menu,idx) => (
                                <ListItem className={style.subMenu} button key={idx} name={menu.link} component={Link} to={menu.link}>
                                  <Tooltip title={menu.label}>
                                    <ListItemIcon>{menu.icon}</ListItemIcon>
                                  </Tooltip>
                                  <ListItemText primary={menu.label} />
                                </ListItem>
                              ))}

                              {menu.childrenWithSubs.map((menu,idx) => (
                                <ListItem style={{ padding: '0' }} key={idx} >
                                  <Accordion style={{ borderRadius: 'unset', boxShadow: 'inherit' }}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      className={style.flexCustom}
                                    >
                                      <ListItemIcon style={{ paddingLeft: '16px' }} >{menu.parentIcon}</ListItemIcon>
                                      <ListItemText primary={menu.parentLabel} className={style.labelReports} />
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0'}} >
                                      <List  className={style.subMenuGen} >
                                      {
                                        current_user?(
                                          current_user.is_from_cdasia?
                                          (<>
                                          {menu.children.map((menu,idx) => (
                                            
                                            <ListItem button key={idx} name={menu.link} component={Link} to={menu.link}>
                                              <Tooltip title={menu.label}>
                                                <ListItemIcon>{menu.icon}</ListItemIcon>
                                              </Tooltip>
                                              <ListItemText primary={menu.label} className={style.lastLabel}/>
                                            </ListItem>
                                          ))}
                                          </>):
                                          (<>
                                            {menu.children.map((menu,idx) => (
                                              menu.label.includes("Consolidated") === false && menu.label.includes("consolidated")===false &&
                                              <ListItem button key={idx} name={menu.link} component={Link} to={menu.link}>
                                                <Tooltip title={menu.label}>
                                                  <ListItemIcon>{menu.icon}</ListItemIcon>
                                                </Tooltip>
                                                <ListItemText primary={menu.label} className={style.lastLabel}/>
                                              </ListItem>
                                            ))}
                                            </>)
                                        ):null
                                      }
                                      </List>
                                    </AccordionDetails>
                                  </Accordion>
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </ListItem>
                    ))}
                    {Settings.map((menu,idx) => (
                      <ListItem style={{ padding: '0' }} key={idx} >
                        <Accordion style={{ borderRadius: 'unset', boxShadow: 'inherit' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className={style.flexCustom}
                          >
                            <ListItemIcon>{menu.menuIcon}</ListItemIcon>
                            <ListItemText primary={menu.menuLabel} className={style.parentLabel} />
                          </AccordionSummary>
                          <AccordionDetails style={{ paddingTop: '0', paddingBottom: '0', padding: '0'  }} >
                            <List style={{ paddingTop: '0', paddingBottom: '0', padding: '0' }} >
                              {menu.children.map((menu,idx) => (
                                <ListItem className={style.subMenu} button key={idx} name={menu.link} component={Link} to={menu.link}>
                                  <Tooltip title={menu.label}>
                                    <ListItemIcon>{menu.icon}</ListItemIcon>
                                  </Tooltip>
                                  <ListItemText primary={menu.label} />
                                </ListItem>
                              ))}

                              {menu.childrenWithSubs.map((menu,idx) => (
                                <ListItem style={{ padding: '0' }} key={idx} >
                                  <Accordion style={{ borderRadius: 'unset', boxShadow: 'inherit' }}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon className={style.toggle} />}
                                      className={style.flexCustom}
                                    >
                                      <ListItemIcon style={{ paddingLeft: '16px' }}>{menu.parentIcon}</ListItemIcon>
                                      <ListItemText primary={menu.parentLabel} className={style.parentLabel} />
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: '0', display: 'block'}} >
                                      <List style={{ padding: '0'}} >
                                        {menu.children.map((menu,idx) => (
                                          <ListItem className={style.subMenuLast} button key={idx} name={menu.link} component={Link} to={menu.link}>
                                            <Tooltip title={menu.label}>
                                              <ListItemIcon>{menu.icon}</ListItemIcon>
                                            </Tooltip>
                                            <ListItemText primary={menu.label} />
                                          </ListItem>
                                        ))}
                                      </List>
                                    </AccordionDetails>
                                  </Accordion>
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </ListItem>
                    ))}
                  </List>
                  {process.env.REACT_APP_POWERED_BY !== "" ?
                    <div className={this.state.open === true? style.companyName : style.displayNone }>
                    <p>
                      {process.env.REACT_APP_POWERED_BY}
                    </p>
                  </div> :null}
                </Drawer>
                <div className={this.state.open? style.openBody : style.closeBody}>
                  <Grid container style={{ flexWrap: 'inherit' }}
                        className={this.state.open===true ? "openSidenav" : "none"}
                  >
                    <Grid item xs={12}>
                      <SimplePopUp
                        openPopup={this.state.openPopup}
                        title="Change Password"
                        handleClose={this.handleClosePopup}
                        maxWidth={this.state.maxWidth}
                      >
                        <ChangePasswordForm />
                      </SimplePopUp>
                      <main className={style.content}>
                        <Switch>
                          <Route exact path="/">
                          </Route>
                          <Route exact path="/clients">
                            <Clients/>
                          </Route>
                          <Route exact path="/receipts">
                            <Receipts/>
                          </Route>
                          <Route exact path="/receipts/new" component={ReceiptNew} />

                          <Route exact path="/receipts/:id" component={ReceiptShow}/>

                          <Route exact path="/clients/:id" component={ClientsShow} />
                          <Route exact path="/clients/:id/edit" component={ClientsNewUpdate} />
                          <Route exact path="/client/new" component={ClientsNewUpdate} />
                          <Route exact path="/clients/:client_id/invoices/new" component={InvoicesNewAndUpdate} />
                          <Route exact path="/clients/:client_id/receipts/new" component={ReceiptNew} />
                          <Route exact path="/sales_registers">
                           <SaleRegister />
                          </Route>
                          <Route exact path="/collection_registers">
                           <CollectionRegister />
                          </Route>
                          <Route exact path="/invoice_registers">
                            <ARReports />
                          </Route>
                          <Route exact path="/ir_invoice_registers">
                            <IRReports />
                          </Route>
                          <Route exact path="/classifications">
                            <Classifications />
                          </Route>
                          <Route exact path="/products">
                            <Products />
                          </Route>
                          <Route exact path="/terms_of_payments">
                           <TermOfPayments/>
                          </Route>
                          <Route exact path="/cwt_types">
                            <CwtTypes />
                          </Route>
                          <Route exact path="/invoices">
                            <Invoices/>
                          </Route>
                          <Route exact path="/accounts">
                            <Accounts />
                          </Route>
                          <Route exact path="/branches">
                            <Branches />
                          </Route>
                          <Route exact path="/banks">
                            <Banks />
                          </Route>
                          <Route exact path="/departments">
                            <Departments />
                          </Route>
                          <Route exact path="/invoices/new" component={InvoicesNewAndUpdate} />
                          <Route exact path="/invoices/:id" component={InvoiceShow} />
                          <Route exact path="/invoices/:invoice_id/receipts/new" component={ReceiptNew}/>
                          <Route exact path="/journals">
                            <Journals />
                          </Route>
                          <Route exact path="/journals/:id" component={JournalShow} />
                          <Route exact path="/journal/new" component={JournalsNewAndUpdate} />
                          <Route exact path="/journals/:id/edit" component={JournalsNewAndUpdate} />
                          <Route exact path="/journal_registers">
                            <JournalRegisters />
                          </Route>
                          <Route exact path="/balance_sheets">
                            <BalanceSheets />
                          </Route>
                          <Route exact path="/consolidated_balance_sheets">
                            <ConsolidatedBalanceSheets />
                          </Route>
                          <Route exact path="/trial_balances">
                            <TrialBalances />
                          </Route>
                          <Route exact path="/consolidated_income_statements">
                            <ConsolidatedIncomeStatements />
                          </Route>
                          <Route exact path="/general_ledgers">
                            <GeneralLedgers />
                          </Route>
                          <Route exact path="/income_statements">
                            <IncomeStatements />
                          </Route>
                          <Route exact path="/permissions">
                            <Permissions/>
                          </Route>
                          <Route exact path="/profile">
                              <UpdateProfile/>
                            </Route>
                          <Route exact path="/permissions/new" component={PermissionNewUpdate} />
                          <Route exact path="/permissions/:permission_id" component={PermissionNewUpdate} />
                          
                          <Route exact path="/deliveries">
                            <Deliveries/>
                          </Route>
                          <Route exact path="/deliveries/new" component={DeliveryNew} />
                          <Route exact path="/deliveries/:id" component={DeliveryShow} />

                          <Route exact path="/clients/:client_id/deliveries/new" component={DeliveryNew} />
                          <Route exact path="/invoices/:invoice_id/deliveries/new" component={DeliveryNew}/>

                          <Route exact path="/product_transactions">
                            <ProductTransactions />
                          </Route>
                             
                          <Route path="*">
                            Route Not Found
                          </Route>
                        </Switch>
                      </main>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Router>
          ) : (<ProgressBar model="home"/>)
        }
      </>
    )
  }
}

export default App
