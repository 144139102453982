// eslint-disable-next-line
import React from 'react'

import axios from 'axios'

import styles from './GeneralLedgers.module.css'

import PropTypes from 'prop-types'
import ClearAllIcon from '@material-ui/icons/ClearAll'
import ProgressBar from "../../ProgressBar/ProgressBar";

import GeneralLedgersSearchForm from './GeneralLedgersSearchForm'
import GeneralLedgersTable from './GeneralLedgersTable';
import SimplePopUp from '../../SimplePopUp/SimplePopUp'
import { Button, Grid, IconButton } from '@material-ui/core'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import AlertMessage from "../../Notify/AlertMessage";
import ReactToPrint from 'react-to-print';
import { withRouter } from 'react-router-dom'
import PrintIcon from '@material-ui/icons/Print'

export const Component = withRouter(() => {

})

class GeneralLedgers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            model: 'General Ledger',
            generalLedgers: [],
            account: { status: 'true', accounts: [] },
            title: '',
            load: false,
            tableHead: ['code', 'name', 'account_balance'],
            branch: {},
            branches: [],
            urlPrint: {},
            accounts: [],
            openPopup: false,
            closeDate: {
                closing_at: ''
            },
            searchUrl: process.env.REACT_APP_API_DOMAIN + "/v1/accounts/general_ledger",
            searchParams: {},
            isOpen: false,
            message: '',
            type: '',
            forPrint: false

        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleAutoComplete = this.handleAutoComplete.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.openJournalsForClose = this.openJournalsForClose.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
        this.closeJournals = this.closeJournals.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.changePrintLayout = this.changePrintLayout.bind(this)
    }

    handleCloseAlert (event, reason) {
        
        if (reason === 'clickaway') {
            return this.setState({
                isOpen: false
              })
        }
        
      }

    componentDidMount() {
        var searchUrl = this.state.searchUrl + this.props.location.search
        var searchParams = {status: true}
        const query = new URLSearchParams(this.props.location.search)
        query.forEach(function (value, key) {
            if (key !== 'account_ids') {
                if (key === 'code') {
                    searchParams[key] = value.replace(/\*/g, '')
                } else {
                    searchParams[key] = value
                }
            }
        })
        if (query.get('branch_id') !== undefined && query.get('branch_id') !== null && query.get('branch_id') !== '') {
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/' + query.get('branch_id'),
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                searchParams["branch"] = resp.data
            })
        }
        if (query.get('account_ids') !== undefined && query.get('account_ids') !== null && query.get('account_ids') !== "") {
            var account_ids = query.get('account_ids').split(',')
            var searchparamsAccounts = []
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/accounts',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                resp.data.map((account) => {
                    account_ids.map((accId) => {
                        if (account.id === parseInt(accId)) {
                            searchparamsAccounts.push(account)
                        }
                    })
                })
            })
            searchParams["accounts"] = searchparamsAccounts
        }
        this.setState({ account: searchParams })
        this.loadGeneralLedgers(searchUrl, this.props.location.search ? true : false, true)
    }

    getSearchParams() {
        var searchParams = []
        Object.entries(this.state.account).map(([key, value]) => {
            if (value != '' && key != 'accounts' && key != 'branch' && value != undefined && value != null) {
                searchParams.push([key, value].join("="))
            }
        })
        if (this.state.account.branch) {
            if (this.state.account.branch.id) {
                searchParams.push("branch_id=" + this.state.account.branch.id)
            }
        }
        if (this.state.account.accounts !== null && this.state.account.accounts !== undefined) {
            var accounts = ''
            if (this.state.account.accounts.length > 0) {
                this.state.account.accounts.map((account, idx) => {
                    if (idx === this.state.account.accounts.length - 1) {
                        accounts = accounts + account.id
                    } else {
                        accounts = accounts + account.id + ','
                    }
                })
                searchParams.push("account_ids=" + accounts)
            }

        }
        return searchParams
    }

    loadGeneralLedgers(url, searchLedger, firstLoad) {
        if (searchLedger) {
            axios({
                method: 'get',
                url: url.includes('?') ? url + '&per_page=1000000&sort_by[code]=asc' : url + '?per_page=1000000&sort_by[code]=asc',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ generalLedgers: resp.data.accounts })
                this.setState({ urlPrint: resp.data.meta })
                this.setState({ load: true })
            }).catch(error => {
                this.setState({
                  isOpen: true,
                  message: error.response.status.toString() + " Unexpected Error Occurred",
                  type: 'error'
                })
              })
        } else {
            this.setState({ load: true })
        }
        if (firstLoad) {
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ branches: resp.data })
            })
            axios({
                method: 'get',
                url: process.env.REACT_APP_API_DOMAIN + '/v1/accounts',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ accounts: resp.data })

            })
        }

    }

    handleSubmit() {
        this.setState({load: false})
        const { history } = this.props
        var searchParams = this.getSearchParams().join("&")
        const params = new URLSearchParams()
        params.append("/search", searchParams)
        history.push({ search: searchParams })
        this.loadGeneralLedgers([this.state.searchUrl, searchParams].join("?"), true, false)
    }

    handleInputChange(e) {
        if (e.target.name === "date_of_journal_start") {
            var dateStart = new Date(e.target.value).toLocaleDateString('fr-Ca')
            var lastDay = new Date(new Date(dateStart).getFullYear(), new Date(dateStart).getMonth() + 1, 0).toLocaleDateString('fr-Ca')
            this.setState({
                account: {
                    ...this.state.account,
                    [e.target.name]: e.target.value,
                    date_of_journal_end: lastDay
                }
            })
        }
        else {
            this.setState({
                account: {
                    ...this.state.account,
                    [e.target.name]: e.target.value
                }
            })
        }
    }

    handleKeyPress(e) {
        if (e.key.toLowerCase() === 'enter') {
            this.handleSubmit()
        }
    }

    clearSearch() {
        const { history } = this.props
        history.push({ search: '' })
        this.setState({ account: { date_of_journal_start: '', date_of_journal_end: '', status: '', branch: {}, accounts: [] } })
        this.setState({ generalLedgers: [], urlPrint: {} })
    }

    handleAutoComplete(event, values) {
        if (event.target.id.includes("branch")) {
            this.setState({
                account: {
                    ...this.state.account,
                    branch: values
                }
            })
        } else {
            this.setState({
                account: {
                    ...this.state.account,
                    accounts: values
                }
            })
        }
    }


    handleClose() {
        this.setState({ openPopup: false })
    }

    openJournalsForClose() {
        this.setState({ closeDate: { closing_at: this.state.account.date_of_journal_end ? moment(this.state.account.date_of_journal_end).startOf('month').format('YYYY-MM-DD') : '' }, openPopup: true })
    }

    handleDateChange(e) {
        const startOfMonth = moment(e).startOf('month').format('YYYY-MM-DD')
        this.setState({ closeDate: { closing_at: startOfMonth } })
    }

    closeJournals() {
        axios.get(process.env.REACT_APP_API_DOMAIN + '/v1/journals/close', {
            params: this.state.closeDate,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
            this.setState({ openPopup: false })
        })
    }

    changePrintLayout(){
        return new Promise((resolve) => {
            this.setState({ forPrint: !this.state.forPrint }, () => resolve());
          });        
    }
    render() {
        const print = 
            <ReactToPrint
            trigger={() => <IconButton color="primary"><PrintIcon /></IconButton>}
            onBeforeGetContent={this.changePrintLayout}
            onAfterPrint={this.changePrintLayout}
            content={() => this.componentRef}
        />
        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            <GeneralLedgersSearchForm
                                item={this.state.account}
                                branches={this.state.branches}
                                accounts={this.state.accounts}
                                search={this.handleSubmit}
                                clearSearch={this.clearSearch}
                                handleAutoComplete={this.handleAutoComplete}
                                handleChange={this.handleInputChange}
                            />
                            <GeneralLedgersTable
                                urlPrint={this.state.urlPrint}
                                items={this.state.generalLedgers}
                                model={this.state.model}
                                icon={<ClearAllIcon size="large" />}
                                openJournalsForClose={this.openJournalsForClose}
                                ref={el => (this.componentRef = el)}
                                print={print}
                                forPrint={this.state.forPrint}
                            />

                            <SimplePopUp
                                openPopup={this.state.openPopup}
                                title="Close Journals"
                                handleClose={this.handleClose}
                                maxWidth='sm'
                            >
                                <>
                                <Grid container spacing={1}>
                                        <Grid item xs={5} ><div className={styles.textFields}><b>Close Journals Up to : </b></div></Grid>
                                        <Grid item xs={7}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    variant="dialog"
                                                    openTo="month"
                                                    views={["month", "year"]}
                                                    label="Month and Year"
                                                    helperText="Closing Date"
                                                    format="MM/yyyy"
                                                    className={styles.textFields}
                                                    value={this.state.closeDate.closing_at}
                                                    onChange={this.handleDateChange}
                                                    size="small"
                                                    inputVariant="outlined"

                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                    <div className={styles.note}>Note: All journals up to this date can no longer be edited upon save. </div>
                                    <div className={styles.actionButton}>
                                        <Button variant="outlined" color="primary" onClick={this.closeJournals} >Save</Button>
                                    </div>
                                </>
                            </SimplePopUp>
                            <AlertMessage
                                notify={this.state.notify}
                                handleCloseAlert={this.handleCloseAlert}
                                isOpen={this.state.isOpen}
                                type={this.state.type}
                                message={this.state.message}
                            />
                        </div>

                    ) : (
                        <ProgressBar model={this.state.model} />
                    )}
            </>
        )
    }
}

export default withRouter(GeneralLedgers)

GeneralLedgers.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
}
