import React from 'react'
import styles from '../../App.module.css'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

class AccountLink extends React.Component {
  render() {
    return (
      <div>
        <Tooltip title="Switch to another module"  arrow>
          <IconButton onClick={this.props.toggleLink}>
            {this.props.linkOpen ? <ExpandLessIcon className={styles.iconsLink}/> : <ExpandMoreIcon className={styles.iconsLink} /> }
          </IconButton>
        </Tooltip>
        <div className={this.props.linkOpen ? styles.linkOpen : styles.linkClose}>
          <div className={styles.linkBody}>
            <div>
              {this.props.apps.length > 0 ? (
                this.props.apps.map((app) => (
                  process.env.REACT_APP_TITLE.toLowerCase().includes( 'receivable' || 'payable' ||  'payroll') !== app.name.toLowerCase().includes( 'receivable' || 'payable' || 'payroll') ? (
                    <div key={app.id} className={styles.linkItem}>
                      <a href={app.url}>{ app.name }</a>
                    </div>
                  ):null
                ))
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AccountLink

AccountLink.propTypes = {
  toggleLink: PropTypes.func,
  linkOpen: PropTypes.bool,
  apps: PropTypes.array,
}
