import React from 'react'

import PropTypes from 'prop-types'
import styles from '../Invoices.module.css'

import {
  Table, TableHead, TableBody, TableCell, TableRow
} from '@material-ui/core'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {Link} from "react-router-dom";
import {Redirect} from 'react-router'

class DeliveriesDetailTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      pathItem: {}
    }
  }

  redirectTo(item) {
    this.setState({redirect: true})
    this.setState({pathItem: item})
  }
  render() {
    if (this.state.redirect) {
      if (this.state.pathItem !== null) {
        return (<Redirect
          to={{
            pathname: "/deliveries/" + this.state.pathItem.id,
            state: {
              id: this.state.pathItem.id,
              item: this.state.pathItem
            }
          }}
        />)
      }
    }
    return (
      <div>
        <div className={styles.checkDetailsHeader}>
          <div>
            <Tooltip title="Add Delivery" arrow>
              <Link
                  to={{pathname: '/invoices/' + this.props.pathname + '/deliveries/new'}}>
                <AddCircleOutlineIcon fontSize="medium"/>
              </Link>
            </Tooltip>
          </div>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b> Date of Delivery</b>
              </TableCell>
              <TableCell>
                <b>Delivery Number</b>
              </TableCell>
              <TableCell>
                <b>Invoice/s</b>
              </TableCell>
              <TableCell>
                <b>Delivered By</b>
              </TableCell>
              <TableCell>
                <b>Prepared By	</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {this.props.deliveries.sort(function (a, b) {
              if (a.date_of_delivery < b.date_of_delivery) return -1;
              if (a.date_of_delivery > b.date_of_delivery) return 1;
              return 0;
            }).map((delivery) => (
              <TableRow key={delivery.id} onClick={() => this.redirectTo(delivery)} className={styles.tableRowBody}>
                <TableCell>
                  {new Date(delivery.date_of_delivery).toLocaleDateString('en-Us', {
                    month: '2-digit',
                    day: '2-digit',
                    year: '2-digit'})}
                </TableCell>
                <TableCell>
                  {delivery.reference_no}
                </TableCell> <TableCell>
                  {delivery.invoice_nos[0]}
                </TableCell>
                <TableCell>
                  {delivery.deliverer.name}
                </TableCell>
                <TableCell>
                  {delivery.creator_name}
                </TableCell>
                <TableCell>
                  {delivery.status}
                </TableCell>

              </TableRow>
            ))}

          </TableBody>
        </Table>
      </div>
    )
  }
}

export default DeliveriesDetailTab

DeliveriesDetailTab.propTypes = {
  classes: PropTypes.object,
  invoice: PropTypes.object,
  deliveries: PropTypes.array,
  pathname: PropTypes.func,
  activeJournal: PropTypes.func,
  cancelJournal: PropTypes.func,
}
