import axios from "axios";

export function loadData (component, url = undefined) {
  axios({
    method: 'GET',
    url: url || component.state.url,
    headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
  }).then(resp => {
    setStateSearchParams(component)
    component.setState({ data: resp.data[component.state.model.replace(' ', '_').toLowerCase() + 's'],
                         totalPages: resp.data.meta.total_pages, load: true })
  }).catch(error => console.log(error))
}

export function searchData (component) {
  const { history } = component.props
  var searchParams = []
  const params = new URLSearchParams()

  Object.entries(component.state.searchParams).map(([key, value]) => {
    if (value != '' && value != undefined && value != null) {
      searchParams.push([key, value].join("="))
    }
  })

  searchParams = searchParams.join("&")
  params.append("/search", searchParams)
  history.push({ search: searchParams })
  loadData(component, setStateSearchUrl(component, searchParams))
}

export function clearSearchParams (component) {
  const { history } = component.props
  history.push({ search: '' })
  component.setState({ searchParams: {}, currentPage: 1 })
  loadData(component, setStateSearchUrl(component))
}

export const setStateSearchUrl = (component, searchParams = undefined) => {
  var searchUrl = [process.env.REACT_APP_API_DOMAIN, component.state.apiSearchUrlPath].join("")

  if (searchParams != undefined && searchParams != null && searchParams != '') {
    searchUrl = [searchUrl, searchParams].join("?")
  }

  return searchUrl
}

export function setStateSearchParams (component) {
  const params = new URLSearchParams(component.props.location.search)
  var searchParams = {}

  params.forEach(function(value, key) {
    searchParams[key] = value
  })

  component.setState({ searchParams: searchParams })
}

export function handleSearchInputChange (component) {
  component.setState({ searchParams: { ...component.state.searchParams, [event.target.name]: event.target.value } })
}

export function handleKeyPress (component, event) {
  if (event.key === 'Enter') {
    component.handleSearch()
  }
}

export function handleClose (component) {
  component.setState({ openPopup: false, openDeletePopup: false, error_messages: '' })
}

export function handleDeleteClose (component) {
  component.setState({ openDeletePopup: false })
}

export function handlePageChange(component, event) {
  const page = Number(event.target.innerText)
  const { history } = component.props
  const params = new URLSearchParams()

  axios({
    method: 'get',
    url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/search' + '?page=' + page,
    headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
  }).then(resp => {
    component.setState({ data: resp.data.clients, currentPage: page })
  })

  params.append("page", page)
  history.push({ search: params.toString() })
}

export function handleApiRequestsErrors (error) {
  const errorMessagesOpts = { isOpen: true, type: 'error' }

  if (![null, undefined].includes(error.response)) {
    if (error.response.status === 401) {
      errorMessagesOpts.message =  "You are unauthorized to access!"
    } else if (error.response.status === 500) {
      errorMessagesOpts.message =  "Something went wrong. Please contact us."
    } else if (error.response.status === 503) {
      errorMessagesOpts.message =  "Something went wrong. Please check your internet connection or contact us."
    }
  }

  return errorMessagesOpts
}
