import React from 'react'
import styles from './Accounts.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  MenuItem, Grid, InputAdornment
} from '@material-ui/core'

class AccountsForm extends React.Component {
  render() {
    return (
      <div>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="name"
                fullWidth="true"
                label="Name"
                onChange={this.props.onchange}
                value={this.props.item.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                className={styles.codeTextField}
                variant="outlined"
                align="right"
                size="small"
                name="code"
                fullWidth="true"
                label="Code"
                onChange={this.props.onchange}
                value={this.props.item.code}
                InputProps={{
                  endAdornment: <InputAdornment className={styles.refInput}>
                    <div>{this.props.zeroFill}</div>
                  </InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                select
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="type"
                id="accounts_type"
                fullWidth={true}
                label="Type"
                onChange={this.props.onchange}
                value={this.props.item.type}
              >
                <MenuItem value=""> Please Select </MenuItem>
                <MenuItem value="Asset">Asset</MenuItem>
                <MenuItem value="Expense">Expense</MenuItem>
                <MenuItem value="Liability">Liability</MenuItem>
                <MenuItem value="Revenue">Revenue</MenuItem>
                <MenuItem value="Capital">Capital</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </form>

        <div className={styles.actionButton}>
          <Button variant="outlined"
                  disabled={!this.props.item.name}
                  id="account-submit" color="primary"
                  onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default AccountsForm

AccountsForm.propTypes = {
  onchange: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleSwitch: PropTypes.func,
  accounts: PropTypes.array,
  productTypes: PropTypes.array,
  item: PropTypes.object,
  updateAccount: PropTypes.object,
  amountChange: PropTypes.func,
  submit: PropTypes.func,
  zeroFill: PropTypes.string
}
