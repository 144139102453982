import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import PropTypes from 'prop-types'

import axios from 'axios'

import {withRouter} from 'react-router-dom'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import ClientsSearch from "./ClientSearch";
import AlertMessage from "../../shared/Notify/AlertMessage";
import * as CommonFunction from "../../shared/Custom/CommonFunctions"
export const Component = withRouter(() => {

})

class Clients extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Client',
      clients: [],
      client: {},
      title: '',
      load: false,
      tableHead: ['name', 'company_address', 'home_address'],
      openAdvanceSearch: false,
      searchParams: {},
      notify: {},
      withPagination: true,
      totalPages: 1,
      currentPage: 1,
      clickableTableRow: true,
      searchUrl: process.env.REACT_APP_API_DOMAIN + '/v1/clients/search',
      isOpen: false,
      message: '',
      type: '',
      totalResults: 0,
      perPage: '20'
    }
    this.clearSearch = this.clearSearch.bind(this)
    this.loadClients = this.loadClients.bind(this)
    this.handleAutoComplete = this.handleAutoComplete.bind(this)
    this.handleOpenAdvanceSearch = this.handleOpenAdvanceSearch.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handlePerPageChange = this.handlePerPageChange.bind(this)
    this.loadClients = this.loadClients.bind(this)

  }

  componentDidMount() {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    const params = new URLSearchParams(this.props.location.search)
    // This is to load all params based on URLSearchParams
    params.forEach(function(value, key) {
      if (key !== 'page' && key !== 'per_page') {
        searchParams[key] = value
      }
    })
    if(params.get('per_page')) {
      this.setState({perPage: params.get('per_page')})
    }
    
    this.setState({ searchParams: searchParams })
    this.loadClients(searchUrl)
  }

  loadClients(url) {
    axios({
      method: 'get',
      url: url,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({clients: resp.data.clients,totalPages: resp.data.meta.total_pages, currentPage: resp.data.meta.current_page, totalResults: resp.data.meta.total_count, withResultsLabel: true})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/clients/classifications',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({classifications: resp.data})
        this.setState({load: true})
      }).catch(error => this.setState(CommonFunction.handleApiRequestsErrors(error)))
    }).catch(error => {
      this.setState({
        isOpen: true,
        message: error.response.status.toString() +  error.response.data.errors[0].detail,
        type: 'error'
      })
    })
  }


  handleAutoComplete(event, values) {
    if (values !== null) {
      this.setState({defaultGroup: values})

      this.setState({
        client: {
          ...this.state.client,
          group_id: values.uid
        }
      })
    }
  }

  handleOpenAdvanceSearch() {
    this.setState({openAdvanceSearch: !this.state.openAdvanceSearch})
  }

  clearSearch() {
    const { history } = this.props
    history.push({ search: 'per_page='+ this.state.perPage })
    this.setState({ withResultsLabel: false})
    this.loadClients(this.state.searchUrl+ '?per_page='+this.state.perPage)
    this.setState({ searchParams: { q: '', name: '', contact_persons: '', contact_numbers: '', email_addresses: '', tax_exempted: '' } })
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  handleSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadClients([this.state.searchUrl, searchParams].join("?"))
  }

  getSearchParams() {
    var searchParams = []
    // This is to load all params based on searchParams state.
    Object.entries(this.state.searchParams).map(([key, value]) => {
      if (value != '' && value != undefined && value != null) {
        searchParams.push([key, value].join("="))
      }
    })

    searchParams.push(["per_page", this.state.perPage].join("="))
    return searchParams
  }


  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    console.log(searchParams)
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadClients([this.state.searchUrl, searchParams].join("?"))
  }

  handleSearchInputChange(e) {
    this.setState({
      searchParams: {
        ...this.state.searchParams,
        [e.target.name]: e.target.value
      }
    })
  }

  handlePerPageChange(e){
    var searchParams = this.getSearchParams()
    searchParams.map((value, index) => {
      if(value.includes("per_page")) {
        searchParams.splice(index, 1)
      }
    })
    searchParams.push(["per_page", e.target.value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ perPage: e.target.value })
    this.loadClients([this.state.searchUrl, searchParams].join("?"))
  }



  render() {
    return (
      <div>
        {
          this.state.load ? (
            <>
              <ClientsSearch
                clearSearchParams={this.clearSearch}
                item={this.state.searchParams}
                search={this.handleSearch}
                handleChange={this.handleSearchInputChange}
                handleKeyPress={this.handleKeyPress}
              />
              <SimpleTable
                initialItem={this.state.initialClient}
                clickableTableRow={this.state.clickableTableRow}
                items={this.state.clients}
                model={this.state.model}
                headers={this.state.tableHead}
                currentPage={this.state.currentPage}
                withPagination={this.state.withPagination}
                totalPages={this.state.totalPages}
                handlePageChange={this.handlePageChange}
                withResultsLabel={this.state.withResultsLabel}
                totalResults={this.state.totalResults}
                perPage={this.state.perPage}
                handlePerPageChange={this.handlePerPageChange}
                icon={<PeopleOutlineIcon fontSize="large"/>}
                headerTitle="Clients"
                defaultNewRoutesPath="/client/new"
                defaultModelRoute="/clients/"
                createUsingSimplePopup={false}
              />
            </>
          ) : <> <ProgressBar model={this.state.model}/>
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              /></>
        }
      </div>

    )
  }


}

export default withRouter(Clients)

Clients.propTypes = {
  location: PropTypes.string,
  history: PropTypes.string
}
