import React from 'react'

import PropTypes from 'prop-types'

import {
  Button, TextField
} from '@material-ui/core'
import styles from "./Journal.module.css";
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';

class AttachmentForm extends React.Component {
  render() {
    const { file } = this.props.attachment;
    let filename = null;
    filename = file
      ? ( <span><b>File Selected</b> - {file.name}</span>)
      : ( <span>Drag your files here or click in this area.</span> );

    return (
      <div>
        <form>
          <div className={styles.formBody}>

            <input
              id="file"
              type="file"
              name="file"
              className={styles.input}
              onChange={(e) => this.props.onFileChange(e)}
              inputProps={{ accept: 'image/*' }}
            />
            <label
              className={styles.filelabel}
              htmlFor="file">
              <VerticalAlignBottomIcon fontSize="large"/>
              <br/>
              {filename}
              {/*{this.state.document.file}*/}
            </label>

            <TextField
              autoComplete="off"
              variant="outlined"
              className={styles.textFields}
              size="small"
              name="description"
              fullWidth
              label="Description"
              onChange={this.props.onchange}
              value={this.props.attachment.description}
              InputLabelProps={{
                shrink: true
              }}
            />
            <p className={styles.fileError}><b>{this.props.error}</b></p>
          </div>
        </form>
        <div className={styles.actionAttactmentButton}>
          <Button variant="outlined" color="primary" onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default AttachmentForm

AttachmentForm.propTypes = {
  onFileChange: PropTypes.func,
  attachment: PropTypes.object,
  children: PropTypes.object,
  submit: PropTypes.func,
  error: PropTypes.func,
  handleFileInputChange: PropTypes.func,
  handleUsersAutocomplete: PropTypes.func,
  onchange: PropTypes.func,
  users: PropTypes.array
}
