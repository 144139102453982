import React from 'react'
import styles from './CompanyHeader.module.css'
import PropTypes from 'prop-types'
class CompanyHeader extends React.Component {
  render () {
    return (
      <div>
        <div className={this.props.forPrint? styles.pdfHeader:styles.noDisplay}>
            <div >
                <h2 style={{margin: '0'}}>Lex Integritas Technology Innovations, Inc.</h2>
            </div>
            <div>10 San roque St. Kapitolyo pasig City 1603 <br/>
                Tel. No. (02) 750 0194
            </div>
        </div>
      </div>
    )
  }
}
export default CompanyHeader

CompanyHeader.propTypes = { 
  forPrint: PropTypes.bool

}
