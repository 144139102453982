import React from 'react'
import styles from './Receipt.module.css'

import PropTypes from 'prop-types'

import { Button, FormControlLabel, TextField, MenuItem, Switch } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CloseIcon from '@material-ui/icons/Close'
import NumberFormat from "react-number-format";

class ReceiptDetailsForm extends React.Component {
  render() {
    return(
      <>
        {
          <div>
            <form>
              <div className={styles.groupTextfield}>
                <div className={styles.autoCompleteField}>
                  <Autocomplete
                    name="invoice_id"
                    id="invoice_id"
                    key="invoice"
                    size="small"
                    loading={this.props.invoicesAutoCompleteLoading}
                    options={this.props.invoices}
                    getOptionLabel={(option) => option.reference_no || ''}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={this.props.handleAutocomplete}
                    onInputChange={(event) => this.props.handleAutocompleteOnInputChange(event)}
                    fullWidth={true}
                    value={this.props.initialReceiptDetail.invoice || {}}
                    closeIcon={<CloseIcon fontSize="small" id="clear_invoice_autocomplete" />}
                    renderInput={(params) => <TextField {...params} label="Invoice Number" variant="outlined" required />}
                  />
                </div>

                <div className={styles.autoCompleteField}>
                  <Autocomplete
                    name="bank_id"
                    id="bank_id"
                    key="bank"
                    size="small"
                    loading={this.props.banksAutoCompleteLoading}
                    options={this.props.banks}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={this.props.handleAutocomplete}
                    onInputChange={(event) => this.props.handleAutocompleteOnInputChange(event)}
                    fullWidth={true}
                    value={this.props.initialReceiptDetail.bank}
                    closeIcon={<CloseIcon fontSize="small" id="clear_bank_autocomplete" />}
                    renderInput={(params) => <TextField {...params} label="Bank" variant="outlined" required />}
                  />
                </div>
              </div>

              <div className={styles.groupTextfield3}>
                <div className={styles.termAutocomplete}>
                  <Autocomplete
                    name="payment_term_id"
                    id="payment_term_id"
                    key="payment_term"
                    size="small"
                    options={this.props.paymentTerms}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={this.props.handleAutocomplete}
                    onInputChange={(event) => this.props.handleAutocompleteOnInputChange(event)}
                    fullWidth={true}
                    value={this.props.initialReceiptDetail.payment_term}
                    closeIcon={<CloseIcon fontSize="small" id="clear_payment_term_autocomplete" />}
                    renderInput={(params) => <TextField {...params} label="Terms of Payment" variant="outlined" required />}
                  />
                </div>

                <NumberFormat
                  customInput={TextField}
                  variant="outlined"
                  autoComplete="off"
                  size="small"
                  fullWidth
                  className={styles.textFieldsAlignRight}
                  shrink
                  name="amount"
                  label="Amount"
                  id="amount"
                  value={this.props.initialReceiptDetail.amount}
                  onValueChange={this.props.handleInputChangeReceiptDetailAmount}
                  inputProps={{ shrink: true }}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  disabled={!this.props.initialReceiptDetail.invoice || this.props.initialReceiptDetail.invoice===null}
                />

                <NumberFormat
                    customInput={TextField}
                    variant="outlined"
                    autoComplete="off"
                    disabled={!this.props.initialReceiptDetail.invoice}
                    size="small"
                    fullWidth
                    className={styles.textFieldsAlignRight}
                    shrink
                    required
                    name="balance"
                    label="Balance"
                    id="balance"
                    thousandSeparator={true}
                    decimalSeparator="."
                    value={parseFloat(this.props.initialReceiptDetail.balance).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                    InputLabelProps={{ shrink: true }}
                  />
              </div>

              {
                this.props.isCheckPayment ?
                (
                  <>
                    <div className={styles.groupTextfield}>
                      <h2> Check Details </h2>
                    </div>
                    <div className={styles.groupTextfield}>
                      <TextField
                        autoComplete="off"
                        className={styles.textFields}
                        fullWidth
                        variant="outlined"
                        required
                        name="check_no"
                        label="Check Number"
                        id="check_no"
                        size="small"
                        type="number"
                        value={this.props.initialReceiptDetail.check_no}
                        onChange={this.props.handleReceiptDetailsFormInputChange}
                        inputProps={{ shrink: true }}
                      />

                      <TextField
                        autoComplete="off"
                        variant="outlined"
                        className={styles.textFields}
                        multiline={true}
                        size="small"
                        required
                        name="bank_of_check"
                        id="bank_of_check"
                        fullWidth={true}
                        label="Name of Bank"
                        value={this.props.initialReceiptDetail.bank_of_check}
                        onChange={this.props.handleReceiptDetailsFormInputChange}
                        InputProps={{
                          shrink: true
                        }}
                      />
                    </div>


                    <div className={styles.groupTextfield}>
                      <TextField
                        autoComplete="off"
                        select
                        required
                        className={styles.textFields}
                        variant="outlined"
                        size="small"
                        id="type_of_check"
                        name="type_of_check"
                        fullWidth={true}
                        label="Type of Check"
                        value={this.props.initialReceiptDetail.type_of_check || ''}
                        onChange={this.props.handleReceiptDetailsFormInputChange}
                      >
                        <MenuItem value="Local">Local</MenuItem>
                        <MenuItem value="Regional">Regional</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </TextField>

                      <TextField
                        autoComplete="off"
                        className={styles.textFields}
                        type="date"
                        variant="outlined"
                        size="small"
                        required
                        name="date_of_check"
                        id="date_of_check"
                        fullWidth={true}
                        label="Date of Check"
                        onChange={this.props.handleReceiptDetailsFormInputChange}
                        value={this.props.initialReceiptDetail.date_of_check}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />

                      <div>
                        <FormControlLabel
                          control={<Switch
                            size="medium"
                            checked={this.props.initialReceiptDetail.pdc}
                            onChange={this.props.handleSwitch}
                            id="pdc"
                            name="pdc" />}
                          label="PDC?"
                        />
                      </div>
                    </div>
                  </>
                )
                : null
              }

              <div className={styles.groupTextfield}>
                <TextField
                  autoComplete="off"
                  variant="outlined"
                  className={styles.textFields}
                  multiline={true}
                  size="small"
                  required
                  name="remarks"
                  id="remarks"
                  fullWidth={true}
                  label="Remarks"
                  value={this.props.initialReceiptDetail.remarks}
                  onChange={this.props.handleReceiptDetailsFormInputChange}
                  InputProps={{
                    shrink: true
                  }}
                />
              </div>
            </form>

            <div className={styles.actionButton}>
              <Button variant="outlined" id="save-receipt-detail" color="primary"
                      disabled={ !this.props.initialReceiptDetail.invoice || !this.props.initialReceiptDetail.bank || !this.props.initialReceiptDetail.remarks}
                      onClick={() => this.props.handleSaveReceiptDetails(this.props.initialReceiptDetail)} >Save</Button>
            </div>
          </div>
        }
      </>
    )
  }
}
export default ReceiptDetailsForm

ReceiptDetailsForm.propTypes = {
  invoicesAutoCompleteLoading: PropTypes.bool,
  banksAutoCompleteLoading: PropTypes.bool,
  isCheckPayment: PropTypes.bool,
  invoices: PropTypes.array,
  banks: PropTypes.array,
  paymentTerms: PropTypes.array,
  initialReceiptDetail: PropTypes.object,
  handleAutocomplete: PropTypes.func,
  handleAutocompleteOnInputChange: PropTypes.func,
  handleReceiptDetailsFormInputChange: PropTypes.func,
  handleSaveReceiptDetails: PropTypes.func,
  handleSwitch: PropTypes.func,
  handleInputChangeReceiptDetailAmount: PropTypes.func
}
