import React from 'react'
import styles from './Invoices.module.css'
import moment from 'moment';
import 'moment-timezone';

import PropTypes from 'prop-types'

import {
  TextField, Button, MenuItem, Grid, TableHead, TableRow, TableCell,
  TableBody, Table, IconButton, Tooltip, InputAdornment, FormControlLabel, Switch  
} from '@material-ui/core'

import AddBoxIcon from '@material-ui/icons/AddBox'
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import NumberFormat from "react-number-format";
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'

class InvoicesForm extends React.Component {
  getSubTotal() {
    var total = 0
    if (this.props.item.invoice_details_attributes.length > 0) {
      this.props.item.invoice_details_attributes.map((amount) => {
        total = total + parseFloat(amount.amount)
      })
    }
    return total
  }

  getVat() {
    var total = 0
    var withTax = 0
    if (this.props.item.invoice_details_attributes.length > 0) {
      this.props.item.invoice_details_attributes.map((invoice_detail) => {
        if (!this.props.selectedClient.tax_exempted) {
          total = total + parseFloat(invoice_detail.amount)
          withTax = (total * .12)
        } else {
          if (!invoice_detail.is_tax_exempted) {
            total = total + parseFloat(invoice_detail.amount)
            withTax = (total * .12)
          } else {
            withTax = 0
          }
        }
      })
    }
    return withTax
  }

  render() {
    var total = parseFloat(this.getSubTotal()) + parseFloat(this.getVat())
    return (
      <div>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch
                  size="medium"
                  checked={this.props.item.is_cash_sales}
                  onChange={this.props.handleSwitch}
                  fullWidth
                  id="is_cash_sales"
                  name="is_cash_sales" />}
                className={styles.switchLabel}
                label="CASH SALES"
              />
            </Grid>

            <Grid item xs={6}>
              <div className={styles.groupAddClient}>
              <Autocomplete
                name="client_id"
                id="client_id"
                key="client"
                size="small"
                loading={this.props.clientsAutoCompleteLoading}
                options={(this.props.clients).sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                  return 0;
                })}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option.id === value}
                onChange={this.props.handleAutocomplete}
                onInputChange={(event, value, reason) => this.props.handleAutocompleteOnInputChange(event, value, reason)}
                fullWidth={true}
                value={this.props.selectedClient}
                closeIcon={<CloseIcon fontSize="small" id="clear_client_autocomplete" />}
                renderInput={(params) => <TextField {...params} label="Client Name" variant="outlined" required />}
              />

                <div>
                  <Tooltip title="Add Client" arrow>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={this.props.openClientForm}
                      style={{padding: '14px'}}
                    >
                      <AddBoxIcon/>
                    </IconButton>
                  </Tooltip>
                </div>

              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.addressBody}>
                <div>
                  Address :
                </div>
                <div>
                  { this.props.selectedClient.company_address || this.props.selectedClient.home_address || '' }
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoComplete="off"
                variant="outlined"
                type="date"
                className={styles.textFields}
                required
                size="small"
                id="date_of_invoice"
                name="date_of_invoice"
                fullWidth
                label="Date of Invoice"
                onChange={this.props.onchange}
                value={this.props.item.date_of_invoice}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <Autocomplete
                value={this.props.selectedBranch}
                id="branch_id"
                className={styles.textFields}
                name="branch_id"
                key="branch"
                fullWidth
                size="small"
                options={(this.props.branches).sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                  return 0;
                })}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option.id === value}
                onChange={this.props.handleAutocomplete}
                filterSelectedOptions={true}
                closeIcon={<CloseIcon fontSize="small" id="clear_branch_autocomplete" />}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Branch" required/>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoComplete="off"
                select
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="contact_person_id"
                id="contact_person_id"
                fullWidth={true}
                label="Contact Person"
                onChange={this.props.onchange}
                value={this.props.item.contact_person_id}
              >

                {this.props.contactPersons.length > 0 ? (
                  this.props.contactPersons.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                  }).map((contact) => (
                    <MenuItem key={contact.id} value={contact.id}>{contact.name}</MenuItem>
                  ))
                ) : <MenuItem value="">No Option</MenuItem>}
              </TextField>
            </Grid>


            <Grid item xs={3}>
              <TextField
                autoComplete="off"
                required
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="reference_no"
                fullWidth={true}
                label="Billing Invoice Number"
                onChange={this.props.onchange}
                value={this.props.item.reference_no}
                InputProps={{
                  startAdornment: <InputAdornment position="start" className={styles.refInput}><div>{[null, undefined].includes(this.props.selectedBranch) ? this.props.selectedBranch.name.charAt(0) + '-' : ' '}</div></InputAdornment>,
                }}
                id="reference_no"
              />
              <div className={styles.error}>{this.props.error.reference_no?'Billing Invoice Number ' + this.props.error.reference_no:null}</div>
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="po_no"
                fullWidth="true"
                label="PO No."
                onChange={this.props.onchange}
                value={this.props.item.po_no}
                id="po_no"
              />
            </Grid>

            <Grid item xs={3}>
              <div className={styles.termsBody}>
                <TextField
                  autoComplete="off"
                  select
                  id="cwt_type_id"
                  className={styles.textFields}
                  variant="outlined"
                  size="small"
                  name="cwt_type_id"
                  key="cwt_type"
                  fullWidth={true}
                  label="CWT"
                  InputProps={{
                    endAdornment:
                     <InputAdornment position="start" >{this.props.item.cwt_type_id?<IconButton id="cwt_type_id" name="cwt_type_id" onClick={this.props.clearField}><CloseIcon /></IconButton>:null}</InputAdornment>,
                  }}
                  onChange={this.props.onchange}
                  value={this.props.item.cwt_type_id}
                >
                  {this.props.cwtTypes.length > 0 ? (
                    this.props.cwtTypes.sort(function (a, b) {
                      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                      return 0;
                    }).map((cwtType) => (
                      <MenuItem key={cwtType.id} value={cwtType.id}>{cwtType.name + "(" + parseFloat(cwtType.multiplier) * 100 + "%)"}</MenuItem>
                    ))
                  ) : <MenuItem value="">No Option</MenuItem>}
                </TextField>

                <div>
                  <Tooltip title="Add CWT" arrow>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={this.props.openCTWForm}
                      style={{padding: '14px'}}
                      name="add_paymentTerms"
                    >
                      <AddBoxIcon/>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

            </Grid>
            <Grid item xs={3}>
              <div className={styles.termsBody}>
                <TextField
                  autoComplete="off"
                  select
                  required
                  id="payment_term_id"
                  key="payment_term"
                  className={styles.textFields}
                  variant="outlined"
                  size="small"
                  name="payment_term_id"
                  fullWidth={true}
                  label="Terms of Payment"
                  onChange={this.props.onchange}
                  value={this.props.item.payment_term_id}
                >
                  {this.props.paymentTerms.length > 0 ? (
                    this.props.paymentTerms.sort(function (a, b) {
                      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                      return 0;
                    }).map((term) => (
                      <MenuItem key={term.id} value={term.id}>{term.name}</MenuItem>
                    ))
                  ) : <MenuItem value="">No Option</MenuItem>}
                </TextField>

                <div>
                  <Tooltip title="Add Terms of Payment" arrow>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={this.props.openTermsForm}
                      style={{padding: '14px'}}
                      name="add_paymentTerms"
                    >
                      <AddBoxIcon/>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div className={styles.error}> {this.props.item.payment_term_id === ''?this.props.error.payment_term?'Term of payment  ' + this.props.error.payment_term:null:null}</div>
            </Grid>

            { this.props.item.is_cash_sales ? (
                <>
                  <Grid item xs={this.props.isBankPayment ? 4 : 6}>
                    <TextField
                      autoComplete="off"
                      required
                      className={styles.textFields}
                      variant="outlined"
                      size="small"
                      name="receipt_no"
                      fullWidth={true}
                      label="Receipt Number"
                      onChange={this.props.onchange}
                      value={this.props.item.receipt_reference_no}
                      InputProps={{
                        startAdornment: <InputAdornment position="start" className={styles.refInput}><div></div></InputAdornment>,
                      }}
                      id="receipt_no"
                    />
                  </Grid>

                  <Grid item xs={this.props.isBankPayment ? 4 : 6}>
                    <TextField
                      autoComplete="off"
                      variant="outlined"
                      type="date"
                      className={styles.textFields}
                      size="small"
                      id="date_of_receipt"
                      name="date_of_receipt"
                      required
                      fullWidth
                      label="Date of Receipt"
                      onChange={this.props.onchange}
                      value={this.props.item.date_of_receipt}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  {
                    this.props.isBankPayment ? (
                      <Grid item xs={4}>
                        <Autocomplete
                          name="bank_id"
                          id="bank_id"
                          key="bank"
                          size="small"
                          loading={this.props.banksAutoCompleteLoading}
                          className={styles.textFields}
                          options={(this.props.banks).sort(function (a, b) {
                            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                            return 0;
                          })}
                          getOptionLabel={(option) => option.name || ''}
                          getOptionSelected={(option, value) => option.id === value}
                          onChange={this.props.handleAutocomplete}
                          onInputChange={(event, value, reason) => this.props.handleAutocompleteOnInputChange(event, value, reason)}
                          fullWidth={true}
                          value={this.props.selectedBank}
                          closeIcon={<CloseIcon fontSize="small" id="clear_bank_autocomplete" />}
                          renderInput={(params) => <TextField {...params} label="Bank Name" variant="outlined" required />}
                        />
                      </Grid>
                    ) : null
                  }

                  {
                    this.props.isCheckPayment ?
                    (
                      <Grid container spacing={1} className={styles.isCheckPaymentBody}>
                        <Grid item xs={12}>
                          <h2 style={{ margin: '0'}}> Check Details </h2>
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            autoComplete="off"
                            className={styles.textFields}
                            fullWidth
                            variant="outlined"
                            required
                            name="check_no"
                            label="Check Number"
                            id="check_no"
                            size="small"
                            type="number"
                            value={this.props.item.check_no}
                            onChange={this.props.onchange}
                            inputProps={{ shrink: true }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            autoComplete="off"
                            variant="outlined"
                            className={styles.textFields}
                            multiline={true}
                            size="small"
                            required
                            name="bank_of_check"
                            id="bank_of_check"
                            fullWidth={true}
                            label="Name of Bank"
                            value={this.props.item.bank_of_check}
                            onChange={this.props.onchange}
                            InputProps={{
                              shrink: true
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            autoComplete="off"
                            select
                            className={styles.textFields}
                            variant="outlined"
                            size="small"
                            id="type_of_check"
                            name="type_of_check"
                            fullWidth={true}
                            label="Type of Check"
                            value={this.props.item.type_of_check || ''}
                            onChange={this.props.onchange}
                          >
                            <MenuItem value="Local">Local</MenuItem>
                            <MenuItem value="Regional">Regional</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </TextField>
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            autoComplete="off"
                            className={styles.textFields}
                            type="date"
                            variant="outlined"
                            size="small"
                            required
                            name="date_of_check"
                            id="date_of_check"
                            fullWidth={true}
                            label="Date of Check"
                            onChange={this.props.onchange}
                            value={this.props.item.date_of_check}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </Grid>

                        <Grid item xs={2}>
                          <div>
                            <FormControlLabel
                              control={<Switch
                                size="medium"
                                checked={this.props.item.pdc}
                                onChange={this.props.handleSwitch}
                                id="pdc"
                                name="pdc" />}
                              label="PDC?"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    )
                    : null
                  }
                </>
              ) : null
            }

            <Grid item xs={12}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="remarks"
                fullWidth="true"
                label="Remarks"
                onChange={this.props.onchange}
                value={this.props.item.remarks}
                id="remarks"
              />
            </Grid>

            {this.props.isInstallment ? (
              <>
                <Grid item xs={3}>
                  <NumberFormat
                    customInput={TextField}
                    autoComplete="off"
                    variant="outlined"
                    className={this.props.item.invoice_details_attributes.length > 0 ? styles.customFieldsForInstallment : styles.disableInput}
                    size="small"
                    name="down_payment"
                    fullWidth
                    id="down_payment"
                    label="Down Payment"
                    thousandSeparator={true}
                    onValueChange={this.props.donwPaymentChange}
                    value={this.props.item.down_payment}
                    decimalSeparator="."
                    fixedDecimalScale={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    autoComplete="off"
                    required
                    type="number"
                    className={this.props.item.invoice_details_attributes.length > 0 ? styles.customFieldsForInstallment : styles.disableInput}
                    variant="outlined"
                    size="small"
                    name="no_of_months"
                    fullWidth="true"
                    label="Number of Month"
                    onChange={this.props.onchange}
                    value={this.props.item.no_of_months}
                    id="no_of_months"
                  />
                </Grid>
                <Grid item xs={3}>
                  <NumberFormat
                    customInput={TextField}
                    autoComplete="off"
                    variant="outlined"
                    className={this.props.item.invoice_details_attributes.length > 0 ? styles.customFieldsForInstallment : styles.disableInput}
                    size="small"
                    name="monthly_payment"
                    fullWidth
                    label="Monthly Payment"
                    thousandSeparator={true}
                    onValueChange={this.props.amountChange}
                    value={this.props.item.monthly_payment}
                    decimalSeparator="."
                    fixedDecimalScale={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    autoComplete="off"
                    variant="outlined"
                    type="date"
                    className={this.props.item.invoice_details_attributes.length > 0 ? styles.customFieldsForInstallment : styles.disableInput}
                    size="small"
                    id="date_of_maturity"
                    name="date_of_maturity"
                    fullWidth
                    label="Due Date"
                    onChange={this.props.onchange}
                    value={this.props.item.date_of_maturity}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </>
            ) : null}

          </Grid>

        </form>
        <div className={styles.headerContent}>
          <div> Invoice Details</div>
          <IconButton

            name="add_invoice_detail"
            disabled={this.props.selectedClient.name === undefined}
            onClick={() => this.props.openInvoiceDetailsForm({ discount: 0, qty: 1, year: new Date().getFullYear() }, true)}
          >
            <AddCircleOutlineIcon fontSize="small"/>
          </IconButton>
        </div>
        <Table size="small" style={{position: 'relative'}} name="invoice_details_table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Unit</b>
              </TableCell>
              <TableCell>
                <b>Product Description</b>
              </TableCell>
              <TableCell>
                <b>Date of Expiration</b>
              </TableCell>
              <TableCell>
                <b>Quantity</b>
              </TableCell>
              <TableCell>
                <b>Tax Exempted</b>
              </TableCell>
              <TableCell align="right">
                <b>Discount</b>
              </TableCell>
              <TableCell align="right">
                <b>Unit Amount</b>
              </TableCell>
              <TableCell align="right">
                <b>Total Amount</b>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>


          {this.props.item.invoice_details_attributes.length > 0 ? (
            this.props.item.invoice_details_attributes.map((detail, idx) => (
              <TableRow key={detail.id} className={styles.tableBodyNew}>
                <TableCell>{detail.unit}</TableCell>
                <TableCell>{detail.name}</TableCell>
                <TableCell>{detail.date_of_expiration ? moment(detail.date_of_expiration).format("MM/DD/yyyy") : "N/A"}</TableCell>
                <TableCell>{detail.qty}</TableCell>
                <TableCell name="is_tax_exempted">{detail.tax_exempt !== true ? 'Yes' : 'No'}</TableCell>
                <TableCell align="right">{detail.discount + "%"}</TableCell>
                <TableCell align="right">
                  {parseFloat(detail.unit_amount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                </TableCell>
                <TableCell align="right" name="amount">
                  {parseFloat(detail.amount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
                </TableCell>
                <TableCell></TableCell>
                <TableCell className={styles.actionButtonHoverable}>
                  <IconButton name="delete_invoice_detail" onClick={() => this.props.removeInvoiceDetailsContent(idx)} color="secondary"> <DeleteIcon
                    fontSize="small"/></IconButton>
                  <IconButton name="edit_invoice_detail" onClick={() => this.props.openInvoiceDetailsForm(detail, false, idx)} color="primary"> <EditIcon
                    fontSize="small"/></IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : null}
          <TableBody>
            <TableRow>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell align="right"><b>Sub Total :</b></TableCell>
              <TableCell></TableCell>
              <TableCell align="right" name="sub_total">
                {this.getSubTotal().toLocaleString(navigator.language, {minimumFractionDigits: 2})}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell align="right"><b>Add 12% VAT :</b></TableCell>
              <TableCell></TableCell>
              <TableCell align="right" name="tax_amount">
                {this.getVat().toLocaleString(navigator.language, {minimumFractionDigits: 2})}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell className={styles.noBorder}></TableCell>
              <TableCell align="right"><b>Total :</b></TableCell>
              <TableCell></TableCell>
              <TableCell align="right" name="total_amount">
                {total.toLocaleString(navigator.language, {minimumFractionDigits: 2})}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <div className={styles.actionButton}>
          <Button
            variant="outlined"
            id="submit_invoice" color="primary"
            onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default InvoicesForm

InvoicesForm.propTypes = {
  onchange: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleSwitch: PropTypes.func,
  clients: PropTypes.array,
  contactPersons: PropTypes.array,
  branches: PropTypes.array,
  invoiceDetails: PropTypes.array,
  item: PropTypes.object,
  selectedBranch: PropTypes.object,
  selectedClient: PropTypes.object,
  updateAccount: PropTypes.object,
  amountChange: PropTypes.func,
  donwPaymentChange: PropTypes.func,
  openTermsForm: PropTypes.func,
  openClientForm: PropTypes.func,
  address: PropTypes.string,
  paymentTerms: PropTypes.string,
  ifGov: PropTypes.string,
  submit: PropTypes.func,
  removeInvoiceDetailsContent: PropTypes.func,
  handleAddInvoiceDetails: PropTypes.func,
  openInvoiceDetailsForm: PropTypes.func,
  isInstallment: PropTypes.bool,
  clientsAutoCompleteLoading: PropTypes.bool,
  handleAutocompleteOnInputChange: PropTypes.func,
  cwtTypes: PropTypes.array,
  banks: PropTypes.array,
  banksAutoCompleteLoading: PropTypes.bool,
  selectedBank: PropTypes.object,
  error: PropTypes.object,
  isCheckPayment: PropTypes.bool,
  openCTWForm: PropTypes.bool,
  isBankPayment: PropTypes.bool,
  clearField: PropTypes.func
}
