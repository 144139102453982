import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios"
import JournalForm from './JournalForm'
import { Redirect } from 'react-router'

class JournalNewAndUpdate extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      load: false,
      branches: [],
      branch: {},
      accounts: [],
      forEditjournalEntries: [],
      forDeletejournalEntries: [],
      journalEntries: [],
      initialjournalEntries: { account_code: '', account: {}, amount_debit: 0, amount_credit: 0 },
      journal: {
        id: '',
        date_of_journal: new Date().toLocaleDateString('fr-Ca'),
        description: '',
        reference_no: '',
        branch_id: '',
        prefix: 'JV',
        post_status: '',
      },
      zeroFill: '',
      totalDigit: 8,
      redirect: false,
      errorMessage: {},
      withReference: false,
      referenceTypes: [],
      references: [],
      clients: [],
      payees: [],
      employees: []

    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleJournalEntriesChange = this.handleJournalEntriesChange.bind(this)
    this.handleContentAutocomplete = this.handleContentAutocomplete.bind(this)
    this.addEntry = this.addEntry.bind(this)
    this.removeEntry = this.removeEntry.bind(this)
    this.handleJournalEntriesAmountChange=this.handleJournalEntriesAmountChange.bind(this)
    this.padFix = this.padFix.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.handleReferences= this.handleReferences.bind(this)
    this.handleReferenceAutoComplete = this.handleReferenceAutoComplete.bind(this)
    this.handleReferenceAutoCompleteSearch = this.handleReferenceAutoCompleteSearch.bind(this)
    this.handleMemo=this.handleMemo.bind(this)
    this.getReferenceNo = this.getReferenceNo.bind(this)
  }


  componentDidMount() {
    
    if (this.props.match.params.id !== undefined) {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/journals/' + this.props.match.params.id,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ journal: resp.data })
        this.setState({
          branch: {
            name: resp.data.branch_name,
            id: resp.data.branch_id
          }
        })
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_DOMAIN + '/v1/journals/' + this.props.match.params.id + '/journal_entries',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          const newData = resp.data.map((item) => ({
            id: item.id,
            journal_id: item.journal_id,
            reference_id: item.reference_id,
            account_id: item.account_id,
            account_code: item.account_code,
             account: item.account, 
             amount_debit: parseFloat(item.amount_debit).toFixed(2), 
             amount_credit: parseFloat(item.amount_credit).toFixed(2),
            description: item.description,
            reference: item.reference,
            reference_type: item.reference_type? item.reference_type.replace('Caes::',''):null
          }))
          if(newData.filter(item=>item.reference_id !==null).length>0){
            axios.get(process.env.REACT_APP_API_DOMAIN + '/v1/journal_entries/reference_types', {
              headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
              this.setState({referenceTypes: resp.data})
            })
            this.setState({withReference: true})
          }
          this.setState({ journalEntries: newData})
          this.loadjournal(process.env.REACT_APP_API_DOMAIN + '/v1/journals/search')
        }).catch(error => console.log(error.response))
      }).catch(error => console.log(error.response))

    } else {
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN + '/v1/employees/profiles',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        if(resp.data.branch_id){
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/branches/'+resp.data.branch_id,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            this.setState({branch: resp.data})
            this.getReferenceNo()
          }).catch(error => console.log(error.response))
        }
        this.loadjournal(process.env.REACT_APP_API_DOMAIN + '/v1/journals/search')
      }).catch(error => console.log(error.response))
    }
  }

  loadjournal(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ journals: resp.data.journals })
      this.setState({ load: true })
    }).catch(error => console.log(error.response))
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/branches',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ branches: resp.data })
    }).catch(error => console.log(error.response))
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_DOMAIN + '/v1/accounts/search?per_page=100000',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ accounts: resp.data.accounts })
    })
    axios.get(process.env.REACT_APP_API_DOMAIN + '/v1/clients/search', {
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({clients: resp.data.clients})
    })
    axios.get(process.env.REACT_APP_API_DOMAIN + '/v1/payees/search?per_page=100000', {
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({payees: resp.data.payees})
    })
    axios.get(process.env.REACT_APP_API_DOMAIN + '/v1/employees/search?per_page=100000', {
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({employees: resp.data.employees})
    })
  }

  handleInputChange(e) {
    if(e.target.name==="reference_no"){
      this.setState({
        journal: {
          ...this.state.journal,
          reference_no: this.padFix(e.target.value)[0]
        }
      })
    }else{
    this.setState({
      journal: {
        ...this.state.journal,
        [e.target.name]: e.target.value
      }
    })
  }
  }


  handleAutocomplete(event, values) {
    
    if(values!==null){
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_DOMAIN  + '/v1/journals/search?resource_type=Journal&branch_id='+values.id+'&sort_by[date_of_journal]=desc',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(() => {
        this.setState({ branch: values })
        this.getReferenceNo()
      }).catch(error => console.log(error.response))
    }else{
      this.setState({
        journal: {
          ...this.state.journal,
          reference_no: ''
        }
      })
      this.setState({ branch: values })
    }
  }


  handleSubmit() {
    
    var journalEntries = []
    var forEditjournalEntries = []
    var forDeletejournalEntries  = this.state.forDeletejournalEntries
    if (this.state.journalEntries.length > 0) {
      this.state.journalEntries.map((content) => {
        var item = {
          id: content.id,
          account_code: content.account.code,
          account_id: content.account.id,
          amount_credit: content.amount_credit,
          amount_debit: content.amount_debit
        }
        journalEntries.push(item)
      })
    }
    if (this.state.forEditjournalEntries.length > 0) {
      this.state.forEditjournalEntries.map((content) => {
        var item = {
          id: content.id,
          account_code: content.account.code,
          account_id: content.account.id,
          amount_credit: content.amount_credit,
          amount_debit: content.amount_debit
        }
        forEditjournalEntries.push(item)
        forDeletejournalEntries.push(item)
      })
    }
    const item = this.state.journal
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/journals/'
      if(!this.state.withReference){
        item["journal_entries_attributes"] = journalEntries
      }
      
    } else {
      var contentArray = []
      method = 'put'
      url = process.env.REACT_APP_API_DOMAIN + '/v1/journals/' + item.id
      if(!this.state.withReference){
      if (journalEntries.length > 0) {
        journalEntries.map((content) => {
          if (content.id === undefined) {
            contentArray.push(content)
          }
        })
      }
      if (forDeletejournalEntries.length > 0) {
        forDeletejournalEntries.map((content) => {
          axios({
            method: 'delete',
            url: process.env.REACT_APP_API_DOMAIN + '/v1/journals/' + item.id + '/journal_entries/' + content.id,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          })
        })
      }
      item["journal_entries_attributes"] = [...contentArray, ...forEditjournalEntries]
      }
    }
    item["branch_id"] = this.state.branch.id
    item["reference_no"] = this.state.journal.reference_no
    
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      const newData = resp.data
      var done = []
      if(this.state.withReference){
        this.state.journalEntries.map((entry)=>{
          var entryUrl =''
          var entryMethod=""
          if(entry.id===undefined || entry.id ===''){
            entryUrl= process.env.REACT_APP_API_DOMAIN + '/v1/journals/'+newData.id+'/journal_entries'
            entryMethod='post'
          }else{
            entryUrl= process.env.REACT_APP_API_DOMAIN + '/v1/journals/'+newData.id+'/journal_entries/'+entry.id
            entryMethod='put'
          }
          axios({
            method: entryMethod,
            url: entryUrl,
            data: (entry),
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
          }).then(resp=>{
            done.push(resp.data)
            if(done.length===this.state.journalEntries.length){
              this.setState({ journal: newData })
              this.setState({ redirect: true })
            }
          })
         
        })
        
      }else{
      this.setState({ journal: newData })
      this.setState({ redirect: true })
      }
    }).catch(error => {
      if (error.response.status === 422) {
        var errors = {}
        Object.keys(error.response.data.errors[0].detail.errors).map((field)=>{
          errors[field] = error.response.data.errors[0].detail.errors[field][0]
         })
         this.setState({errorMessage: errors})
      } else {
        this.setState({isOpen: true,message: error.response.status.toString() + " Unexpected Error Problem Occurred",type: 'error'})
      }
    })
  
  }

  handleJournalEntriesChange(e) {
    var value = null
    const updatedVoucher = [...this.state.journalEntries]
    if (e.target.dataset.fieldType === "amount_debit" || e.target.dataset.fieldType === "amount_credit") {
      value = parseFloat(e.target.value).toFixed(2)
    } else {
      value = e.target.value
    }
    updatedVoucher[e.target.dataset.id][e.target.dataset.fieldType] = value
    if (updatedVoucher[e.target.dataset.id]["id"] !== undefined) {
      this.setState({ forEditjournalEntries: [...this.state.forEditjournalEntries.filter(item => item.id !== updatedVoucher[e.target.dataset.id].id), updatedVoucher[e.target.dataset.id]] })
    }
    this.setState({ journalEntries: updatedVoucher })
  }

  handleJournalEntriesAmountChange(event,idx,params) {
    var value = 0
    if(event.floatValue!==undefined){
      value = event.floatValue
    }
      const updatedVoucher = [...this.state.journalEntries]
      updatedVoucher[idx][params] = value
      if (updatedVoucher[idx]["id"] !== undefined) {
        this.setState({ forEditjournalEntries: [...this.state.forEditjournalEntries.filter(item => item.id !== updatedVoucher[idx].id), updatedVoucher[idx]] })
      }
      this.setState({ journalEntries: updatedVoucher })
  }

  removeEntry(idx, content) {
    const updatedVouchers = [...this.state.journalEntries]
    updatedVouchers.splice(idx, 1)
    if (content.id !== undefined) {
      var item = this.state.journalEntries[idx]
      this.setState({ forDeletejournalEntries: [...this.state.forDeletejournalEntries, item] })
    }
    this.setState({ journalEntries: updatedVouchers })
  }

  handleContentAutocomplete(event, values) {
    var updatedVoucher = [...this.state.journalEntries]
    var editVouchers = [...this.state.forEditjournalEntries]
    if (values !== null) {

      updatedVoucher[event]["account"] = values
      updatedVoucher[event]["account_id"] = values.id
      updatedVoucher[event]["account_code"] = values.code

      if (updatedVoucher[event]["id"] !== undefined) {
        this.setState({
          forEditjournalEntries: this.state.forEditjournalEntries.filter(item => item.id !== updatedVoucher[event]["id"])
        })
        this.setState({ forEditjournalEntries: [...editVouchers, updatedVoucher[event]] })
      } else {
        this.setState({ journalEntries: updatedVoucher })
      }
    } else {
      updatedVoucher = [...this.state.journalEntries]
      updatedVoucher[event]["account"] = {}
      updatedVoucher[event]["account_id"] = ''
      updatedVoucher[event]["account_code"] = ''
      this.setState({ journalEntries: updatedVoucher })
    }

  }

  addEntry() {
    this.setState({ journalEntries: [...this.state.journalEntries, { ...this.state.initialjournalEntries }] })
  }

  padFix(n) {
    return ('00000000'+n.toString()).match(/\S{8}$/);
  }

  getReferenceNo(){
    if(this.state.branch.id){
      axios.get(process.env.REACT_APP_API_DOMAIN + '/v1/journals/generate_ref_no', {
        params: { branch_id: this.state.branch.id},
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        if(resp.data.error===false){
          this.setState({journal: {...this.state.journal,prefix: 'JV'+ this.state.branch.name.charAt(0).toUpperCase(),reference_no: this.padFix(resp.data.reference_no)[0]}})}
          this.setState({errorMessage: {}})
      })
    }
  }
  
  handleSwitch(e){
    this.setState({withReference: e.target.checked})
    if(e.target.checked){
      axios.get(process.env.REACT_APP_API_DOMAIN + '/v1/journal_entries/reference_types', {
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({referenceTypes: resp.data})
      })
     
    }
    
  }

  handleReferences(idx,event){
  const updatedEntries = [...this.state.journalEntries]
  updatedEntries[idx][event.target.name] = event.target.value
  this.setState({ journalEntries: updatedEntries,references: this.state[event.target.value.toLowerCase()+'s'] })
  }

  handleReferenceAutoComplete(idx,value){
    const updatedEntries = [...this.state.journalEntries]
    updatedEntries[idx]['reference_id'] = value!==null? value.id: ''
    this.setState({ journalEntries: updatedEntries })
  }
  
  handleReferenceAutoCompleteSearch(idx,value){
    const updatedEntries = [...this.state.journalEntries]
    axios.get(process.env.REACT_APP_API_DOMAIN + `/v1/${updatedEntries[idx].reference_type.toLowerCase()}s/search?name=${value}`, {
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({references: resp.data[updatedEntries[idx].reference_type.toLowerCase()+'s']})
    })
  }

  handleMemo(idx,event){
    const updatedEntries = [...this.state.journalEntries]
  updatedEntries[idx][event.target.name] = event.target.value
  this.setState({ journalEntries: updatedEntries})
  }


  render() {
    const { redirect } = this.state
    if (redirect)
    return (<Redirect to={{
      pathname: '/journals/' + this.state.journal.id
    }} />)
    return (

      this.state.load ? (
        <JournalForm
          error={this.state.errorMessage}
          handleSubmit={this.handleSubmit}
          handleAutocomplete={this.handleAutocomplete}
          handleInputChange={this.handleInputChange}
          accounts={this.state.accounts}
          branches={this.state.branches}
          branch={this.state.branch}
          journal={this.state.journal}
          journalEntries={this.state.journalEntries}
          handleContentAutocomplete={this.handleContentAutocomplete}
          addJournalEntry={this.addEntry}
          removeJournalEntry={this.removeEntry}
          handleJournalEntriesChange={this.handleJournalEntriesChange}
          handleJournalEntriesAmountChange={this.handleJournalEntriesAmountChange}
          zeroFill={this.state.zeroFill}
          withReference={this.state.withReference}
          handleSwitch={this.handleSwitch}
          referenceTypes={this.state.referenceTypes}
          handleReferences={this.handleReferences}
          references={this.state.references}
          handleReferenceAutoComplete={this.handleReferenceAutoComplete}
          handleReferenceAutoCompleteSearch={this.handleReferenceAutoCompleteSearch}
          handleMemo={this.handleMemo}
          getReferenceNo={this.getReferenceNo}
        />
      ) : null
    )
  }
}

export default JournalNewAndUpdate

JournalNewAndUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  location: PropTypes.object
}
